import { Row, Col } from "antd";
import { IWorkOrderStatusUpdatesProps } from "./WorkOrderComponents.interface";
import AlectifyText from "static/texts.json";
import { truncate } from "lodash";
import { displayDateTime, getFullUserName } from "utils/helpers";
import { PM_STATUS } from "enums";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { useEffect, useState } from "react";
import { ITask } from "redux/components/tasks";
// import { fetchExternalPMDetails } from "services/pm-external/pm-external.service";

const WorkOrderStatusUpdate: React.FC<IWorkOrderStatusUpdatesProps> = (
  props: IWorkOrderStatusUpdatesProps,
) => {
  // const { fetching } = useSelector((state: IRootState) => state.tasks);
  const [workOrder, setWorkOrder] = useState<{
    data: ITask | null;
    fetching: boolean;
  }>({
    data: null,
    fetching: false,
  });
  const { user } = useSelector((state: IRootState) => state.auth);
  /*   const renderStatistic = (
    title: string,
    value: string | number | null,
    fontSize = 14,
  ) => (
    <Statistic title={title} value={value || "-"} valueStyle={{ fontSize }} />
  ); */
  useEffect(() => {
    /*  const fetchWorkOrderDetail = async (id: string) => {
      setWorkOrder((prev) => ({ ...prev, fetching: true }));
      try {
        const response = await fetchExternalPMDetails(id);
        setWorkOrder({ data: response.data, fetching: false });
      } catch (error) {
        console.error(error);
        setWorkOrder((prev) => ({ ...prev, fetching: false }));
      }
    };

    if (props.task?.id) {
      fetchWorkOrderDetail(props.task.id);
    } */
    setWorkOrder({ data: props.task, fetching: false });
  }, [props.task?.id]);
  return (
    <>
      {/* {workOrder.fetching || fetching ? (
        <Skeleton active />
      ) : ( */}
      <Row gutter={[20, 20]} className="card-container-review">
        <ul>
          <li>
            {AlectifyText.WORK_ID}: {truncate(workOrder?.data?.workId || "-")}
          </li>
          <li>
            {AlectifyText.WORK_TITLE}: {workOrder?.data?.workTitle || "-"}
          </li>
          <li>
            {AlectifyText.DUEDATE}: {displayDateTime(workOrder?.data?.dueDate)}
          </li>
          <li>
            <strong>{getFullUserName(user as any)}</strong> completed the work
            on{" "}
            <strong>
              {displayDateTime(workOrder?.data?.reviewedAt, true) || "-"}
            </strong>{" "}
            {/* [Completion date mentioned was{" "}
              <strong>
                {displayDateTime(workOrder?.data?.completionAt) || "-"}
              </strong>
              ] */}
          </li>
        </ul>
        {props.status === PM_STATUS.COMPLETED && (
          <Col span={24}>
            {/* @temp: sylings will be added in scss */}
            <div className="green-text">
              <span>
                Note: Your Work order is closed. No further action is required.
                You will be able to view your work orders under
                <strong> Past </strong> Work order Tab or go to detail page.
              </span>
            </div>
          </Col>
        )}
      </Row>
      {/* )} */}
    </>
  );
};

export default WorkOrderStatusUpdate;
