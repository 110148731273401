import { Row, Col, Typography, Space, Divider } from "antd";
import { ISparePartsAdvisory } from "services/spare-parts/spare-parts.interface";
import TextToLink from "../text-to-link";
import AlectifyChip from "../chips/AlectifyChip";
import { useState } from "react";
import { isEmpty } from "lodash";

const SparePartsAdvisory: React.FC<{
  sparePartsAdvisory: ISparePartsAdvisory[] | null;
}> = (props: { sparePartsAdvisory: ISparePartsAdvisory[] | null }) => {
  const [selectedAdvisory, setSelectedAdvisory] =
    useState<ISparePartsAdvisory | null>(null);
  console.log("selectedAdv", selectedAdvisory);
  return (
    <Row
      justify={"start"}
      align={"top"}
      gutter={16}
      className="spare-parts-advisory-container"
    >
      <Col span={9} className="spare-parts-advisory-list-container">
        <div className="spare-parts-advisory-list-heading">
          The following spare parts may have an advisory or might have become
          obselete. Please review:-
        </div>
        <div className="spare-parts-advisory-list mt-10">
          {props.sparePartsAdvisory?.map((advisory: ISparePartsAdvisory) => (
            <div
              className={`spare-parts-advisory-list-item mt-10 ${
                selectedAdvisory && selectedAdvisory?.id === advisory?.id
                  ? "active"
                  : ""
              }`}
              onClick={() => setSelectedAdvisory(advisory)}
            >
              <Typography.Title level={5} className="m-0">
                <TextToLink underline text={advisory?.partNumber} />
              </Typography.Title>
              <Space className="mt-5">
                {!isEmpty(advisory?.preferredSupplierName) && (
                  <AlectifyChip text={advisory?.preferredSupplierName} />
                )}
                {!isEmpty(advisory?.category) && (
                  <AlectifyChip text={advisory?.category} />
                )}
              </Space>
              <div className="mt-5">
                <span>
                  <strong>Description:</strong>
                </span>
                <span> {advisory?.description || "-"}</span>
              </div>
            </div>
          ))}
        </div>
      </Col>
      {selectedAdvisory && (
        <Col span={15} className="spare-parts-advisory-detail-container">
          <Typography.Title level={4} className="m-0">
            {selectedAdvisory.partNumber}
          </Typography.Title>
          <Space>
            {!isEmpty(selectedAdvisory?.preferredSupplierName) && (
              <AlectifyChip text={selectedAdvisory?.preferredSupplierName} />
            )}
            {!isEmpty(selectedAdvisory?.category) && (
              <AlectifyChip text={selectedAdvisory?.category} />
            )}
          </Space>
          <div className="mt-5">
            <span>
              <strong>Description:</strong>
            </span>
            <span> {selectedAdvisory?.description || "-"}</span>
          </div>
          <Divider />
          <div
            dangerouslySetInnerHTML={{ __html: selectedAdvisory?.summary }}
          />
        </Col>
      )}
    </Row>
  );
};

export default SparePartsAdvisory;
