import { useEffect, useState } from "react";
import AlectifyTable from "../table";
import useDocumentsTable from "./effects/useDocumentsTable";
import {
  IRoundsDocumentationTableProps,
  IRoundsTableState,
} from "./RoundsDetailTable.interface";
import { getRoundsDocsTimeline } from "services/data-round/data-round.service";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";

const RoundsDocumentationTable: React.FC<IRoundsDocumentationTableProps> = (
  props: IRoundsDocumentationTableProps,
) => {
  const [state, setState] = useState<IRoundsTableState>({
    data: [],
    fetching: false,
    meta: {
      total_count: 0,
      total_pages: 0,
    },
  });

  useEffect(() => {
    fetchDocuments();
  }, [props.checkPointRoundId, props.drProjectId]);

  const fetchDocuments = async (paginationOptions?: IPagination) => {
    try {
      const params = {
        page: paginationOptions?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: paginationOptions?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };
      if (props.drProjectId) {
        setState((prev: IRoundsTableState) => ({
          ...prev,
          fetching: true,
        }));
        const response = await getRoundsDocsTimeline(
          props.drProjectId,
          props.checkPointRoundId,
          params,
        );
        setState((prev: IRoundsTableState) => ({
          ...prev,
          data: response?.data ?? [],
          meta: response?.meta ?? { total_count: 0 },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setState((prev: IRoundsTableState) => ({
        ...prev,
        fetching: false,
      }));
    }
  };

  const columns = useDocumentsTable();

  return (
    <AlectifyTable
      loading={state.fetching}
      dataSource={state.data}
      total={state.meta.total_count}
      columns={columns}
      onDataFetch={fetchDocuments}
      defaultPageSize={5}
    />
  );
};

export default RoundsDocumentationTable;
