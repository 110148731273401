import { ITaskCount } from "services/tasks/tasks.interface";
import {
  ITask,
  ITaskAttachment,
  ITaskResponse,
  ITasksState,
} from "../tasks.interface";
import { IReduxActionResponse } from "redux/interfaces";
import { tasksInitialState } from "../tasks.initialState";

export const getProjectTasks = (
  state: ITasksState,
  action: IReduxActionResponse<ITaskResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      data: payload?.data,
      meta: payload?.meta,
      message: payload?.message,
    };
  }
  return state;
};

export const showTasksLoader = (
  state: any,
  action: IReduxActionResponse<ITasksState>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
  };
};

export const getTaskAttachments = (
  state: any,
  action: IReduxActionResponse<ITaskAttachment>,
) => {
  const { payload } = action;
  return {
    ...state,
    taskAttachments: {
      ...state.taskAttachments,
      list: payload?.list,
      count: payload?.count,
    },
  };
};

export const showTaskAttachmentssLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    taskAttachments: {
      ...state.taskAttachments,
      fetching: payload,
    },
  };
};

export const setTasksCounts = (
  state: ITasksState,
  action: IReduxActionResponse<ITaskCount>,
): ITasksState => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      skippedCounts: payload?.skipped || 0,
      completedCounts: payload?.completed || 0,
      waitingForReviewCounts: payload?.waitingForReview || 0,
      pendingCounts:
        (payload?.pending || 0) +
        (payload?.waitingForReview || 0) +
        (payload?.denied || 0),
    };
  }
  return state;
};

export const resetTaskState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...tasksInitialState,
  };
};

export const setTodayTasks = (
  state: any,
  action: IReduxActionResponse<ITask[]>,
): ITasksState => {
  return {
    ...state,
    todayTask: action.payload ?? [],
  };
};
