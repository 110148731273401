import { memo, useEffect, useState } from "react";
import { IRootState } from "redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, message, Space, Typography } from "antd";
import AlectifyText from "static/texts.json";
import DocumentsAttachment from "../documents-attachment/DocumentsAttachment";
import { ITaskAttachmentProps } from "../task-attachment/TaskAttachment.interface";
import {
  deletPrevMaintenanceDocuments,
  uploadPrevMaintenanceDocuments,
} from "services/pm-internal/pm-internal.service";
import { MESSAGES } from "constants/messages";
import { IPagination } from "../table/AlectifyTable.interface";
import { IParams } from "redux/interfaces";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyModal from "../modal";
import { FOLDER_NAME } from "enums/folder-type.enum";
// import { IPmAttachmentDocuments } from "redux/components/pm-internal";
import { UploadFile } from "antd/lib";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";
import { actions } from "redux/components/pm-internal";
import "./PreventiveDocumentsAttachment.scss";

const PreventiveDocumentsAttachment: React.FC<ITaskAttachmentProps> = (
  props: ITaskAttachmentProps,
) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);

  const fetchAllDocuments = async (params?: IPagination) => {
    setLoader(true);
    try {
      /* const response = await fetchPrevMaintenanceDocuments(
        props.details?.id,
        params as IParams,
      ); */

      dispatch(getPmInternalAttachments(props.details?.id, params as IParams));
      setLoader(false);
      // if (response.status) {
      //   setDocuments(response.data);
      //   setMeta(response.meta);
      // }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const user = useSelector((state: IRootState) => state.auth);
  // const [directory, setDirectory] = useState<{ [key: string]: string }>({});

  /*   const onDirectoryChange = (uid: string, dir: string) => {
    const newDir = directory;
    newDir[uid] = dir;
    setDirectory(newDir);
  }; */

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DOCUMENT_ATTACHMENTS,
    });
  };

  useEffect(() => {
    fetchAllDocuments({
      page: 1,
      per_page: 50,
      limit: 50,
    });
    onCloseModal();
  }, [props.details?.id]);

  const uploadDocument = async (fileList: UploadFile[]) => {
    try {
      dispatch(actions.setAttachmentLoader(true));
      setLoader(true);

      message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);

      // Create FormData and append files
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append(FOLDER_NAME.DOCUMENT_UPLOAD, file.originFileObj as any);
      });

      // Append additional fields to formData
      formData.append("userId", user?.user?.id);
      formData.append("projectId", props.details.project?.id);
      formData.append("subProjectId", props.details.subProject.id);

      // Call API to upload documents
      await uploadPrevMaintenanceDocuments(props.details?.id, formData);
      message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      /* dispatch(
        getAttachmentsMedia(props.details?.id, {
          page: 1,
          limit: 5,
          signedUrls: true,
          fileType: "media",
        }),
      ); */
      fetchAllDocuments({
        page: 1,
        per_page: 50,
        limit: 50,
      });
      // dispatch(getPmInternalAttachments(props.details?.id));

      if (props.isDetailPage) {
        if (props.callback) props.callback();
        // dispatch(getComments(props.details.subProject?.id, props.details?.id));
      }
      onCloseModal();
      setLoader(false);
    } catch (error) {
      message.error(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_ERROR);
      throw error;
    } finally {
      dispatch(actions.setAttachmentLoader(false));
      setLoader(false);
    }
  };

  const deleteDocument = async (id: string) => {
    try {
      setLoader(true);
      await deletPrevMaintenanceDocuments(id);
      setLoader(false);
      fetchAllDocuments();
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  return (
    <div className="work-order-pm-attachment-container">
      <Collapse defaultActiveKey={["1"]}>
        {props.details?.isRecurring && (
          <Collapse.Panel
            key={"1"}
            header={
              <Space direction="vertical">
                <Typography.Title level={5} className="m-0">
                  {` Master Attachments (${
                    props.details?.masterPreventiveMaintenance?.files || 0
                  })`}
                </Typography.Title>
                <span className="work-order-pm-attachment-helper-text">
                  {
                    "These attachments will show on all occurrences of this work order. To Add/Remove files from here go to Actions > Edit > All Events."
                  }
                </span>
              </Space>
            }
          >
            <DocumentsAttachment
              // items={documents}
              // count={meta?.totalItems || 0}
              uploadDocuments={uploadDocument}
              loading={loader}
              shareable={props.shareable}
              documentType={AlectifyText.PM}
              id={props?.details?.masterPreventiveMaintenance?.id}
              deleteDocument={deleteDocument}
              isFuture={props?.details?.isFuture}
              isMaster
            />
          </Collapse.Panel>
        )}
        <Collapse.Panel
          header={
            <Space direction="vertical">
              <Typography.Title level={5} className="m-0">
                {`Attachments (${props.details?.documents || 0})`}
              </Typography.Title>
              <span className="work-order-pm-attachment-helper-text">
                These files belong only to this Work Order.
              </span>
            </Space>
          }
          key={props.details?.isRecurring ? "2" : "1"}
        >
          <DocumentsAttachment
            // items={documents}
            // count={meta?.totalItems || 0}
            uploadDocuments={uploadDocument}
            loading={loader}
            shareable={props.shareable}
            documentType={AlectifyText.PM}
            id={props?.details?.id}
            deleteDocument={deleteDocument}
            isFuture={props?.details?.isFuture}
            isMaster={false}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
export default memo(PreventiveDocumentsAttachment);
