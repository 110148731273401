import React from "react";
import CheckIcon from "components/icons/CheckIcon";
import { RoundsIssueStatusProps } from "pages/data-round/dr-history/Rounds.interface";
import AlectifyText from "static/texts.json";
import "./RoundSlider.scss";

const RoundsIssueStatus: React.FC<RoundsIssueStatusProps> = ({ shift }) => {
  return (
    <div className="rounds-status-container">
      <div
        className={`rounds-status-box ${
          shift.status === 0
            ? "issue-box"
            : shift.status === 1
            ? "no-issue-box"
            : "unknown-box"
        }`}
      >
        {shift.status === 0 ? (
          <>
            <CheckIcon width={14} fill={"red"} height={12} className="mt-1" />
            <span> {AlectifyText.ISSUE_RAISED}</span>
          </>
        ) : shift.status === 1 ? (
          <>
            <CheckIcon width={14} fill={"green"} height={12} className="mt-1" />
            <span>{AlectifyText.ALL_OKAY}</span>
          </>
        ) : (
          <span>{AlectifyText.DID_NOT_CHECK}</span>
        )}
      </div>
    </div>
  );
};

export default RoundsIssueStatus;
