export enum pmExternalEnum {}

export enum PM_TYPES {
  PM_EXTERNAL = "PM_EXTERNAL",
  PM_INTERNAL = "PM_INTERNAL",
  TASK = "TASK",
  ALL = "ALL",
}

export enum DueDateDelayFiltersEnum {
  ON_TIME = "ON_TIME",
  SEVEN_DAYS_AGO = "SEVEN_DAYS_AGO",
  SEVEN_TO_14_DAYS_AGO = "SEVEN_TO_14_DAYS_AGO",
  MORE_THAN_14_DAYS_AGO = "MORE_THAN_14_DAYS_AGO",
}

export enum DueDateDelayFiltersStringToEnum {
  "On Time" = "ON_TIME",
  "<7 Days" = "SEVEN_DAYS_AGO",
  "7 to 14 Days" = "SEVEN_TO_14_DAYS_AGO",
  "14+ Days" = "MORE_THAN_14_DAYS_AGO",
}
