import { memo } from "react";
import { Col, Row, Typography } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import { IDocumentsAttachmentProps } from "./DocumentsAttachment.interface";
import DocumentsAttachmentTable from "./DocumentsAttachmentTable";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";

const DocumentsAttachment: React.FC<IDocumentsAttachmentProps> = (
  props: IDocumentsAttachmentProps,
) => {
  const { pmAttachments } = useSelector(
    (state: IRootState) => state.pmInternal,
  );
  return (
    <div>
      {props.shareable && (
        <Row justify={"center"} align={"middle"}>
          <Col span={12}>
            <Typography.Title level={5} className="text-align-left m-0">
              {AlectifyText.ATTACHMENTS} ({pmAttachments?.meta?.totalItems || 0}
              )
            </Typography.Title>
          </Col>
          <Col span={12} className="text-align-right mb-10">
            {props.shareable && (
              <AlectifyButton
                text="Share"
                icon={<ShareAltOutlined />}
                type="primary"
                className="task-share-btn"
              />
            )}
          </Col>
        </Row>
      )}
      <div className="mt-10 mr-10">
        <DocumentsAttachmentTable
          // data={props?.items}
          // count={props?.count}
          loading={props?.loading || pmAttachments.fetching}
          documentType={props?.documentType}
          id={props?.id}
          uploadDocuments={props.uploadDocuments}
          deleteDocuments={props.deleteDocument}
          isFuture={props.isFuture}
          isMaster={props.isMaster}
        />
      </div>
    </div>
  );
};
export default memo(DocumentsAttachment);
