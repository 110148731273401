import { memo, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Upload,
  UploadFile,
  message,
} from "antd";
import {
  DeleteOutlined,
  CameraOutlined,
  PlusOutlined,
  UndoOutlined,
  StopOutlined,
} from "@ant-design/icons";
import SelectMasterProject from "components/shared/master-project-select";
import AlectifySelect from "components/shared/select";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyText from "static/texts.json";
import { WORK_ORDER_FORM_FIELD_TYPES } from "../WorkOrderCreateEdit.constants";
import TextEditor from "components/text-editor/TextEditor";
import AlectifyButton from "components/shared/button";
import { enumToTile, generateVideoThumbnail } from "utils/helpers";
import {
  IWorkOrderBasicInfoCreationProps,
  WorkOrderTypeEnum,
} from "../WorkOrderCreateEdit.interface";
import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
import { getSelectedAssetsText } from "../WorkOrderCreateEdit.helpers";
import { isEmpty } from "lodash";
import { PM_TYPES } from "redux/components/pm-external";
import { CheckboxProps } from "antd/lib";
import logger from "utils/logger";
import { fetchWorkOrderMedia } from "services/work-orders/work-order.service";
import { PM_EDITING_TYPES } from "components/shared/pm-create-edit-form/PMCreateEditForm.constants";

// Image upload preview function
const getBase64 = (file: Blob, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(file);
};

const BasicInformation: React.FC<IWorkOrderBasicInfoCreationProps> = (
  props: IWorkOrderBasicInfoCreationProps,
) => {
  const [previewImages, setPreviewImages] = useState<string[]>(
    (props.taskDetails?.imageUrl && [props.taskDetails?.imageUrl]) || [],
  ); // Multiple images
  const [deletedImageIds, setDeletedImageIds] = useState<string[]>([]);
  const [trackFileList, setTrackFileList] = useState<UploadFile[]>([]);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [deletedExistingImages, setDeletedExistingImages] = useState<string[]>(
    [],
  );

  const [priority, setPriority] = useState<string>(
    props.taskDetails?.priority || "NORMAL",
  );

  const handleChange = (info: any) => {
    const fileList = info.fileList.slice(-10); // Limit to 10 files
    const newPreviewList: string[] = [...previewImages]; // Copy existing previews

    const previewPromises = fileList.map((file: any) => {
      const fileObj = file.originFileObj || file;

      if (fileObj.type.startsWith("image/")) {
        return new Promise<string>((resolve) =>
          getBase64(fileObj, (url) => {
            if (!newPreviewList.includes(url)) newPreviewList.push(url);
            resolve(url);
          }),
        );
      } else if (fileObj.type.startsWith("video/")) {
        return generateVideoThumbnail(fileObj).then((thumbnailUrl) => {
          if (!newPreviewList.includes(thumbnailUrl))
            newPreviewList.push(thumbnailUrl);
          return thumbnailUrl;
        });
      }
      return Promise.resolve("");
    });

    Promise.all(previewPromises).then(() => {
      setPreviewImages([...newPreviewList]); // Update state once all previews are processed
    });

    setTrackFileList(fileList);
    props.handleImageUpload(fileList);
  };

  const beforeUpload = (file: File) => {
    const isImageOrVideo =
      file.type.startsWith("image/") || file.type.startsWith("video/");
    if (!isImageOrVideo) {
      message.error("You can only upload image or video files!");
    }
    return isImageOrVideo ? false : Upload.LIST_IGNORE;
  };

  const handleRemove = (index: number) => {
    const updatedImages = [...previewImages];
    const removedImage = updatedImages[index]; // Get the image to be removed

    if (!isS3Url(removedImage)) {
      updatedImages.splice(index, 1); // Remove the image from the list if it's not an S3 URL
      setPreviewImages(updatedImages);

      const updatedFileList = [...trackFileList];
      updatedFileList.splice(index, 1); // Remove the file from the trackFileList
      setTrackFileList(updatedFileList);

      // setDeletedImageIds([...deletedImageIds, removedImage]);
      props.handleImageUpload(updatedFileList);
    } else {
      setDeletedImageIds([...deletedImageIds, removedImage]);
      props.handleDeleteImages(removedImage);
    }
  };
  const handleRestore = (index: number) => {
    const imageToRestore = previewImages[index];
    props.handleRestoreImages(imageToRestore);
    setDeletedImageIds(deletedImageIds.filter((id) => id !== imageToRestore));
  };
  const getTaskCategoryOptions = () => {
    return Object.values(WorkOrderTypeEnum).map((type) => ({
      label: enumToTile(type),
      key: enumToTile(type),
      value: type,
      disabled: props.type === PM_TYPES.TASK && type !== WorkOrderTypeEnum.TASK,
    }));
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setPriority("CRITICAL");
    } else {
      setPriority("NORMAL");
    }
  };
  const isS3Url = (url: string) => {
    const s3UrlPattern = /^https:\/\/.*\.s3\.amazonaws\.com\/.*$/;
    return s3UrlPattern.test(url);
  };

  const checkIfImageIsPermanentlyDeleted = (url: string) => {
    const image = props.workOrderImages?.find(
      (img: any) => img.filePath === url,
    );
    return image ? !image.isActive : false; // Invert to correctly mark inactive images
  };

  const renderImage = (url: string, index: number) => {
    const inActive = checkIfImageIsPermanentlyDeleted(url);
    const isDeleted = deletedImageIds.includes(url);
    return (
      <div key={index} className="image-preview">
        <Tooltip title={isDeleted ? "Deleted" : null}>
          {url.includes(".mp4") ? (
            <>
              <video
                src={url}
                className={`${
                  isDeleted || inActive
                    ? "video-preview-disabled"
                    : "preview-video"
                } ${
                  deletedExistingImages.includes(url) ? "deleted-overlay" : ""
                }`}
              />
              {deletedExistingImages.includes(url) && (
                <div className="deleted-overlay">
                  <StopOutlined height={50} width={50} />
                </div>
              )}
            </>
          ) : (
            <>
              <img
                src={url}
                alt={`uploaded-${index}`}
                className={`${
                  isDeleted || inActive
                    ? "image-preview-disabled"
                    : "preview-image"
                } ${
                  deletedExistingImages.includes(url) ? "deleted-overlay" : ""
                }`}
              />
              {deletedExistingImages.includes(url) && (
                <div className="deleted-overlay">
                  <StopOutlined height={50} width={50} />
                </div>
              )}
            </>
          )}
        </Tooltip>

        {/* Show delete button only if image is active and not deleted */}
        {!(
          props.taskDetails?.isRecurring &&
          props.editType === PM_EDITING_TYPES.THIS_EVENT
        ) &&
          !inActive &&
          !isDeleted && (
            <DeleteOutlined
              className="delete-icon"
              onClick={() => handleRemove(index)}
            />
          )}

        {/* Show restore button only if image is deleted and active */}
        {!inActive && isDeleted && !deletedExistingImages.includes(url) && (
          <Tooltip title="Restore">
            <UndoOutlined
              className="delete-icon"
              onClick={() => handleRestore(index)}
            />
          </Tooltip>
        )}
      </div>
    );
  };
  const fetchImages = async () => {
    setImageLoader(true);
    try {
      const deletedImages: string[] = [];
      const deletedExistingImageCopy: string[] = [];
      const response = await fetchWorkOrderMedia(
        props.taskDetails?.masterPreventiveMaintenance
          ? props.taskDetails?.masterPreventiveMaintenance?.id
          : props.taskDetails?.id || "",
        { page: 1, limit: 100 },
      );
      setImageLoader(false);
      if (response.status) {
        if (response.data) {
          const previewList: string[] = [];
          response.data?.forEach((image: any) => {
            if (!image.isActive) {
              deletedImages.push(image.filePath);
              deletedExistingImageCopy.push(image.filePath);
            }
            setDeletedImageIds(deletedImages);
            previewList.push(image.filePath);
            setPreviewImages(previewList);
          });
          setDeletedExistingImages(deletedExistingImageCopy);
          props.handleSetWorkOrderImages(response.data);
        }
      }
    } catch (ex) {
      setImageLoader(false);
      logger.log(ex as any);
    }
  };
  useEffect(() => {
    if (!isEmpty(props.taskDetails)) {
      setPriority(props.taskDetails?.priority as any);
      fetchImages();
    }
  }, [props.taskDetails]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            1. Work Order Details
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">1/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          <Col span={24}>
            <AlectifyInput
              name={WORK_ORDER_FORM_FIELD_TYPES.WORK_TITLE}
              placeholder={AlectifyText.WORK_TITLE}
              label={AlectifyText.WORK_TITLE}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.WORK_TITLE,
                  ),
                },
              ]}
              type="text"
            />
          </Col>
          <Col span={12}>
            <AlectifySelect
              name={WORK_ORDER_FORM_FIELD_TYPES.TASK_CATEGORY}
              placeholder={AlectifyText.WORK_ORDER_TYPE}
              label={AlectifyText.WORK_ORDER_TYPE}
              options={getTaskCategoryOptions()}
              onSelect={props.onSelectTaskCategory}
              dropdownStyle={{ height: 400, overflowY: "auto" }}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.WORK_ORDER_TYPE,
                  ),
                },
              ]}
              disabled={props.editing}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.TASK_PRIORITY}
              label={AlectifyText.PRIORITY}
              valuePropName="checked"
            >
              <div
                className="wo-priority-box cursor-pointer"
                // onClick={() =>
                //   setPriority((value: string) =>
                //     value === "NORMAL" ? "CRITICAL" : "NORMAL",
                //   )
                // }
              >
                <div>Critical</div>
                <div>
                  <Checkbox
                    checked={priority === "CRITICAL"}
                    onChange={onChange}
                    disabled={props.editing}
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
          {/* Image Upload Section */}
          <Col span={24} className="wo-image-upload-container">
            <Tooltip
              title={
                props.taskDetails?.isRecurring &&
                props.editType === PM_EDITING_TYPES.THIS_EVENT
                  ? "To edit photos, please select Edit All Events"
                  : ""
              }
            >
              <Col span={24} className="wo-image-upload-container">
                <Upload
                  className="full-width-upload" // Add custom class for full width
                  listType="picture-card"
                  showUploadList={false}
                  fileList={trackFileList}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  accept="image/*,video/*"
                  disabled={
                    props.taskDetails?.isRecurring &&
                    props.editType === PM_EDITING_TYPES.THIS_EVENT
                  }
                  multiple
                >
                  <Space>
                    <CameraOutlined />
                    <span>Upload Photos/Videos</span>
                  </Space>
                </Upload>
              </Col>
            </Tooltip>
          </Col>
          {/* Preview Section */}
          <Col span={24}>
            {imageLoader ? (
              <Space>
                <Skeleton.Image active={imageLoader} />
                <Skeleton.Image active={imageLoader} />
                <Skeleton.Image active={imageLoader} />
                <Skeleton.Image active={imageLoader} />
              </Space>
            ) : (
              <div className="image-preview-container">
                {previewImages.map((image, index) => renderImage(image, index))}
              </div>
            )}
          </Col>
          <Col span={24}>
            <Row justify={"center"} align={"middle"} gutter={[16, 16]}>
              <Col span={12}>
                <SelectMasterProject
                  onChange={() => {}}
                  onSelect={props.onSelectMasterProject}
                  defaultValue={props.selectedMasterProject?.id}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SITE,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
              <Col span={12}>
                <SelectSubProject
                  onSelect={props.onSelectSubProject}
                  masterProjectId={props.selectedMasterProject?.id || ""}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.ASSET_CATEGORY,
                      ),
                    },
                  ]}
                  disabled={props.editing}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.ASSETS}
              label={AlectifyText.ASSETS}
            >
              <Tooltip
                className="alectify-selected-asset-tooltip"
                title={
                  !isEmpty(props.selectedAssets) ||
                  !isEmpty(props.selectedAssetPackage) ? (
                    <ol className="selected-documents-list">
                      {[
                        ...(props.selectedAssets as any),
                        ...(props.selectedAssetPackage as any),
                      ].map((equipment: any) => (
                        <li>
                          {equipment?.name ||
                            equipment.tag_name ||
                            equipment.name ||
                            equipment.package_name}
                        </li>
                      ))}
                    </ol>
                  ) : null
                }
              >
                <AlectifyButton
                  name="Assets"
                  icon={
                    (isEmpty(props.selectedAssetPackage) &&
                      isEmpty(props.selectedAssets) && <PlusOutlined />) || (
                      <></>
                    )
                  }
                  text={
                    getSelectedAssetsText(
                      props.selectedAssets,
                      props.selectedAssetPackage,
                    ) || AlectifyText.ADD_ASSETS
                  }
                  type="primary"
                  className="full-width wo-assets-btn"
                  onClick={props.equipmentModalHandler}
                  disabled={isEmpty(props.selectedSubProject)}
                />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={WORK_ORDER_FORM_FIELD_TYPES.DETAILS}
              label={AlectifyText.DESCRIPTION}
              className="m-0"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.DESCRIPTION,
                  ),
                },
              ]}
            >
              <TextEditor
                placeholder="Type here..."
                name="description"
                initialValue={props.taskDetails?.detail || ""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    WORK_ORDER_FORM_FIELD_TYPES.DETAILS,
                    value,
                  );
                }}
                customClass="wo-description m-0"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(BasicInformation);
