import { Checkbox, Col, Form, Radio, Row, Space } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import AlectifyDatePicker from "components/shared/datepicker";
import AlectifySelect from "components/shared/select";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  WORK_ORDER_DATES,
  WORK_ORDER_DAYS_NAME,
  WORK_ORDER_DAYS_NAME_SHORT,
  WORK_ORDER_EDITING_TYPES,
  WORK_ORDER_FORM_FIELD_TYPES,
  WORK_ORDER_FREQUENCIES,
  WORK_ORDER_WEEKS,
} from "../WorkOrderCreateEdit.constants";
import { capitalize, lowerCase } from "lodash";
import { IWorkOrderScheduleProps } from "../WorkOrderCreateEdit.interface";
import FormItem from "antd/es/form/FormItem";
import { MESSAGES } from "constants/messages";
import AlectifyText from "static/texts.json";
import "../WorkOrderCreateEdit.styles.scss";

const WorkOrderSchedule: React.FC<IWorkOrderScheduleProps> = (
  props: IWorkOrderScheduleProps,
) => {
  const [frequecyType, setFrquencyType] = useState<string>("monthly");
  const [repeatType, setRepeatType] = useState<string>("date");
  const [selectedWeek, setSelectedWeek] = useState<string | null>(
    WORK_ORDER_DAYS_NAME_SHORT[0].value,
  );
  const [selectedFrequency, setSelectedFrequency] = useState<string | null>(
    null,
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };
  // Generate dynamic message for "monthly" frequency
  const getMonthlyMessage = () => {
    if (repeatType === "date") {
      return selectedFrequency && selectedDate
        ? `This work order repeats every ${selectedFrequency} month(s) on the Date of ${selectedDate}th.`
        : "";
    }
    if (repeatType === "day") {
      return selectedFrequency && selectedWeek && selectedDay
        ? `This work order repeats every ${selectedFrequency} month(s) on the ${capitalize(
            selectedWeek,
          )} week of ${capitalize(selectedDay)}.`
        : "";
    }
    return "";
  };
  useEffect(() => {
    if (props.taskDetails) {
      setSelectedDate(props.taskDetails?.date || null);
      setSelectedFrequency(props.taskDetails.frequency || null);
      setRepeatType(props.taskDetails?.dayType);
      setFrquencyType(lowerCase(props.taskDetails.frequencyType || ""));
      setSelectedDate(props.taskDetails.date);
      setSelectedDay(props.taskDetails.day);
      setSelectedWeek(props.taskDetails.week);
      props.FormInstance.setFieldValue(
        WORK_ORDER_FORM_FIELD_TYPES.DAY_DATE,
        props.taskDetails.dayType,
      );
      props.FormInstance.setFieldValue(
        WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_TYPE,
        lowerCase(props.taskDetails.frequencyType || ""),
      );
    }
  }, [props.taskDetails]);

  return (
    <div className="wo-create-schedule-container">
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            3. Schedule your work order
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">3/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={16}>
          <Col span={24}>
            <Form.Item
              name="dueDate"
              label="Due Date"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.SELECT.replace(
                    "{fieldName}",
                    AlectifyText.DUEDATE,
                  ),
                },
              ]}
            >
              <AlectifyDatePicker
                className="full-width-picker"
                disabledDates={(current) => disabledDate(current)}
              />
            </Form.Item>
          </Col>
          {/*   <Col span={12}>
            <Form.Item name="startDate" label="Start Date">
              <AlectifyDatePicker
                className="full-width-picker"
                disabledDates={(current) => disabledDate(current)}
              />
            </Form.Item>
          </Col> */}

          <Col span={24} className="mb-10">
            <Space>
              <span>Do you want to make it Recurring?</span>
              <Checkbox
                checked={props.isRecurring}
                onChange={(e) => {
                  setFrquencyType("monthly");
                  props.onRecurringChange(e.target.checked);
                }}
                disabled={props.editing}
              />
            </Space>
          </Col>
          {props.isRecurring && (
            <Col span={24}>
              {/* Repeat Type Selection */}
              <Form.Item
                className="m-0 wo-repeat-type-selection-container"
                name={WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_TYPE}
              >
                <Radio.Group
                  value={frequecyType}
                  onChange={(e) => {
                    if (
                      e.target.value === "weekly" &&
                      !props.editing &&
                      !props.copying
                    ) {
                      props.FormInstance.setFieldValue(
                        WORK_ORDER_FORM_FIELD_TYPES.DAY,
                        WORK_ORDER_DAYS_NAME_SHORT[0].value,
                      );
                      setSelectedDay(WORK_ORDER_DAYS_NAME_SHORT[0].value);
                    }
                    setFrquencyType(e.target.value);
                  }}
                  disabled={
                    props.editing &&
                    props.editType === WORK_ORDER_EDITING_TYPES.THIS_EVENT
                  }
                >
                  <Radio
                    value="monthly"
                    className={
                      frequecyType === "monthly" ? "active-frequency" : ""
                    }
                  >
                    Monthly
                  </Radio>
                  <Radio
                    value="weekly"
                    className={
                      frequecyType === "weekly" ? "active-frequency" : ""
                    }
                  >
                    Weekly
                  </Radio>
                  <Radio
                    value="daily"
                    className={
                      frequecyType === "daily" ? "active-frequency" : ""
                    }
                  >
                    Daily
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="wo-scheduler-type-container">
                {frequecyType === "monthly" && (
                  <>
                    {" "}
                    <div className="helper-text-wo mb-10">
                      Select up to 24 months or 2 years
                    </div>
                    <FormItem name={WORK_ORDER_FORM_FIELD_TYPES.DAY_DATE}>
                      <Radio.Group
                        value={repeatType}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          props.FormInstance.resetFields([
                            WORK_ORDER_FORM_FIELD_TYPES.DATE,
                            WORK_ORDER_FORM_FIELD_TYPES.DAY,
                            WORK_ORDER_FORM_FIELD_TYPES.WEEK,
                            // WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
                            // WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_DAY,
                          ]);
                          if (
                            e.target.value === "date" &&
                            (props.editing || props.copying)
                          ) {
                            props.FormInstance.setFieldValue(
                              WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
                              props.taskDetails?.frequency,
                            );
                          } else if (
                            e.target.value === "day" &&
                            (props.editing || props.copying)
                          ) {
                            props.FormInstance.setFieldValue(
                              WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_DAY,
                              props.taskDetails?.frequency,
                            );
                          }
                          setRepeatType(e.target.value);
                        }}
                        disabled={
                          props.editType === WORK_ORDER_EDITING_TYPES.THIS_EVENT
                        }
                      >
                        <div className="mb-20">
                          {/* <Space direction="horizontal"> */}
                          <Row justify={"start"} align={"middle"}>
                            <Col span={5}>
                              <Radio
                                value="date"
                                onClick={(e) => e.stopPropagation()}
                                disabled={
                                  props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                }
                              >
                                <span
                                  className={
                                    repeatType !== "date" ||
                                    props.editType ===
                                      WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                      ? "disabled-text"
                                      : ""
                                  }
                                >
                                  Repeats Every
                                </span>
                              </Radio>
                            </Col>
                            <Col span={2} className="wo-select">
                              <AlectifySelect
                                options={WORK_ORDER_FREQUENCIES}
                                name={WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY}
                                className="m-0"
                                rules={
                                  (repeatType === "date" && [
                                    {
                                      required: true,
                                      message:
                                        MESSAGES.FIELD_RULES.REQUIRED.replace(
                                          "{fieldName}",
                                          WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
                                        ),
                                    },
                                  ]) ||
                                  []
                                }
                                disabled={
                                  repeatType !== "date" ||
                                  props.editType ===
                                    WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                }
                                defaultValue={props.taskDetails?.frequency}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(value) =>
                                  setSelectedFrequency(value)
                                }
                              />
                            </Col>
                            <Col span={6}>
                              <div
                                className={`ml-10 ${
                                  repeatType !== "date" ||
                                  props.editType ===
                                    WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                    ? "disabled-text"
                                    : ""
                                }`}
                              >
                                Month(s) on the Date of
                              </div>
                            </Col>
                            <Col span={2} className="wo-select">
                              <AlectifySelect
                                options={WORK_ORDER_DATES}
                                name={WORK_ORDER_FORM_FIELD_TYPES.DATE}
                                className="m-0"
                                disabled={
                                  repeatType !== "date" ||
                                  props.editType ===
                                    WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                }
                                rules={
                                  (repeatType === "date" && [
                                    {
                                      required: true,
                                      message:
                                        MESSAGES.FIELD_RULES.REQUIRED.replace(
                                          "{fieldName}",
                                          WORK_ORDER_FORM_FIELD_TYPES.DATE,
                                        ),
                                    },
                                  ]) ||
                                  []
                                }
                                onClick={(e) => e.stopPropagation()}
                                onChange={(value) => setSelectedDate(value)}
                              />
                            </Col>
                          </Row>

                          {/* </Space> */}
                        </div>
                        <Row justify={"start"} align={"middle"}>
                          <Col span={5}>
                            <Radio value="day">
                              <span
                                className={
                                  repeatType !== "day" ||
                                  props.editType ===
                                    WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                    ? "disabled-text"
                                    : ""
                                }
                              >
                                Repeats Every
                              </span>
                            </Radio>
                          </Col>
                          <Col span={2} className="wo-select">
                            <AlectifySelect
                              options={WORK_ORDER_FREQUENCIES}
                              name={`${WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY_DAY}`}
                              defaultValue={props.taskDetails?.frequency}
                              className="m-0"
                              disabled={
                                repeatType !== "day" ||
                                props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                              }
                              onChange={(value) => {
                                props.FormInstance.setFieldValue(
                                  WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
                                  value,
                                );
                                setSelectedFrequency(value);
                              }}
                              rules={
                                (repeatType === "day" && [
                                  {
                                    required: true,
                                    message:
                                      MESSAGES.FIELD_RULES.REQUIRED.replace(
                                        "{fieldName}",
                                        WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
                                      ),
                                  },
                                ]) ||
                                []
                              }
                            />
                          </Col>
                          <Col span={4} className="text-align-center">
                            <span
                              className={
                                repeatType !== "day" ||
                                props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                  ? "disabled-text"
                                  : ""
                              }
                            >
                              Month(s) on the
                            </span>
                          </Col>
                          <Col span={4} className="wo-select">
                            <AlectifySelect
                              options={WORK_ORDER_WEEKS}
                              name={WORK_ORDER_FORM_FIELD_TYPES.WEEK}
                              className="wo-frequency-week-selection m-0"
                              disabled={
                                repeatType !== "day" ||
                                props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                              }
                              onChange={(value) => setSelectedWeek(value)}
                              rules={
                                (repeatType === "day" && [
                                  {
                                    required: true,
                                    message:
                                      MESSAGES.FIELD_RULES.REQUIRED.replace(
                                        "{fieldName}",
                                        WORK_ORDER_FORM_FIELD_TYPES.WEEK,
                                      ),
                                  },
                                ]) ||
                                []
                              }
                            />
                          </Col>
                          <Col span={2} className="ml-10">
                            <span
                              className={
                                repeatType !== "day" ||
                                props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                                  ? "disabled-text"
                                  : ""
                              }
                            >
                              Week of
                            </span>
                          </Col>
                          <Col span={4} className="wo-select">
                            <AlectifySelect
                              options={WORK_ORDER_DAYS_NAME}
                              name={WORK_ORDER_FORM_FIELD_TYPES.DAY}
                              className="wo-frequency-day-selection m-0"
                              disabled={
                                repeatType !== "day" ||
                                props.editType ===
                                  WORK_ORDER_EDITING_TYPES.THIS_EVENT
                              }
                              onChange={(value) => setSelectedDay(value)}
                              rules={
                                (repeatType === "day" && [
                                  {
                                    required: true,
                                    message:
                                      MESSAGES.FIELD_RULES.REQUIRED.replace(
                                        "{fieldName}",
                                        WORK_ORDER_FORM_FIELD_TYPES.DAY,
                                      ),
                                  },
                                ]) ||
                                []
                              }
                            />
                          </Col>
                        </Row>
                      </Radio.Group>
                    </FormItem>
                  </>
                )}
                {frequecyType === "weekly" && (
                  <Space>
                    <span>Repeats Every</span>
                    <Form.Item
                      name={WORK_ORDER_FORM_FIELD_TYPES.DAY}
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                            "{fieldName}",
                            WORK_ORDER_FORM_FIELD_TYPES.DAY,
                          ),
                        },
                      ]}
                    >
                      {WORK_ORDER_DAYS_NAME_SHORT.map((day) => (
                        <span
                          className={`wo-alectify-days-name mr-5 ${
                            selectedDay === day.value ? "active" : ""
                          }`}
                          onClick={() => {
                            props.FormInstance.setFieldValue(
                              WORK_ORDER_FORM_FIELD_TYPES.DAY,
                              day.value,
                            );
                            setSelectedDay(day.value);
                          }}
                          key={day.value}
                        >
                          {day.label}
                        </span>
                      ))}
                    </Form.Item>
                  </Space>
                )}
                {(frequecyType === "daily" && (
                  <div className="wo-alectify-schedule-helper-text">
                    <span>This work order repeats daily.</span>
                  </div>
                )) ||
                  (frequecyType === "weekly" && selectedDay && (
                    <div className="wo-alectify-schedule-helper-text">
                      <span>
                        This work order repeats weekly on{" "}
                        {(selectedDay && capitalize(selectedDay)) || ""}.
                      </span>
                    </div>
                  )) ||
                  (frequecyType === "monthly" && (
                    <div className="wo-alectify-schedule-helper-text">
                      {getMonthlyMessage()}
                    </div>
                  ))}
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default WorkOrderSchedule;
