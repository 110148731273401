import React from "react";
import ReactApexChart from "react-apexcharts";
import { IAlectifyChartProps } from "./chart.interface";
import "./chart.styles.scss";
const AlectifyBarChart: React.FC<IAlectifyChartProps> = (
  props: IAlectifyChartProps,
) => {
  return (
    <div
      className={`chart-body ${props.className || ""}`}
      onClick={props.onClick}
    >
      <ReactApexChart
        options={props.options}
        series={props.series}
        type={props.type}
        height={props.height ? props.height : "auto"}
      />
    </div>
  );
};

export default AlectifyBarChart;
