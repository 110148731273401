import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";
import { isEmpty, truncate } from "lodash";
import { IUser } from "redux/components/Auth";
import { GreetingEnum, UserTypeEnum } from "enums";
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT_12H,
  DISPLAY_DATE_TIME_FORMAT_24H,
} from "constants/index";
import "moment-timezone"; // Importing the module for type definitions
import { FOLDER_NAME } from "enums/folder-type.enum";
import store from "redux/store/store";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import {
  getNotifications,
  getUnreadNotificationsCount,
} from "redux/components/notifications/sources";
import { jwtDecode } from "jwt-decode";
import { ISources } from "components/chatbot-ui/Chatbot.interface";
import { config } from "./configs";

dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * Generates a user full name based on user first and last name
 * @param {IUser} user - The user object.
 * @returns The user full name
 */
export const getFullUserName = (user: IUser): string => {
  return `${user?.first_name} ${user?.last_name}`;
};

/**
 * Generates a user image URL based on user data.
 *
 * @param {IUser} user - The user object.
 * @returns {string} The URL of the user image.
 */
export const getUserImage = (user: IUser): string => {
  // If user already has an image, return it
  if (user?.image || user?.image_url) {
    return user.image || user.image_url || "";
  }

  // Extract the first initial of the first name (default to 'N' if unavailable)
  const firstInitial = user?.first_name?.[0] || "N";

  // Extract the first initial of the last name (default to 'A' if unavailable)
  const secondInitial = user?.last_name?.[0] || "A";

  // Generate a user image URL with ui-avatars.com
  // Customize the URL format as needed (e.g., specify background color)
  return `https://ui-avatars.com/api/?background=random&name=${firstInitial}+${secondInitial}`;
};

/**
 * Get a greeting message based on the given hour of the day.
 *
 * @returns A greeting message such as "Good Morning," "Good Afternoon," "Good Evening," or "Good Night."
 */
export const getGreetingMessage = (): string => {
  const currentHour = dayjs().hour();

  if (currentHour >= 4 && currentHour < 12) {
    return GreetingEnum.MORNING;
  } else if (currentHour >= 12 && currentHour < 17) {
    return GreetingEnum.AFTERNOON;
  } else if (currentHour >= 17 && currentHour < 21) {
    return GreetingEnum.EVENING;
  } else {
    return GreetingEnum.EVENING;
  }
};

// Get the current year dynamically
export const currentYear = new Date().getFullYear();

/**
 * Formats a given date into a string representation with optional time and 12-hour format.
 *
 * @param {Date | null | undefined} date - The date to be formatted.
 * @param {boolean} showTime - Indicates whether to include time in the formatted string.
 * @param {boolean} use12HourFormat - Indicates whether to use 12-hour time format.
 * @returns {string} - The formatted date string.
 */

export const displayDateTime = (
  date: Date | null | undefined,
  showTime: boolean = false,
  use12HourFormat: boolean = false,
): string => {
  const format = getDisplayDateTimeFormat(showTime, use12HourFormat);

  if (date) {
    try {
      const localDate = convertUtcToLocal(date);
      return dayjs(localDate).format(format);
    } catch (error) {
      console.error(error);
    }
  }
  return "";
};

/**
 * Determines the display format for the date and time based on user preferences.
 *
 * @param {boolean} showTime - Indicates whether to include time in the display format.
 * @param {boolean} use12HourFormat - Indicates whether to use 12-hour time format.
 * @returns {string} - The display format for the date and time.
 */

const getDisplayDateTimeFormat = (
  showTime: boolean,
  use12HourFormat: boolean,
): string => {
  return showTime
    ? use12HourFormat
      ? DISPLAY_DATE_TIME_FORMAT_12H
      : DISPLAY_DATE_TIME_FORMAT_24H
    : DISPLAY_DATE_FORMAT;
};

/**
 * Capitalizes the first letter of a sentence and converts the rest to lowercase.
 * @param {string} value - The input sentence to be transformed.
 * @returns {string} The sentence with the first letter capitalized and the rest in lowercase.
 */
export const capitalizeFirstLetter = (value: string): string => {
  // Split the sentence into words
  const words = value.split(" ");

  // Capitalize the first letter of each word and convert the rest to lowercase
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );

  // Join the capitalized words back into a sentence
  return capitalizedWords.join(" ");
};

export const isExternalUser = (user: IUser) => {
  return !isEmpty(user) && user.user_type === UserTypeEnum.EXTERNAL;
};

export const isAdmin = (user: IUser) => {
  return user.user_type === UserTypeEnum.ADMIN;
};
/**
 * Converts a snake_case string to a title case string.
 *
 * @param {string} slug - The snake_case string to convert.
 * @returns {string | null} - The title case representation of the input string, or null if the input is empty.
 */
export const snakeCaseToTitle = (slug: string): string => {
  let words = slug.split("_");

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

// Remove empty or whitespace-only values from an object
export const removeEmptyValues = <T extends Record<string, any>>(obj: T): T => {
  const newObj = {} as T;
  Object.keys(obj).forEach((key) => {
    const value = obj[key as keyof T];
    if (
      value !== undefined &&
      value !== null &&
      value.toString().trim() !== ""
    ) {
      newObj[key as keyof T] = value;
    }
  });
  return newObj;
};

export const convertUtcToLocal = (utcDate: Date): string => {
  // Convert UTC date to local time
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const localDate = dayjs.utc(utcDate).tz(timeZone);
  // Format the local date as a string (you can customize the format as needed)
  const formattedLocalDate = localDate.format("YYYY-MM-DD HH:mm:ss");

  return formattedLocalDate;
};

export const preparePayloadForTaskAttachment = (
  item: any,
  directory: any,
  projectId: string,
  areaId: string | null,
  tagId: string | null,
  user: IUser,
  taskId: string,
  isSumbit?: boolean,
) => {
  return item?.map((fileObj: any) => ({
    folder: isSumbit
      ? FOLDER_NAME.SUBMIT_FOR_REVIEW
      : FOLDER_NAME.DOCUMENT_UPLOAD,
    file_name: fileObj.name,
    s3_url: `${projectId}/${tagId ?? areaId}/${directory}/${fileObj.name}`,
    user_id: user?.id,
    file_type: fileObj?.type,
    service_id: taskId,
    tag_id: tagId,
    area_id: areaId,
  }));
};

export const prepareRequestBodyForTaskAttachment = (
  item: any,
  projectId: string,
  areaId: string | null,
  tagId: string | null,
  isSumbit?: boolean,
) => {
  return item?.map((fileObj: any) => ({
    file_name: fileObj.name,
    file_type: fileObj?.type,
    folder: isSumbit
      ? FOLDER_NAME.SUBMIT_FOR_REVIEW
      : FOLDER_NAME.DOCUMENT_UPLOAD,
    project_id: projectId,
    tag_id: tagId,
    area_id: areaId,
    quotation_required: false,
  }));
};

/**
 * Generate a Universally Unique Identifier (UUID).
 *
 * This function uses the crypto API if available to create a secure UUID. In environments
 * without the crypto API, it falls back to a less secure, random-based method.
 *
 * @returns {string} A UUID string with the format "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".
 */
export const generateUUID = () => {
  const cryptoObj = window.crypto;
  if (cryptoObj) {
    const buffer = new Uint16Array(8);
    cryptoObj.getRandomValues(buffer);
    return (
      pad4(buffer[0]) +
      pad4(buffer[1]) +
      "-" +
      pad4(buffer[2]) +
      "-4" +
      pad4(buffer[3] & 0xfff) +
      "-a" +
      pad4((buffer[4] & 0x3f) | 0x80) +
      "-" +
      pad4(buffer[5] & 0xfff) +
      pad4(buffer[6]) +
      pad4(buffer[7])
    );
  } else {
    // Fallback for environments without crypto API
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }
};

const pad4 = (value: number) => {
  return value.toString(16).padStart(4, "0");
};

type AmountNotation =
  | "compact"
  | "standard"
  | "scientific"
  | "engineering"
  | undefined;

/**
 * Renders a numeric value as a formatted USD currency string.
 *
 * @param value - The value to be formatted as USD currency.
 * @returns A string representing the formatted USD currency value.
 */
export const renderAmount = (
  value: any,
  notation: AmountNotation = "compact",
) => {
  value = value instanceof String ? parseInt(value as string) : value;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: notation,
  }).format(value || 0);
};

/**
 * converts a duration string and adds time units e.g 12:30 => 12 hrs 30 mins.
 *
 * @param {string} inputTime - Argument in string to convert.
 * @returns string for example 12 hrs 30 mins.
 */
export const convertStringIntoHrsAndMins = (inputTime: string): string => {
  // Split the input time string into hours and minutes
  const [hours, minutes] = inputTime.split(":");

  // Convert the hours and minutes to strings
  const hoursString = `${hours ? parseInt(hours, 10) : 0} hrs`;
  const minutesString = `${minutes ? parseInt(minutes, 10) : 0} mins`;

  // Return the formatted string
  return `${hoursString} ${minutesString}`;
};

/**
 * Formats a date using the provided format.
 *
 * @param {Dayjs} date - The date to be formatted.
 * @param {string} format - The format string (default is "MMM DD, YYYY").
 * @returns {string} - The formatted date as a string, or "-" if the input date is falsy.
 */
export const formatDate = (
  date: Dayjs | null,
  format: string = DISPLAY_DATE_FORMAT,
): string => {
  if (!date || !date.isValid()) {
    return "-";
  }
  return date.format(format);
};

export const truncateString = (input: string, count: number): string => {
  if (input.length <= count) {
    return input;
  } else {
    return input.substring(0, count - 3) + "...";
  }
};

/**
 * Truncates a file name while preserving the file type.
 *
 * @param {string} fileName - The original file name to be truncated.
 * @param {number} maxLength - The maximum length for the truncated file name (excluding file type).
 * @returns {string} The truncated file name with ellipsis (...) while preserving the file type.
 */
export const truncateFileName = (
  fileName: string,
  maxLength: number,
): string => {
  const extensionIndex = fileName.lastIndexOf(".");
  const extension = fileName.slice(extensionIndex);
  const truncatedName = fileName.slice(0, maxLength);
  return truncatedName + "..." + extension;
};

/**
 * Converts an enum-style string to a user-friendly title.
 * Replaces underscores with spaces and capitalizes each word.
 *
 * @param {string} text - The input string to be formatted.
 * @returns {string} - The formatted title.
 *
 * @example
 * const backendText = "DOCUMENT_UPLOAD";
 * const formattedTitle = enumToTitle(backendText);
 * console.log(formattedTitle); // Output: "Document Upload"
 */
export const enumToTile = (text: string): string => {
  return text
    .replace(/_/g, " ")
    .replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .replace("Pm", "PM");
};

/*Get file Extension using by filename
 * It split the string by "." and return the last array element using pop()
 */

export const getExtension = (text: string) => {
  return text?.split(".")?.pop();
};

/**
 * Extracts file name from signed url.
 * @param {string} singedURL - The input string to be formatted.
 * @returns {string} - filename as string.
 */
export const extractFileNameFromSignedURL = (singedURL: string) => {
  if (!singedURL) {
    return null;
  }
  const pathname = new URL(singedURL).pathname;
  const filename = pathname.split("/").pop();
  const cleanedFilename = filename?.replace(/%/g, "");
  return cleanedFilename;
};

/**
 * Extracts file path from signed url.
 * @param {string} signedURL - The input string to be formatted.
 * @returns {string} - filepath as string.
 */
export const extractFilePathFromSignedURL = (
  signedURL: string,
): string | null => {
  if (!signedURL) {
    return null;
  }

  try {
    const pathname = new URL(signedURL).pathname;

    // Remove the filename from the pathname to get the file path
    const pathParts = pathname.split("/");
    pathParts.pop(); // Remove the last part which is the filename
    const filePath = pathParts.join("/");

    return filePath;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};

// /**
//  * Extracts the file name from the signed URL.
//  *
//  * @param {string} filePath - The file path containing the file name.
//  * @returns {string|null} - The extracted file name or null if the input is falsy.
//  */

export const extractFileName = (filePath: string) => {
  if (!filePath) {
    return null;
  }

  const pathParts = filePath.split("/");
  const fileName = pathParts[pathParts.length - 1];

  return fileName;
};

/**
 * Checks if a specific feature is enabled based on the provided feature name.
 *
 * @param {string} featureName - The name of the feature to check.
 * @returns {boolean} - True if the feature is enabled, otherwise false.
 */

export const isFeatureEnabled = (featureName: string) => {
  const featureFlags = (store.getState() as any).auth?.user?.features;
  if (featureFlags) return featureFlags[featureName];
};

/*
 Get Sum of array values 
*/
export const sumValues = (values: any) => {
  return values.reduce((total: number, value: number) => total + value, 0);
};

/**
 * Formats the title text for a date range.
 * @param {any} fromDate - The starting date of the range.
 * @param {any} toDate - The ending date of the range.
 * @returns {string} - The formatted title text representing the date range.
 */

export const formatTitleText = (fromDate: any, toDate: any) => {
  const formatMonthYear = (date: any) => date.format("MMMM YYYY");
  const fromMonthYear = formatMonthYear(fromDate);

  const toMonthYear = formatMonthYear(toDate);

  return fromMonthYear === toMonthYear
    ? fromMonthYear
    : `${fromMonthYear} - ${toMonthYear}`;
};

export const updateComponentByNotfication = (
  type: string,
  callback: (payload?: any) => void,
  sync: boolean = true,
) => {
  onMessage(messaging, (payload: any) => {
    try {
      if (payload?.data?.type === type) {
        callback(payload);
        if (sync) {
          store.dispatch(getUnreadNotificationsCount());
          store.dispatch(getNotifications({ page: 1, limit: 50 }));
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  });
};

// Get image Extensions
export const imageExtensions = ["png", "jpg", "jpeg"];

// Get the filename from url

export const getFileNameFromS3Url = (imageUrl: string) => {
  const pathSegments = imageUrl.split("/");
  return pathSegments[pathSegments.length - 1];
};

export const getFileNameAndExtention = (name: string) => {
  const [fileName, extension] = name.split(".");

  return { fileName: fileName || "", extension: extension || "" };
};

/**
 * Checks if a given URL points to a video file based on its extension.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - True if the URL points to a video file, otherwise false.
 */
export const isVideoFileType = (url: string): boolean => {
  const videoExtensions = [
    "mp4", // MPEG-4 Video
    "avi", // Audio Video Interleave
    "mov", // QuickTime Movie
    "wmv", // Windows Media Video
    "flv", // Flash Video
    "mkv", // Matroska Video
    "webm", // WebM Video
    "3gp", // 3GPP Multimedia File
    "m4v", // MPEG-4 Video
    "mpeg", // MPEG Video
    "mpg", // MPEG Video
    "ogv", // Ogg Video
  ];

  try {
    const fileName = new URL(url).pathname.split("/").pop();
    const fileExtension = fileName?.split(".").pop()?.toLowerCase();
    return videoExtensions.includes(fileExtension || "");
  } catch (error) {
    console.error("Invalid URL:", error);
    return false;
  }
};
/**
 * Generates a thumbnail image from a video file.
 *
 * @param {File} file - The video file to generate the thumbnail from.
 * @returns {Promise<string>} - A promise that resolves to the thumbnail image as a base64 string.
 */
export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.crossOrigin = "anonymous"; // Avoid CORS issues
    video.muted = true;
    video.playsInline = true;

    video.addEventListener("loadeddata", () => {
      video.currentTime = 2; // Capture a frame at 2 seconds
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 200; // Adjust preview size
      canvas.height = 120;

      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailUrl = canvas.toDataURL("image/png");
        resolve(thumbnailUrl);
      } else {
        reject("Canvas context not available");
      }

      URL.revokeObjectURL(video.src); // Cleanup memory
    });

    video.addEventListener("error", (e) => reject(`Video error: ${e.message}`));
  });
};

/**
 * Extracts the file path from an S3 URL.
 *
 * This function handles both path-style and virtual-hosted S3 URLs.
 *
 * @param {string} s3Url - The S3 URL to extract the file path from.
 * @returns {string | null} - The extracted file path, or null if the URL is invalid.
 *
 * @example
 * const pathStyleUrl = "https://s3.amazonaws.com/my-bucket/folder/file.txt";
 * const virtualHostedUrl = "https://my-bucket.s3.amazonaws.com/folder/file.txt";
 * console.log(getFilePathFromS3Url(pathStyleUrl)); // Output: "folder/file.txt"
 * console.log(getFilePathFromS3Url(virtualHostedUrl)); // Output: "folder/file.txt"
 */
export const getFilePathFromS3Url = (s3Url: string): string | null => {
  try {
    return decodeURIComponent(s3Url.split("?")[0].split(".com/").reverse()[0]);
  } catch (error) {
    console.error("Invalid S3 URL:", error);
    return null;
  }
};
/**
 * Remove null and undefined values from a given object.
 * @param {any} obj. Object to perform operations on.
 * @returns {any}- object of type any.
 */
export const removeNullUndefined = <T>(obj: T): T => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};

/**
 * download csv file.
 */
export const downloadCSV = (csvContent: any) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", "sample.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * sample csv file.
 */
export const csvContent = `
Item #,Parent Asset,Sub Asset,Description,Manufacturer,Model,Model Serial Number,Preferred Supplier,Installation Date,Warranty End Date,Asset Type,Status,Group,Location
1,HV-1 (HV Switch) ,,27.6KV Primary Switch,S&C Electric,HV27kV,HV123456,S&C Electric,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
2,TX-2 (Power Transformer) ,,10 MVA Transformer - 27.6KV/4.16kV,ABB,TX10MVA,TX123457,Wesco,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
3,MV Switchgear - MV-1,,2000 A MV Switchgear,Siemens,VAC2000,BKR1235,Wesco,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
3.1,MV Switchgear - MV-1,MB-2 (Main Breaker) ,2000 A Main Breaker,Siemens,VAC2000,BKR1235,Wesco,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
3.2,MV Switchgear - MV-1,MB-1 (Main Breaker) ,2000 A Main Breaker,Siemens,VAC2000,BKR1234,Wesco,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
3.3,MV Switchgear - MV-1,MB-T (Tie Breaker),2000 A Tie Breaker,Siemens,VAC2000,BKR1236,Wesco,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
4,DRV- 1 (800 HP Drive),,5kV Drive - 800HP,Toshiba,MV800,T12456,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
5,DRV-2 (1200 HP Drive),,5kV Drive - 1200HP,Toshiba,MV1200,T12457,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
6,PNL-1 (1200A Panelboard),,1200A Panelboard,Eaton,MBP1200,ETB12234,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
6.1,PNL-1 (1200A Panelboard),MBP-1 (1200 Breaker),1200 Main Breaker,Eaton,MBP1200,ETB12234,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
6.2,PNL-1 (1200A Panelboard),PQM-1 (Meter),Power Quality Meter,Multilin,EPM9000,M1234,Multilin,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
6.3,PNL-1 (1200A Panelboard),SPD-1 (Surge Protection),Surge Protection Device - 200kA,Eaton,SPD200KA,ETS1234,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
7,PNL-2 (1200A Panelboard),,1200 A Panelboard,Eaton,MBP1200,ETB12235,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
7.1,PNL-2 (1200A Panelboard),MBP-2 (1200 Breaker),1200 Main Breaker,Eaton,MBP1200,ETB12235,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
7.2,PNL-2 (1200A Panelboard),PQM-2 (Meter),Power Quality Meter,Multilin,EPM9000,M1235,Multilin,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
7.3,PNL-2 (1200A Panelboard),SPD-2 (Surge Protection),Surge Protection Device - 200kA,Eaton,SPD200KA,ETS1235,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
8,MCC-1 (1200A),,1200A MCC,Rockwell,RSZ1,R12456,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
8.1,MCC-1 (1200A),ST-1 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12456,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
8.2,MCC-1 (1200A),ST-2 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12457,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
8.3,MCC-1 (1200A),ST-3 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12458,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
8.4,MCC-1 (1200A),ST-4 (Starter Size 3),FVR Size 3 Motor Starter,Rockwell,RSZ3,R12459,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9,MCC-2 (800A),,800 A MCC,Rockwell,RSZ1,R12468,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9.1,MCC-2 (800A),ST-8 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12468,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9.2,MCC-2 (800A),ST-9 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12469,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9.3,MCC-2 (800A),ST-10 (Starter Size 1),FVNR Size 1 Motor Starter,Rockwell,RSZ1,R12470,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9.4,MCC-2 (800A),ST- 11 (Starter Size 3),FVR Size 3 Motor Starter,Rockwell,RSZ3,R12471,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
9.5,MCC-2 (800A),ST- 12 (Starter Size 3),FVR Size 3 Motor Starter,Rockwell,RSZ3,R12472,Graybar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
10,SW-1 (Switch 100A),,100A Disconnect Switch,Mersen,M100A,SW12244,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
11,SW-2 (Switch 100A),,100A Disconnect Switch,Mersen,M100A,SW12245,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
12,SW-3 (Switch 100A),,100A Disconnect Switch,Mersen,M100A,SW12246,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
13,SW-4 (Switch 100A),,100A Disconnect Switch,Mersen,M100A,SW12247,Sonepar,02-10-2024,02-09-2026,Electrical,Active,,Cooling Tower
`;

/**
 * Serial Number Validation.
 */
export const validateSerialNumber = (_: any, value: string | number) => {
  // Ensure value is treated as a string
  const stringValue = value.toString();

  // Check if it's a decimal number
  if (/\./.test(stringValue)) {
    // Split the value into left and right sides of the decimal
    const [leftSide, rightSide] = stringValue.split(".");

    // Check the number of digits on each side
    if (leftSide.length > 10) {
      return Promise.reject(new Error("Max limit reached"));
    }

    if (rightSide.length > 5) {
      return Promise.reject(new Error("Max limit reached"));
    }
  } else {
    // No decimal point found, only check the left side condition
    if (stringValue.length > 10) {
      return Promise.reject(new Error("Max limit reached"));
    }
  }

  // If all checks pass, resolve the Promise
  return Promise.resolve();
};

/**
 * Truncates the given text to the specified length, appending "..." if the text is longer than the specified length.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} length - The maximum length of the truncated text.
 * @returns {string} - The truncated text with "..." appended if it exceeds the specified length.
 */

export const truncateText = (text: string, length: number) =>
  truncate(text, { length, omission: "..." });

/**
 * This function transforms an input data array to extract and return the `user` property
 * of each object in the array.
 */
export const extractUserFromTeamMembers = (data: any) => {
  const teamMembers = data?.map((member: any) => member.user);
  return teamMembers;
};

/**
 * This function transforms an input data array to extract and return the `user id` property
 * of each object in the array.
 */
export const extractTeamMembersId = (data: any) => {
  const teamMembers = data?.map((member: any) => member.user.id);
  return teamMembers;
};

/**
 * Checks if token is expired or not.
 * @param {string} token - Token to check expiry.
 * @returns {boolean} - returns boolean if token is expired it will return true otherwise false.
 */
export const isTokenExpired = (token: string): boolean => {
  if (!token) return true;

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    // console.error("Error decoding token:", error);
    return true; // If there's an error decoding, assume the token is expired
  }
};

/**
 * Extracts texts from html.
 * @param { string } - html to extract text from.
 * @returns { string }
 */
export const extractTextFromHTML = (html: string): string => {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a Document
  const doc = parser.parseFromString(html, "text/html");

  // Use textContent to extract plain text from the parsed HTML
  return doc.body.textContent || "";
};

/**
 *  This function checks if a given file name has an audio file extension.
 *  It returns true if the file extension is one of the recognized audio formats, otherwise false.
 * */

export const isAudioFileType = (fileName: string): boolean => {
  const audioExtensions = [
    "mp3", // MPEG Layer-3
    "wav", // Waveform Audio
    "aac", // Advanced Audio Codec
    "flac", // Free Lossless Audio Codec
    "ogg", // Ogg Vorbis
    "mpeg", // MPEG audio
    "m4a", // MPEG-4 Audio
    "wma", // Windows Media Audio
    "opus", // Opus audio codec
    "aiff", // Audio Interchange File Format
    "alac", // Apple Lossless Audio Codec
    "pcm", // Pulse-code modulation (raw audio data)
    "amr", // Adaptive Multi-Rate audio
    "au", // Audio format used on Unix systems
    "ra", // RealAudio
    "caf", // Core Audio Format (Apple)
    "dts", // Digital Theater Systems audio
    "ac3", // Dolby Digital audio
    "midi", // Musical Instrument Digital Interface
    "kar", // Karaoke MIDI
    "mod", // Tracker module format
    "xm", // FastTracker 2 Extended Module
    "it", // Impulse Tracker Module
    "s3m", // Scream Tracker 3 Module
  ];
  const fileExtension = fileName?.split(".").pop()?.toLowerCase();
  return audioExtensions.includes(fileExtension || "");
};

export function convertArrayToStrings(arr: any[]) {
  // Check if the array contains objects
  const containsObjects = arr.some((item) => typeof item === "object");

  // If the array contains objects, convert them to strings
  if (containsObjects) {
    return arr.map((item) =>
      typeof item === "object" ? item?.user?.id : item,
    );
  }

  // If the array doesn't contain objects, return the original array
  return arr;
}

export function jsonToCsv<T extends Record<string, any>>(
  jsonArray: T[],
): string {
  if (!Array.isArray(jsonArray) || jsonArray.length === 0) {
    throw new Error("Input must be a non-empty array of objects.");
  }

  // Utility to convert camelCase to normal string
  const camelCaseToNormal = (key: string): string => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert a space before uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  // Extract the keys (columns) from the first object
  const headers = Object.keys(jsonArray[0]);

  // Map headers to readable format
  const headerRow = headers.map(camelCaseToNormal).join(",");

  // Map data rows
  const dataRows = jsonArray.map((obj) =>
    headers
      .map((header) => {
        const value = obj[header];
        // Escape quotes and wrap in double quotes if necessary
        return typeof value === "string"
          ? `"${value.replace(/"/g, '""')}"`
          : value ?? ""; // Handle null/undefined as empty string
      })
      .join(","),
  );

  // Combine header and data rows
  const csvRows = [headerRow, ...dataRows];

  // Join all rows with a newline
  return csvRows.join("\n");
}

export const addParamsToUrl = (params: Record<string, string>) => {
  const url = new URL(window.location.href);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value); // Add or update the parameter
  });
  window.history.pushState({}, "", url); // Update the URL without re-rendering
};

export const calculateCitationScore = (source: ISources): boolean => {
  if (!isEmpty(source)) {
    const threshold = config.REACT_APP_CITATION_THRESHOLD ?? 0.65;
    return source.score > threshold;
  } else {
    return false;
  }
};

// Function to calculate the positive difference in days between two dates
export const calculateDaysDifference = (
  date1: string | number | Date,
  date2: string | number | Date,
): number => {
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error("Invalid date provided.");
  }

  // Set both dates to midnight to avoid time zone and daylight savings issues
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const differenceInTime = Math.abs(startDate.getTime() - endDate.getTime()); // Absolute difference in milliseconds
  return Math.floor(differenceInTime / (1000 * 60 * 60 * 24)); // Convert to positive days and use floor instead of ceil
};
