enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

type Loggable = string | number | boolean | object | null | undefined | Error;

class Logger {
  private logLevel: LogLevel;

  constructor(logLevel: LogLevel = LogLevel.DEBUG) {
    this.logLevel = logLevel;
  }

  public debug(...args: Loggable[]): void {
    if (
      process.env.REACT_APP_ENV !== "production" &&
      this.logLevel <= LogLevel.DEBUG
    ) {
      console.debug("[DEBUG]", ...args);
    }
  }

  public info(...args: Loggable[]): void {
    if (
      process.env.REACT_APP_ENV !== "production" &&
      this.logLevel <= LogLevel.INFO
    ) {
      console.info("[INFO]", ...args);
    }
  }

  public warn(...args: Loggable[]): void {
    if (
      process.env.REACT_APP_ENV !== "production" &&
      this.logLevel <= LogLevel.WARN
    ) {
      console.warn("[WARN]", ...args);
    }
  }

  public error(...args: Loggable[]): void {
    if (
      process.env.REACT_APP_ENV !== "production" &&
      this.logLevel <= LogLevel.ERROR
    ) {
      console.error("[ERROR]", ...args);
    }
  }

  public log(...args: Loggable[]): void {
    if (process.env.REACT_APP_ENV !== "production") {
      console.log("[LOG]", ...args);
    }
  }
}

const logger = new Logger();

export default logger;
