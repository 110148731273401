import { Col, DatePicker, Form, Row } from "antd";
import TextEditor from "components/text-editor/TextEditor";
import { IR_FIELD_NAMES } from "../IncidentReportCreateEdit.constants";
import { IIncidentActionsProps } from "../IncidentReportCreateEdit.interface";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import dayjs from "dayjs";

export const IncidentAction: React.FC<IIncidentActionsProps> = (
  props: IIncidentActionsProps,
) => {
  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            4. Actions to take
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">4/5</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"} gutter={10}>
          {/*  <Col span={props.editing ? 24 : 6} className="mb-10">
            <Form.Item
              name={IR_FIELD_NAMES.EMAIL_TO_CLIENT}
              valuePropName="checked"
              className="m-0"
            >
              <Checkbox>Email to Client</Checkbox>
            </Form.Item>
          </Col> */}
          {!props.editing && (
            <>
              <Col span={12}>
                <Form.Item
                  name={IR_FIELD_NAMES.TIME_OF_CALL}
                  label={"Date/Time of Call (24 hrs)"}
                >
                  <DatePicker
                    showTime={
                      {
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      } as any
                    }
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={IR_FIELD_NAMES.NEXT_UPDATE}
                  label={"Next Update (24 hrs)"}
                >
                  <DatePicker
                    showTime={
                      {
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      } as any
                    }
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={IR_FIELD_NAMES.DESCRIPTION}
                  label={AlectifyText.DESCRIPTION}
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.DESCRIPTION,
                      ),
                    },
                  ]}
                >
                  <TextEditor
                    placeholder="Type here..."
                    name="description"
                    initialValue={props.incidentDetail?.description || ""}
                    onChange={(value) => {
                      props.FormInstance.setFieldValue(
                        IR_FIELD_NAMES.DESCRIPTION,
                        value,
                      );
                    }}
                    customClass="wo-description m-0"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={IR_FIELD_NAMES.SYSTEM_STATE}
                  label={AlectifyText.SYSTEM_STATE}
                  className="m-0"
                  rules={[
                    {
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SYSTEM_STATE,
                      ),
                    },
                  ]}
                >
                  <TextEditor
                    placeholder="Type here..."
                    name={IR_FIELD_NAMES.SYSTEM_STATE}
                    onChange={(value) => {
                      props.FormInstance.setFieldValue(
                        IR_FIELD_NAMES.SYSTEM_STATE,
                        value,
                      );
                    }}
                    customClass="wo-description m-0"
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item
              label={"Actions"}
              name={IR_FIELD_NAMES.ACTIONS}
              className="m-0"
            >
              <TextEditor
                placeholder="Type here..."
                name={IR_FIELD_NAMES.ACTIONS}
                initialValue={props.incidentDetail?.actions || ""}
                onChange={(value) => {
                  props.FormInstance.setFieldValue(
                    IR_FIELD_NAMES.ACTIONS,
                    value,
                  );
                }}
                customClass="wo-description m-0"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IncidentAction;
