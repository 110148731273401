import { Avatar, Col, Row, Space, Typography } from "antd";
import { useState } from "react";
import { DataRoundFrequncyEnum } from "../create-edit/DrProjectCreation.interface";
import { DATA_ROUND_DAYS_NAME_MAPPER } from "../create-edit/DrProject.constants";
import {
  ICommentsRoundsHistory,
  IDocsRoundsHistory,
  IRoundsHistoryTimelineProps,
} from "./Rounds.interface";
import RoundsIssueStatus from "components/rounds-issue-status/RoundsIssueStatus";
import { isEmpty } from "lodash";
import { CaretRightOutlined } from "@ant-design/icons";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AlectifyModal from "components/shared/modal";
import ViewImage from "components/shared/view-image/ViewImage";
import AlectifyText from "static/texts.json";
import "./roundsHistory.style.scss";

const RoundsHistoryTimeline: React.FC<IRoundsHistoryTimelineProps> = ({
  shift,
  checkpoint,
}) => {
  const [showComments, setShowComments] = useState(true);
  const [showDocuments, setShowDocuments] = useState(true);
  const formatTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(/[:.]/);
    return `${hours}:${minutes}`;
  };

  return (
    <div className="rounds-history-timeline-container">
      <div className="rounds-history-timeline-card">
        <div className="d-flex justify-content-space-between shift-timing-container">
          <Typography.Text className="mr-5 mt-3 align-items-center d-flex">
            {`${shift?.shift.name || "-"}`}
            {shift.shift.frequency_type === DataRoundFrequncyEnum.DAILY && (
              <span className="timing-container-history">
                {`(Shift timings ${formatTime(
                  shift.shift.start_time,
                )} to ${formatTime(shift.shift.end_time)})`}
              </span>
            )}
            {shift.shift.frequency_type === DataRoundFrequncyEnum.WEEKLY && (
              <span className="timing-container-history">
                (Shift Days:{" "}
                {shift.shift.days_of_week
                  .map((val: any) => DATA_ROUND_DAYS_NAME_MAPPER[val])
                  .join(", ")}
                )
              </span>
            )}
          </Typography.Text>
          {shift.round_user && (
            <Space direction="horizontal">
              <Avatar
                size={24}
                src={shift?.round_user?.image || NO_IMAGE_AVATAR}
                className="cursor-pointer"
                onClick={() =>
                  ModalServiceInstance.open(AlectifyModal, {
                    name: "alectify-task-image-viewer",
                    title: "Preview",
                    footer: null,
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: "alectify-task-image-viewer",
                      });
                    },
                    children: (
                      <ViewImage
                        imageUrl={shift?.round_user?.image || NO_IMAGE_AVATAR}
                        isDownloadable={!isEmpty(shift?.round_user?.image)}
                      />
                    ),
                  })
                }
              />

              <span className="font-size-12">
                {`${shift.round_user.first_name} ${shift.round_user.last_name}`}
              </span>
            </Space>
          )}
        </div>
        <div className="bottom-container">
          <Row gutter={6}>
            <Col span={12}>
              <RoundsIssueStatus shift={shift || ""} />
              <Space>
                {shift.critical_issue && (
                  <div className="d-flex align-items-center">
                    <div className="red-dot" />
                    <span className="issues-text">
                      {" "}
                      {AlectifyText.CRITICAL_ISSUE}
                    </span>
                  </div>
                )}

                {shift.known_issue && (
                  <div className="d-flex align-items-center">
                    <div className="red-dot" />
                    <span className="issues-text">
                      {AlectifyText.KNOWN_ISSUE}
                    </span>
                  </div>
                )}
              </Space>
            </Col>

            <Col span={6}>
              {checkpoint.is_reading_required &&
                shift?.reading_key !== null &&
                shift?.reading_key !== undefined && (
                  <span className="comment-key-text">
                    {AlectifyText.READING}:
                    <span className="text-black ml-2">
                      {shift?.reading_key ?? "121"}
                    </span>
                  </span>
                )}
            </Col>
            <Col span={6} className="">
              {checkpoint.on_off_feature &&
                shift?.on_off_status !== null &&
                shift?.on_off_status !== undefined && (
                  <div className="d-flex justify-flex-end align-items-center">
                    <span className="mr-5 mb-2 comment-key-text">
                      {AlectifyText.DEVICE_STATUS}
                    </span>

                    <div
                      className={`device-status-container ${
                        shift.on_off_status === 0
                          ? "not-active-device-status"
                          : "active-device-status"
                      }`}
                    >
                      {shift.on_off_status === 0 ? "OFF" : "ON"}
                    </div>
                  </div>
                )}
            </Col>
          </Row>

          {/* Clickable Comments Link */}
          {!isEmpty(shift?.comments) && (
            <>
              <Typography.Link
                onClick={() => setShowComments(!showComments)}
                className="font-size-13"
              >
                <CaretRightOutlined
                  className={`transition-transform ${
                    showComments ? "rotate-90" : ""
                  }`}
                />
                {showComments
                  ? "Comments"
                  : `Comments (${shift?.comments.length ?? 0})`}
              </Typography.Link>
            </>
          )}
          {/* Comments Section with Smooth Ease-in Effect */}
          {!isEmpty(shift?.comments) && (
            <div
              className={`toggle-section ${showComments ? "open" : "closed"}`}
            >
              <ul className="toggle-list">
                {shift.comments.map(
                  (comment: ICommentsRoundsHistory, index: number) => (
                    <li key={index}>
                      <span className="comment-text-effect-username">{`${comment.user.first_name} ${comment.user.last_name}:`}</span>{" "}
                      <span className="font-size-12">{comment.text}</span>
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}

          {/* Clickable Documents Link */}
          {!isEmpty(shift?.documents) && (
            <>
              <Typography.Link
                onClick={() => setShowDocuments(!showDocuments)}
                className="font-size-13"
              >
                <CaretRightOutlined
                  className={`transition-transform ${
                    showDocuments ? "rotate-90" : ""
                  }`}
                />
                <span className="font-size-13">
                  {showDocuments
                    ? "Documents"
                    : `Documents (${shift?.documents.length ?? 0})`}
                </span>
              </Typography.Link>
            </>
          )}

          {/* Documents Section with Smooth Ease-in Effect */}
          {!isEmpty(shift?.documents) && (
            <div
              className={`toggle-section ${showDocuments ? "open" : "closed"}`}
            >
              <ul className="toggle-list">
                {shift.documents.map(
                  (doc: IDocsRoundsHistory, index: number) => (
                    <li key={index}>
                      <Typography.Link
                        href={doc.signed_url}
                        target="_blank"
                        className="comment-text-effect-comment"
                      >
                        <span className="text-underline">{`${doc.file_name} `}</span>
                        <span className="comment-text-effect-comment">
                          {`uploaded by (${doc.user.first_name || "-"} ${
                            doc.user.last_name
                          })`}
                        </span>
                        {``}
                      </Typography.Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoundsHistoryTimeline;
