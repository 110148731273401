import { message, Space, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import AssetsIcon from "components/icons/AssetsIcon";
import DetailIcon from "components/icons/DetailIcon";
import GroupCheckpointsTable from "components/shared/group-checkpoint-table/GroupCheckpointsTable";
import { useParams } from "react-router-dom";
import AlectifyText from "static/texts.json";
import DataRoundInsights from "./DataRoundInsights/DataRoundInsights";
import { useEffect, useState } from "react";
import { PROJECT_THEME_COLOR_SECONDARY } from "components/shared/phone-number/constants";
import DataRoundReports from "components/shared/data-rounds-reports";
import DeadlinesIcon from "components/icons/DeadlinesIcon";
import { CalendarIcon } from "components/icons";
import DataRoundsCalendarTab from "./DataRoundsCalendar/DataRoundsCalendarTab";
import { MESSAGES } from "constants/messages";
import { getRoundsReport } from "services/data-round/data-round.service";
import logger from "utils/logger";

interface TabItem {
  key: string;
  label: React.ReactNode;
  children: React.ReactNode;
  visible: boolean; // New property
}

const DataRoundProjectTabs: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState<string>("insights");

  const { projectId } = useParams();

  const [FormInstance] = useForm();

  const generateRoundsReport = async () => {
    const hideMessage = message.loading(
      MESSAGES.EMAIL.GENERATING_EMAIL_WAIT,
      0,
    ); // 0 means it won't auto-close
    try {
      const response = await getRoundsReport(projectId || "");
      if (response.success) {
        hideMessage();
        message.success(MESSAGES.EMAIL.EMAIL_SENT_ROUND_REPORT);
      }
    } catch (ex) {
      hideMessage();
      message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      logger.error(ex as Error);
    }
  };

  const items: TabItem[] = [
    {
      key: "insights",
      label: (
        <Space>
          <DetailIcon />
          <span>{AlectifyText.INSIGHTS}</span>
        </Space>
      ),
      children: <DataRoundInsights projectId={projectId || ""} />,
      visible: true,
    },
    {
      key: "checkpoints",
      label: (
        <Space>
          <AssetsIcon />
          <span>{AlectifyText.CHECKPOINT_LIST}</span>
        </Space>
      ),
      children: (
        <GroupCheckpointsTable
          projectId={projectId || ""}
          form={FormInstance}
          displayActionButton={false}
          onClose={() => {}}
        />
      ),
      visible: true,
    },
    {
      key: "reports",
      label: (
        <Space>
          <DeadlinesIcon />
          <span>{AlectifyText.REPORTS_READINGS}</span>
        </Space>
      ),
      children: (
        <DataRoundReports generateRoundsReport={generateRoundsReport} />
      ),
      visible: true,
    },

    {
      key: "calendar",
      label: (
        <Space>
          <CalendarIcon
            fill={
              activeTab === "calendar" ? PROJECT_THEME_COLOR_SECONDARY : null
            }
          />
          <span>{AlectifyText.CALENDAR}</span>
        </Space>
      ),
      children: <DataRoundsCalendarTab />,
      visible: true,
    },
  ];
  useEffect(() => {}, []);
  return (
    <div className="sub-project-tabs">
      <Tabs
        items={items.filter((item) => item.visible)}
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
      />
    </div>
  );
};

export default DataRoundProjectTabs;
