import { getRoundDetails } from "services/data-round/data-round.service";
import { IRoundsDetailTableProps } from "./RoundsDetailTable.interface";
import { useEffect, useState } from "react";
import { IRound } from "services/data-round/data-round.interface";
import useRoundDetails from "./effects/useRoundDetailTable";
import AlectifyTable from "../table";
import RoundsDocumentationTable from "./RoundsDocumentationTable";
import RoundsCommentsTable from "./RoundsCommentsTable";

const RoundsDetailTable: React.FC<IRoundsDetailTableProps> = (
  props: IRoundsDetailTableProps,
) => {
  const [round, setRound] = useState<IRound[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<{
    id: string;
    type: string;
  } | null>(null);

  const fetchRoundDetails = async () => {
    setLoader(true);
    try {
      const response = await getRoundDetails(props.projectId, props.roundId);
      setLoader(false);
      if (response.success) {
        setRound((response.data && response.data) || null);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    fetchRoundDetails();
  }, [props.projectId, props.roundId]);

  const rowClassName = () => " cursor-pointer";

  const columns = useRoundDetails(setExpandedRow, props.projectId);

  return (
    <AlectifyTable
      displayRowClass={rowClassName}
      expandable={{
        expandedRowKeys: expandedRow ? [expandedRow.id] : [],
        onExpand: (expanded: any, record: any) => {
          if (!expanded) {
            setExpandedRow(null);
          }
        },
        expandedRowRender: (record: any) =>
          expandedRow?.type === "documents" ? (
            <RoundsDocumentationTable
              drProjectId={props.projectId}
              checkPointRoundId={record.id}
            />
          ) : expandedRow?.type === "comments" ? (
            <RoundsCommentsTable
              drProjectId={props.projectId}
              checkPointRoundId={record.id}
            />
          ) : null,
        expandIconColumnIndex: -1,
      }}
      dataSource={round || []}
      columns={columns}
      onDataFetch={fetchRoundDetails}
      total={round?.length || 0}
      scroll={{ y: 410 }}
      loading={loader}
    />
  );
};

export default RoundsDetailTable;
