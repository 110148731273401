import { AxiosResponse } from "axios";
import { IParams } from "redux/interfaces";
import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import {
  GET_MASTER_WORK_ORDER_DOCUMENTS,
  GET_WORK_ORDERS,
  GET_WORK_ORDER_MEDIA,
  UPLOAD_WORK_ORDER_DOCUMENTS,
} from "./work-order.endponts";
import {
  IWorkOrderDocumentsResponse,
  IWorkOrderMediaResponse,
  IWorkOrdersResponse,
} from "./work-order.interface";
import { SERVICE_UNIQUE_ID } from "utils/constants";
/**
 * Fetches work orders for a given master project, sub project ID, pm type.
 *
 * @param masterProjectId - The ID of the master project to fetch pm internal
 * @param subProjectId - The ID of the sub project to fetch pm internal for.
 * @param pmType - The PM type.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project work orders data.
 * @throws If an error occurs during the request.
 */
export const fetchWorkOrders = async (
  masterProjectId: string,
  subProjectId: string,
  pmType: string,
  params?: IParams, // Make params optional
): Promise<IWorkOrdersResponse> => {
  try {
    const url = GET_WORK_ORDERS.replace("{masterProjectId}", masterProjectId)
      .replace("{subProjectId}", subProjectId)
      .replace("{pmType}", pmType);
    const response: AxiosResponse<IWorkOrdersResponse> = await pmHttp.get(
      url,
      params,
      SERVICE_UNIQUE_ID.UNIQUE_FETCH_WORK_ORDERS_DASHBOARD,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches all the media related to work order id.
 * @param id - The ID of a work order.
 * @returns Promise<any> - A promise that resolves to normalized work order documents.
 * The function retrieves documents from the provided pmId, processes the response to normalize it,
 * and returns the normalized data.
 */
export const fetchWorkOrderMedia = async (pmId: string, params?: IParams) => {
  try {
    const url = GET_WORK_ORDER_MEDIA.replace("{id}", pmId);
    const response: AxiosResponse<IWorkOrderMediaResponse> = await pmHttp.get(
      url,
      params,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Uploads files and images for a given work order id.
 * @param id - The ID of the Work order.
 * @param body - The body data containing the documents to be uploaded.
 * @returns Promise<IPmAttachmentsExpressResponse> - A promise that resolves to the response data after uploading the documents.
 */

export const uploadWorkOrderDocuments = async (
  pmId: string,
  body: FormData,
): Promise<IWorkOrderMediaResponse> => {
  try {
    const url = UPLOAD_WORK_ORDER_DOCUMENTS.replace("{id}", pmId);
    const response = await pmHttp.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches Master Work Order Documents.
 * @param id - The ID of the work order.
 * @param params - Optional parameters for the request.
 * @returns Promise<any> - A promise that resolves to the response data after fetching the documents.
 */

export const fetchMasterWorkOrderDocuments = async (
  id: string,
  params?: IParams,
): Promise<IWorkOrderDocumentsResponse> => {
  try {
    const url = GET_MASTER_WORK_ORDER_DOCUMENTS.replace("{id}", id);
    const response = await pmHttp.get(url, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};
