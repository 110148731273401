import { memo, useEffect, useState } from "react";
import AlectifyTable from "../table";
import { IDocumentsItemsProps } from "./DocumentsAttachment.interface";
import useDocumentsPmItemsColumns from "./effects/useDocumentsPmItemsColumns";
import { IPagination } from "../table/AlectifyTable.interface";
import { useDispatch, useSelector } from "react-redux";
// import { getTaskAttachments } from "redux/components/tasks/sources";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";
import { IMetaNest, IParams } from "redux/interfaces";
import AlectifyButton from "../button";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { debounce, isEmpty } from "lodash";
import { UploadChangeParam } from "antd/es/upload";
import { IRootState } from "redux/rootReducer";
import { fetchMasterWorkOrderDocuments } from "services/work-orders/work-order.service";
import { IPmAttachmentDocuments } from "redux/components/pm-internal";
import logger from "utils/logger";

const DocumentsAttachmentTable: React.FC<IDocumentsItemsProps> = (
  props: IDocumentsItemsProps,
) => {
  const dispatch = useDispatch();
  const [masterDocuments, setMasterDocuments] = useState<{
    data: IPmAttachmentDocuments[];
    meta: IMetaNest;
    loader: false;
  }>({
    data: [],
    meta: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 5,
    },
    loader: false,
  });
  const columnPm = useDocumentsPmItemsColumns({
    deleteDocument: props.deleteDocuments,
    isFuture: props.isFuture,
    isMaster: props.isMaster,
  });
  const { pmAttachments } = useSelector(
    (state: IRootState) => state.pmInternal,
  );

  const fethcMasterAttachments = async (params?: IPagination) => {
    setMasterDocuments((prev: any) => ({
      ...prev,
      loader: true,
    }));
    try {
      const response = await fetchMasterWorkOrderDocuments(
        props.id || "",
        params as IParams,
      );
      if (response.status) {
        setMasterDocuments((prev: any) => ({
          ...prev,
          data: response.data,
          loader: false,
          meta: response.meta,
        }));
      }
    } catch (ex) {
      setMasterDocuments((prev: any) => ({
        ...prev,
        loader: true,
      }));
      logger.log(ex as any);
    }
  };
  const fetchAttachments = async (params?: IPagination) => {
    if (!isEmpty(params) && !props.isMaster) {
      params.limit = params.per_page;
      dispatch(getPmInternalAttachments(props?.id, params as IParams));
    } else {
      fethcMasterAttachments(params);
    }
  };

  // const selectedFunction =
  //   documentType === "TASK" ? fetchTaskAttachments : fetchPmAttachments;

  const displayRowClass = (record: any) => {
    return !record.isActive ? "disable-row" : "";
  };

  const handleUploadChange = debounce((info: UploadChangeParam) => {
    props.uploadDocuments && props.uploadDocuments(info.fileList);
  }, 300); // Adjust debounce time as needed

  useEffect(() => {
    if (props.isMaster) {
      fethcMasterAttachments({
        page: 1,
        pageSize: 50,
      });
    }
  }, [props.id, props.isFuture]);

  return (
    <>
      <AlectifyTable
        showSearch
        customSearchKey="search"
        columns={columnPm}
        dataSource={!props.isMaster ? pmAttachments.data : masterDocuments.data}
        total={
          !props.isMaster
            ? pmAttachments.meta?.totalItems
            : masterDocuments?.meta.totalItems
        }
        onDataFetch={fetchAttachments}
        loading={!props.isMaster ? props?.loading : masterDocuments?.loader}
        scroll={{ y: "calc(100vh - 350px)" }}
        displayRowClass={displayRowClass}
        extraButton={
          !props.isMaster && props.uploadDocuments ? (
            <Upload
              onChange={handleUploadChange}
              beforeUpload={() => false}
              showUploadList={false}
              className="width-100"
              multiple
              fileList={[]}
            >
              <AlectifyButton
                text="Upload"
                icon={<CloudUploadOutlined />}
                type="primary"
              />
            </Upload>
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

DocumentsAttachmentTable.defaultProps = {
  isFuture: false,
};

export default memo(DocumentsAttachmentTable);
