import { useEffect, useState } from "react";
import {
  IPagination,
  ITableFilterType,
} from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { isEmpty } from "lodash";
import { fetchDocuments } from "services/documents/documents.service";
import DocumentsTable from "../add-document/documents-table";
import { ISelectableDocumentsTableProps } from "./SelectableDocumentsTable.interface";

const SelectableDocumentsTable: React.FC<ISelectableDocumentsTableProps> = (
  props: ISelectableDocumentsTableProps,
) => {
  const [document, setDocument] = useState({
    data: [],
    meta: null,
    fetching: false,
  });
  const [optionsPersisted, setPersistedOptions] = useState<IPagination | null>(
    null,
  );

  const getDocuments = async (options?: IPagination | null) => {
    try {
      setDocument({ ...document, fetching: true });
      const params = {
        master_project: props.masterProjectId,
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        is_active:
          options?.is_active === "all" ? undefined : options?.is_active ?? true,

        document_type: "ProjectDocuments",
        ...options,
      };

      if (!isEmpty(options)) setPersistedOptions(options);

      const response = await fetchDocuments(params);

      setDocument({
        ...document,
        fetching: false,
        data: response.data,
        meta: response.meta,
      });
    } catch (err) {
      console.log(err);
      setDocument({ ...document, fetching: false });
    }
  };

  useEffect(() => {
    getDocuments({ ...optionsPersisted, is_active: "true" } as any);
  }, [props.masterProjectId]);

  return (
    <DocumentsTable
      document={document}
      getDocuments={getDocuments}
      filterType={ITableFilterType.DROPDOWN}
      optionsPersisted={optionsPersisted}
      scroll={{ x: 1200, y: "calc(100vh - 600px)" }}
      onRowSelectionAction={props.onRowSelectionAction}
      selectedRowKeys={props.selectedRowKeys}
      selectable
      /* filters={
        {
          key: "is_active",
          defaultValue: "true",
          options: [
            { label: "All", value: "all" },
            { label: "Active", value: "true" },
            { label: "Inactive", value: "false" },
          ],
        } as any
      } */
    />
  );
};

export default SelectableDocumentsTable;
