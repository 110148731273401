import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Document, Page, View, Text, BlobProvider } from "@react-pdf/renderer";
import dayjs from "dayjs";

// Icons and components
// import TaskIconPDF from "components/icons/pdf-icons/TaskIconPdf";
// import PmExternalIconPDF from "components/icons/pdf-icons/PmExternalIconPdf";
// import PmInternalIconPdf from "components/icons/pdf-icons/PmInternalIconPdf";
// import EventDelayedIconPdf from "components/icons/pdf-icons/EventDelayedIconPdf";
// import EventPendingIconPdf from "components/icons/pdf-icons/EventPendingIconPdf";
import EventCompletedIconPdf from "components/icons/pdf-icons/EventCompletedIconPdf";
import EventCompletedPastDueDateIconPdf from "components/icons/pdf-icons/EventCompletedPastDueDateIconPdf";
// import EventSkippedIconPdf from "components/icons/pdf-icons/EventSkippedIconPdf";

// Helpers, styles, and enums
import { formatDate } from "utils/helpers";
import { styles } from "./CalendarPdfStyles";
import { IRootState } from "redux/rootReducer";
import { CalendarEventStatusEnum } from "enums";
import AlectifyButton from "components/shared/button";
import { ICalendarTemplateProps } from "./CalendarPdf.interface";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { get, isEmpty, truncate } from "lodash";
// import { useIdleCallback } from "components/custom-hooks/useIdleCallback";

// Event icons
/* const EVENT_ICONS_PDF = {
  [CalendarEventTypesEnum.TASKS]: <TaskIconPDF />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIconPDF />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIconPdf />,
}; */

// Status icons
const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <></>,
  [CalendarEventStatusEnum.PENDING]: <></>,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIconPdf />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIconPdf />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <></>,
};

// Border colors based on status
/* const EVENT_BORDER_COLOR: any = {
  [CalendarEventStatusEnum.DELAYED]: "#d92323",
  [CalendarEventStatusEnum.PENDING]: "#3e74dd",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.SKIPPED]: "#d7d7d7",
}; */

const MonthlyCalendarTemplate: React.FC<ICalendarTemplateProps> = (props) => {
  const { data } = props.calendarEvents;
  const { user } = useSelector((state: IRootState) => state.auth);
  const weeksInMonth: any = [];
  const startDate = moment(props.startDate);
  const endDate = moment(props.endDate);

  // Generate weeks in the month
  let currentDate = startDate.clone();
  while (currentDate.isBefore(endDate)) {
    weeksInMonth.push(currentDate.clone());
    currentDate.add(1, "week");
  }

  // Handle leap year
  if (
    startDate.isLeapYear() &&
    startDate.isSameOrBefore(moment().endOf("month").date(29))
  ) {
    weeksInMonth.push(moment(startDate).endOf("month").date(29).clone());
  }

  // const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<React.ReactElement | null>(null);

  const getAssetName = (event: ICalendarEvent) => {
    return (Array.isArray(event.asset) && event.asset.length > 1) ||
      (Array.isArray(event.asset_package) && event.asset_package.length > 1) ||
      (Array.isArray(event.asset) &&
        event.asset.length === 1 &&
        Array.isArray(event.asset_package) &&
        event.asset_package.length === 1)
      ? "Multi Assets"
      : Array.isArray(event.asset) && event.asset.length === 1
      ? get(event.asset, "[0].asset.name")
      : Array.isArray(event.asset_package) && event.asset_package.length === 1
      ? get(event.asset_package, "[0].area.name")
      : "No Asset";
  };

  const additionalAssets = (event: ICalendarEvent) => {
    const additionalCount = event.asset.length + event.asset_package.length;
    return additionalCount > 1 ? `[${additionalCount}]` : "";
  };

  const createTemplate = useMemo(() => {
    return (
      <Document>
        <Page key={"introPage"} orientation="landscape">
          <View style={styles.titlePageContainer}>
            <Text style={styles.titlePageHeading}>Monthly Work Orders</Text>
            <Text style={styles.titlePageSubHeading}>
              {moment(endDate).format("MMMM YYYY")}
            </Text>
            <Text style={styles.titlePageSubHeading}>
              Generated by: {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text style={styles.titlePageSubHeading}>
              {moment().format("MMMM DD, YYYY, h:mm A")}
            </Text>
          </View>
        </Page>

        {/* Render weeks in the month */}
        {weeksInMonth.map((weekStartDate: any, index: number) => (
          <Page key={index} orientation="landscape">
            <View style={styles.container}>
              <View style={styles.legendDateHeading}>
                <Text>
                  {weekStartDate.format("DD MMM")} -{" "}
                  {weekStartDate.clone().add(6, "days").format("DD MMM, YYYY")}
                </Text>
              </View>
              <View style={styles.row}>
                {Array(7)
                  .fill(0)
                  .map((_, dayIndex) => {
                    const date = weekStartDate.clone().add(dayIndex, "day");
                    const eventsForDay = data?.filter((event) => {
                      const eventStart = moment(event.start)
                        .local()
                        .startOf("day")
                        .format();
                      return eventStart === date.format();
                    });
                    return (
                      <View key={dayIndex} style={styles.column}>
                        <View style={styles.header}>
                          <Text>{formatDate(dayjs(date), "dddd, DD MMM")}</Text>
                        </View>
                        {eventsForDay?.map((event, eventIndex) => (
                          <View
                            key={eventIndex}
                            style={{
                              ...styles.event,
                              backgroundColor: event.master_project[0].color,
                            }}
                          >
                            {/*   <View
                              style={{
                                ...styles.eventIcon,
                                borderColor:
                                  EVENT_BORDER_COLOR[event.event_status],
                              }}
                            >
                              {
                                EVENT_ICONS_PDF[
                                  event.type as CalendarEventTypesEnum
                                ]
                              }
                              <View style={styles.eventStatusIcon}>
                                {
                                  EVENT_STATUS_ICONS[
                                    event.event_status as CalendarEventStatusEnum
                                  ]
                                }
                              </View>
                            </View> */}
                            <View style={styles.eventStatusIcon}>
                              {
                                EVENT_STATUS_ICONS[
                                  event.event_status as CalendarEventStatusEnum
                                ]
                              }
                            </View>
                            <View style={styles.eventContent}>
                              <Text>
                                {truncate(event.title, {
                                  length: 35,
                                  omission: "...",
                                })}
                              </Text>
                              {/* <Text>
                                {event.asset?.[0]?.name ||
                                  event.asset_package?.[0]?.name ||
                                  "NA"}
                              </Text> */}
                              {/* <View> */}
                              <Text>
                                {truncate(getAssetName(event), {
                                  length: 15,
                                  omission: "...",
                                })}
                                {` ${
                                  additionalAssets(event) &&
                                  additionalAssets(event)
                                }`}
                              </Text>

                              {/* </View> */}
                            </View>
                          </View>
                        ))}
                      </View>
                    );
                  })}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    );
  }, [data, user, weeksInMonth]);

  // useIdleCallback(() => {
  //   setLoading(true);
  //   setTemplate(createTemplate);
  //   setLoading(false);
  // });

  useEffect(() => {
    setTemplate(createTemplate);
  }, [data]);

  if (!template) {
    return (
      <AlectifyButton
        text={<span>{"Loading PDF..."}</span>}
        className="light-blue-button"
        type="primary"
      />
    );
  }

  return (
    <BlobProvider document={template}>
      {({ blob, url, loading: pdfLoading }) => {
        const isLoading = pdfLoading;

        return (
          <AlectifyButton
            text={<span>Download Pdf</span>}
            disabled={isEmpty(data)}
            loading={props.loader}
            className="light-blue-button"
            type="primary"
            onClick={() => {
              if (!isLoading && url) {
                const link = document.createElement("a");
                link.href = url;
                link.download = `calendar-${moment().format("YYYY-MM-DD")}.pdf`;
                link.click();
              }
            }}
          />
        );
      }}
    </BlobProvider>
  );
};

export default MonthlyCalendarTemplate;
