import { ProcedureJobTypeEnum } from "services/procedures/procedures.interface";

export const JobTypeOptions = [
  {
    label: ProcedureJobTypeEnum.JOB_PLAN,
    value: ProcedureJobTypeEnum.JOB_PLAN,
  },
  {
    label: ProcedureJobTypeEnum.SOP,
    value: ProcedureJobTypeEnum.SOP,
  },
  {
    label: ProcedureJobTypeEnum.MOP,
    value: ProcedureJobTypeEnum.MOP,
  },
];

export const CREATE_PROCEDURE_FORM_STEPS = [
  { name: "Basic Information", key: 1 },
  { name: "Checklist Steps", key: 2 },
];
