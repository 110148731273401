const {
  REACT_APP_ENV,
  REACT_APP_BASE_URL,
  REACT_APP_GOOGLE_MAP_KEY,
  REACT_APP_PM_SERVICE_BASE_URL,
  REACT_APP_LLM_BASE_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_FIREBASE_VAPIDKEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  REACT_APP_GEN_AI_BASE_URL,
  REACT_APP_CITATION_THRESHOLD,
} = process.env;

interface Config {
  REACT_APP_ENV: AppEnvEnum | string | undefined;
  REACT_APP_BASE_URL: string | undefined;
  REACT_APP_GOOGLE_MAP_KEY: string | undefined;
  REACT_APP_PM_SERVICE_BASE_URL: string | undefined;
  REACT_APP_LLM_BASE_URL: string | undefined;
  REACT_APP_SENTRY_DSN: string | undefined;
  REACT_APP_FIREBASE_VAPIDKEY: string | undefined;
  REACT_APP_FIREBASE_API_KEY: string | undefined;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string | undefined;
  REACT_APP_FIREBASE_PROJECT_ID: string | undefined;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string | undefined;
  REACT_APP_FIREBASE_APP_ID: string | undefined;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string | undefined;
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID: string | undefined;
  REACT_APP_GEN_AI_BASE_URL: string | undefined;
  REACT_APP_CITATION_THRESHOLD: number | any;
}

const config: Config = {
  REACT_APP_ENV,
  REACT_APP_BASE_URL,
  REACT_APP_GOOGLE_MAP_KEY,
  REACT_APP_PM_SERVICE_BASE_URL,
  REACT_APP_LLM_BASE_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_FIREBASE_VAPIDKEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  REACT_APP_GEN_AI_BASE_URL,
  REACT_APP_CITATION_THRESHOLD,
};

export enum AppEnvEnum {
  STAGE = "stage",
  PRODUCTION = "production",
}

export { config };
