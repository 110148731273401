import AlectifyText from "static/texts.json";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import {
  IDrCheckpoint,
  IRound,
} from "services/data-round/data-round.interface";
import CheckIcon from "components/icons/CheckIcon";
import { Tooltip } from "antd";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import TextToLink from "components/shared/text-to-link";

const useRoundDetails = (
  setExpandedRow: React.Dispatch<
    React.SetStateAction<{ id: string; type: string } | null>
  >,
  projectId: string,
) => {
  const onCell = (record: any) => ({
    style: {
      cursor: "pointer",
    },
    onClick: () => {
      if (projectId && record.checkpoint.id) {
        const url = `/rounds-history/${projectId}/${record.checkpoint.id}`;
        window.open(url, "_blank");

        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ROUND_DETAIL_MODAL,
        });
      }
    },
  });

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CHECKPOINT,
      key: "checkpoint",
      dataIndex: "checkpoint",
      ellipsis: true,
      width: 200,
      render: (value: IDrCheckpoint, record: IRound) => {
        return (
          <Tooltip title={record.checkpoint?.name || "-"}>
            <TextToLink text={record.checkpoint?.name || "-"} />
          </Tooltip>
        );
      },
      onCell,
    },
    {
      title: AlectifyText.OPERATING_RANGE,
      key: "checkpoint",
      dataIndex: "checkpoint",
      ellipsis: true,
      width: 200,
      render: (value: IDrCheckpoint) => (
        <Tooltip title={value?.description || ""}>
          {value?.description || ""}
        </Tooltip>
      ),
      onCell,
    },
    {
      title: AlectifyText.ISSUES_REPORTED,
      key: "status",
      dataIndex: "status",
      ellipsis: true,
      render: (value: boolean) =>
        !Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
    },
    {
      title: AlectifyText.CRITICAL_ISSUE,
      key: "critical_issue",
      dataIndex: "critical_issue",
      ellipsis: true,
      render: (value: boolean) =>
        Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
      onCell,
    },

    {
      title: AlectifyText.KNOWN_ISSUE,
      key: "known_issue",
      dataIndex: "known_issue",
      ellipsis: true,
      // width: 70,
      render: (value: boolean) =>
        Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
      onCell,
    },

    {
      title: AlectifyText.READINGS,
      key: "reading_key",
      dataIndex: "reading_key",
      ellipsis: true,
      render: (value: any) => (
        <Tooltip title={value || ""}>{value || ""}</Tooltip>
      ),
      onCell,
    },

    {
      title: AlectifyText.RECENT_COMMENT,
      key: "latest_comment",
      dataIndex: "latest_comment",
      ellipsis: true,
      width: 250,
      render: (value: string) => (
        <Tooltip title={value || ""}>
          <div className="text-ellipsis">{value || ""}</div>
        </Tooltip>
      ),
      onCell,
    },

    /*  {
      title: "Active",
      key: "checkpoint_active_status",
      dataIndex: "checkpoint_active_status",
      ellipsis: true,
      render: (value: boolean) => (Boolean(value) ? "Yes" : "No"),
    }, */

    {
      title: AlectifyText.NUMBER_OF_DOCUMENTS,
      key: "docs_count",
      dataIndex: "docs_count",
      ellipsis: true,
      render: (value: number, record: IRound) => (
        <span
          style={{
            cursor: value > 0 ? "pointer" : "default",
            color: value > 0 ? "blue" : "gray",
            textDecoration: value > 0 ? "underline" : "none",
          }}
          onClick={() => {
            if (value > 0) {
              setExpandedRow((prev) =>
                prev?.id === record.id && prev?.type === "documents"
                  ? null // Collapse if already expanded
                  : { id: record.id, type: "documents" },
              );
            }
          }}
        >
          {value || 0}
        </span>
      ),
    },

    {
      title: AlectifyText.NUMBER_OF_COMMENTS,
      key: "comments_count",
      dataIndex: "comments_count",
      ellipsis: true,
      render: (value: number, record: IRound) => (
        <span
          style={{
            cursor: value > 0 ? "pointer" : "default",
            color: value > 0 ? "blue" : "gray",
            textDecoration: value > 0 ? "underline" : "none",
          }}
          onClick={() => {
            if (value > 0) {
              setExpandedRow((prev) =>
                prev?.id === record.id && prev?.type === "comments"
                  ? null // Collapse if already expanded
                  : { id: record.id, type: "comments" },
              );
            }
          }}
        >
          {value || 0}
        </span>
      ),
    },
  ];
  return columns;
};

export default useRoundDetails;
