import React from "react";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import { IGroupCheckpoint } from "services/data-round/data-round.interface";
import DRGroupTag from "components/shared/tags/DrGroupTag";
import DrCheckpointTag from "components/shared/tags/DrCheckpointTag";
import { IGroupCheckpointsTableProps } from "../GroupCheckpointsTable.interface";
import { Popconfirm, Space, Tooltip } from "antd";
import DeleteIcon from "components/icons/DeleteIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import AddCheckpointManualForm from "components/shared/manage-groups/AddCheckpointManualForm";
import { EditIconFilledIcon } from "components/icons";
import { GroupCheckpointTypes } from "services/data-round/data-round.constants";
import TextToLink from "components/shared/text-to-link";

const useGroupCheckpointsColumn = (props: IGroupCheckpointsTableProps) => {
  const redirectToCheckpointHistory = (record: any) => {
    if (record.dr_project_id && record.checkpoint_id) {
      const url = `/rounds-history/${record.dr_project_id}/${record.checkpoint_id}`;
      window.open(url, "_blank");
    }
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: "Item No#",
      dataIndex: "item_no",
      key: "item_no",
      width: 50,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: IGroupCheckpoint) => (
        <span className={record.type !== "Area" ? "ml-10" : ""}>
          {value || "-"}
        </span>
      ),
    },
    {
      title: AlectifyText.TYPE,
      dataIndex: "type",
      visible: true,
      width: 100,

      // filterMultiple: false,
      // filters: [
      //   {
      //     key: "1",
      //     text: AlectifyText.PARENT_ASSET_NAME,
      //     value: "PackageRoom",
      //   },
      //   {
      //     key: "2",
      //     text: AlectifyText.SUB_ASSET_NAME,
      //     value: "Tag",
      //   },
      // ],
      render: (type) => (
        <>
          {type === "Area" ? (
            <DRGroupTag />
          ) : (
            <span className={type !== "Area" ? "ml-10" : ""}>
              <DrCheckpointTag />
            </span>
          )}
        </>
      ),
    },
    {
      title: AlectifyText.GROUP,
      dataIndex: "area_name",
      key: "area_name",
      width: 180,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      searchable: true,
      render: (_, record: IGroupCheckpoint) => {
        return (
          <Tooltip title={record.area_name || ""}>
            <span className={record.type !== "Area" ? "ml-10" : ""}>
              {record.area_name || "-"}
            </span>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Checkpoint",
      dataIndex: "checkpoint_name",
      key: "checkpoint_name",
      searchable: true,
      width: 180,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: IGroupCheckpoint) => {
        return (
          <Tooltip title={record.checkpoint_name || ""}>
            <span className={record.type !== "Area" ? "ml-10" : ""}>
              {record.checkpoint_name || "-"}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Operating Range",
      dataIndex: "description",
      key: "description",
      searchable: true,
      width: 150,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: IGroupCheckpoint) => {
        return record.type === "Checkpoint" ? (
          <Tooltip title={record.description || ""}>
            <span className="ml-10">{record.description || "-"}</span>
          </Tooltip>
        ) : (
          <>{"-"}</>
        );
      },
    },
    {
      title: "Reading Required",
      dataIndex: "is_reading_required",
      key: "is_reading_required",
      searchable: true,
      width: 100,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (value: boolean, record: IGroupCheckpoint) =>
        (record.type === GroupCheckpointTypes.CHECKPOINT && (
          <span className="ml-10">{Boolean(value) ? "Yes" : "No"}</span>
        )) ||
        "-",
    },
    {
      title: "Device Status Required",
      dataIndex: "on_off_feature",
      key: "on_off_feature",
      searchable: true,
      width: 100,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (value: boolean, record: IGroupCheckpoint) =>
        (record.type === GroupCheckpointTypes.CHECKPOINT && (
          <span className="ml-10">{Boolean(value) ? "Yes" : "No"}</span>
        )) ||
        "-",
    },
    {
      title: AlectifyText.ACTIONS,
      visible: props.displayActionButton,
      width: 100,
      render: (value: string, asset: IGroupCheckpoint) =>
        props.displayActionButton ? (
          <>
            <Space>
              <Tooltip title="Edit">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 500,
                      title:
                        asset.type === "Area"
                          ? asset.area_name
                          : asset.checkpoint_name || "-",
                      name: DRAWER_CONSTANTS.CREATE_NEW_GROUP_CHECKPOINT,
                      showFooter: true,
                      onClose: props?.onClose,
                      closable: true,
                      closeIcon: <CloseOutlined />,
                      submitText: "Update",
                      onSubmit: props?.form?.submit,
                      children: (
                        <AddCheckpointManualForm
                          form={props.form}
                          isEditing
                          projectId={props.projectId}
                          onClose={props.onClose}
                          type={asset.type}
                          data={asset}
                        />
                      ),
                    });
                  }}
                >
                  <EditIconFilledIcon />
                </div>
              </Tooltip>
              <div>
                <Tooltip title="Delete Package">
                  <Popconfirm
                    onConfirm={() => props?.onDelete && props.onDelete(asset)}
                    title="Are your want to delete this package?"
                  >
                    <div className="cursor-pointer">
                      <DeleteIcon />
                    </div>
                  </Popconfirm>
                </Tooltip>
              </div>
            </Space>
          </>
        ) : (
          <></>
        ),
    },
    {
      title: AlectifyText.ACTIONS,
      dataIndex: "actions",
      key: "actions",
      width: 80,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      render: (value: boolean, record: IGroupCheckpoint) =>
        record.type === "Checkpoint" ? (
          <TextToLink
            className="text-to-link-options"
            text={AlectifyText.VIEW_DETAIL}
            onClick={() => redirectToCheckpointHistory(record)}
          />
        ) : null,
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default useGroupCheckpointsColumn;
