import { Col, Row } from "antd";
import ProcedureImport from "components/shared/procedure-import";
import { IWorkOrderProcedureSelectionProps } from "../WorkOrderCreateEdit.interface";

const WorkOrderProcedureSelection: React.FC<
  IWorkOrderProcedureSelectionProps
> = (props: IWorkOrderProcedureSelectionProps) => {
  return (
    <>
      <Row justify={"space-between"} align={"middle"} className="mb-5">
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            5. Select Checklists
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">5/5</span>
        </Col>
      </Row>
      <ProcedureImport
        onSelectProcedure={props.onSelectProcedure}
        procedure={props.procedure || null}
        projectId={props.projectId || ""}
      />
    </>
  );
};

export default WorkOrderProcedureSelection;
