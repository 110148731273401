import { Col, Typography, Row, DatePicker, message } from "antd";
import { IWorkOrderReportsProps } from "./WorkOrderReports.interface";
import { ICalendarEventState } from "pages/calendar/Calendar.interface";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { IPmCalendarEventResponse } from "services/calendar/calendar.interface";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import MonthlyCalendarTemplate from "pages/calendar/calendar-pdf/MonthlyCalendarTemplate";
import WeeklyCalendarTemplate from "pages/calendar/calendar-pdf/WeeklyCalendarTemplate";
import { IMasterProject } from "redux/components/master-project";
import { getMasterPMCSV } from "services/pm-external/pm-external.service";
import { IParams } from "redux/interfaces";
import { downloadCSV } from "utils/helpers";
import SelectMasterProject from "components/shared/master-project-select";
import AlectifyButton from "components/shared/button";
import { isEmpty } from "lodash";
import { MESSAGES } from "constants/messages";

const WorkOrderReports: React.FC<IWorkOrderReportsProps> = (
  props: IWorkOrderReportsProps,
) => {
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs | null>(null);
  const [selectedMasterProject, setSelectedMasterProject] =
    useState<IMasterProject | null>(null);
  const [calendarEventMonthly, setCalendarEventMonthly] =
    useState<ICalendarEventState>({ data: [], fetching: false });
  const [calendarEventWeekly, setCalendarEventWeekly] =
    useState<ICalendarEventState>({ data: [], fetching: false });
  const [loader, setLoader] = useState<boolean>(false);

  const { common } = useSelector((state: IRootState) => state);

  const getCalendarEvent = async (selectedMonthStartDate: Dayjs | null) => {
    if (!selectedMonthStartDate) return;
    setSelectedMonth(selectedMonthStartDate);
    setCalendarEventMonthly({ fetching: true });
    try {
      const startDate = selectedMonthStartDate
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = selectedMonthStartDate
        .clone()
        .endOf("month")
        // .endOf("week")
        .format("YYYY-MM-DD");

      const projectId = common.activeMasterProject?.id ?? "all";
      const subProjectId = "all";
      const options =
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
          ? {}
          : { global: "true" };

      const pmEvents: IPmCalendarEventResponse = await fetchPmCalendarEvents(
        projectId,
        subProjectId,
        startDate,
        endDate,
        options,
      );
      setCalendarEventMonthly({ fetching: false, data: pmEvents.data });
    } catch (error) {
      console.error(error);
      setCalendarEventMonthly({ fetching: false });
    }
  };

  const getWeeklyCalendarEvent = async (
    selectedWeekStartDate: Dayjs | null,
  ) => {
    if (!selectedWeekStartDate) return;
    setSelectedWeek(selectedWeekStartDate);
    setCalendarEventWeekly({ fetching: true });
    try {
      const startDate = selectedWeekStartDate
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");
      const endDate = selectedWeekStartDate
        .clone()
        .endOf("week")
        .format("YYYY-MM-DD");

      const projectId = common.activeMasterProject?.id ?? "all";
      const subProjectId = "all";
      const options =
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
          ? {}
          : { global: "true" };

      const pmEvents: IPmCalendarEventResponse = await fetchPmCalendarEvents(
        projectId,
        subProjectId,
        startDate,
        endDate,
        options,
      );
      setCalendarEventWeekly({ fetching: false, data: pmEvents.data });
    } catch (error) {
      console.error(error);
      setCalendarEventWeekly({ fetching: false });
    }
  };
  const fetchAndDownloadMasterPm = async () => {
    setLoader(true);
    message.loading(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_INPROGRESS);
    try {
      const params: IParams = {
        // page: 1,
        // limit: 1000,
        projectId: selectedMasterProject?.id,
        isRecurring: true,
        // subProjectId: selectedSubProject?.id,
      };
      const response = await getMasterPMCSV(params);
      setLoader(false);
      if (response) {
        downloadCSV(response);
        message.success(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_SUCCESS);
      }
    } catch (ex) {
      setLoader(false);
      message.error("Couldn't download the file.");
      console.log(ex);
    }
  };
  return (
    <>
      <Col span={24}>
        <Typography.Title level={4} className="mt-0">
          Work Orders
        </Typography.Title>
      </Col>
      <div className="alectify-work-order-reports-container">
        <Col span={24}>
          <Row justify={"start"} align={"middle"} className="mb-10" gutter={16}>
            <Col span={4}>
              <Typography.Text>Calendar: Monthly schedule</Typography.Text>
            </Col>
            <Col span={4}>
              <DatePicker
                picker="month"
                onChange={(date) => getCalendarEvent(date)}
                size="large"
                className="alectify-work-order-reports-date-picker"
              />
            </Col>
            <Col span={4}>
              <MonthlyCalendarTemplate
                startDate={
                  selectedMonth
                    ?.clone()
                    .startOf("month")
                    .format("YYYY-MM-DD") || ""
                }
                endDate={
                  selectedMonth?.clone().endOf("month").format("YYYY-MM-DD") ||
                  ""
                }
                calendarEvents={calendarEventMonthly}
                loader={calendarEventMonthly.fetching || false}
              />
            </Col>
          </Row>
          <Row justify={"start"} align={"middle"} className="mb-10" gutter={16}>
            <Col span={4}>
              <Typography.Text>Calendar: Weekly schedule</Typography.Text>
            </Col>
            <Col span={4}>
              <DatePicker
                picker="week"
                onChange={(date) => getWeeklyCalendarEvent(date)}
                size="large"
                className="alectify-work-order-reports-date-picker"
              />
            </Col>
            <Col span={4}>
              <WeeklyCalendarTemplate
                startDate={
                  selectedWeek?.clone().startOf("week").format("YYYY-MM-DD") ||
                  ""
                }
                endDate={
                  selectedWeek?.clone().endOf("week").format("YYYY-MM-DD") || ""
                }
                calendarEvents={calendarEventWeekly}
                loader={calendarEventWeekly.fetching || false}
              />
            </Col>
          </Row>
          <Row
            justify={"start"}
            align={"middle"}
            className="mb-10 alectify-wo-reports-pm-download"
            gutter={16}
          >
            <Col span={4}>
              <Typography.Text>
                Recurring Preventive Maintenance Work Orders
              </Typography.Text>
            </Col>
            <Col span={4}>
              <SelectMasterProject
                key={common?.activeMasterProject?.id}
                onChange={(value: any) => {}}
                onSelect={(value: string, record: any) =>
                  setSelectedMasterProject(record)
                }
                defaultValue={common?.activeMasterProject?.id}
                showLabel={false}
              />
            </Col>
            <Col span={4}>
              <AlectifyButton
                text={"Download CSV"}
                type="primary"
                className="alectify-primary-btn"
                loading={loader}
                disabled={isEmpty(selectedMasterProject)}
                onClick={fetchAndDownloadMasterPm}
              />
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default WorkOrderReports;
