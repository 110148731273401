import { PauseCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { CrossRedIcon, WaitingIcon } from "components/icons";
import CheckIcon from "components/icons/CheckIcon";
import { ReactNode } from "react";
import { RoundStatusEnum } from "services/data-round/data-round.interface";
import "./AlectifyTags.scss";
import { IUser } from "redux/components/Auth";

interface DataRoundStatusTagProps {
  status: RoundStatusEnum;
  end_time?: string | null;
  user?: IUser | null;
}

const DataRoundStatusTag: React.FC<DataRoundStatusTagProps> = ({
  status,
  end_time,
  user,
}) => {
  const statusTagMap: {
    [key in RoundStatusEnum]: { label: string; color: string; icon: ReactNode };
  } = {
    [RoundStatusEnum.NOT_STARTED]: {
      label: "Not Started",
      color: "#999999",
      icon: <CrossRedIcon fill="#fa7a7d" />,
    },
    [RoundStatusEnum.ON_GOING]: {
      label: "In Progress",
      color: "#25a0fd",
      icon: <WaitingIcon fill={"#25a0fd"} height="13" />,
    },
    [RoundStatusEnum.ENDED]: {
      label: "Completed",
      color: "#42be19",
      icon: (
        <CheckIcon width={20} fill={"#42be19"} height={15} className="mt-5" />
      ),
    },
    [RoundStatusEnum.PAUSED]: {
      label: "On Hold",
      color: "#ffb310",
      icon: <PauseCircleTwoTone twoToneColor="#ffb310" />,
    },
  };

  let label = statusTagMap[status].label;
  let color = statusTagMap[status].color;
  let icon = statusTagMap[status].icon;

  if (status === RoundStatusEnum.ENDED) {
    if (end_time) {
      icon = (
        <CheckIcon width={20} fill={"#42be19"} height={15} className="mt-5" />
      );
    } else {
      icon = <CrossRedIcon fill="#fa7a7d" />;
      label = "Did not Finish";
      color = "#fa7a7d";
    }
  }

  if (status === RoundStatusEnum.ENDED && !user) {
    label = "Did not Finish";
    color = "#fa7a7d";
    icon = <CrossRedIcon fill="#fa7a7d" />;
  }

  return (
    <Tooltip title={label}>
      <span className="d-flex align-items-center" style={{ color }}>
        {icon}
        <span style={{ marginLeft: 2 }}>{label}</span>
      </span>
    </Tooltip>
  );
};

export default DataRoundStatusTag;
