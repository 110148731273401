import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  View,
  Text,
  BlobProvider,
  StyleSheet,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import moment from "moment";

import AlectifyButton from "components/shared/button";
import { ITask } from "redux/components/tasks";
import { displayDateTime } from "utils/helpers";
import { isEmpty } from "lodash";
import { PM_STATUS } from "enums";
import { renderWorkOrderRepeats } from "../pm/WorkOrder.helper";
import AlectifyText from "static/texts.json";
import AlectifyBulletIconPdf from "components/icons/pdf-icons/BulletIconPdf";
import htmlToReactPdf from "./HTMLReactParserOptions";
import { fetchProcedure } from "services/procedures/procedures.service";
import { IProcedure } from "services/procedures/procedures.interface";
import { createRoot } from "react-dom/client";

export const PM_STATUS_TEXT = {
  [PM_STATUS.COMPLETED]: "Closed",
  [PM_STATUS.PENDING]: "Scheduled",
  [PM_STATUS.SKIPPED]: "Skipped",
  [PM_STATUS.WAITING_FOR_REVIEW]: "Waiting for Review",
  [PM_STATUS.INPROGRESS]: "In Progress",
  [PM_STATUS.DENIED]: "Denied",
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  titlePageContainer: {
    textAlign: "left",
    marginBottom: 20,
  },
  titlePageHeading: {
    fontSize: 24,
    marginBottom: 10,
  },
  titlePageSubHeading: {
    fontSize: 10,
    marginBottom: 5,
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  stepText: {
    fontSize: 11,
    textAlign: "justify",
    paddingRight: 30,
    whiteSpace: "pre-wrap",
  },
  procedure: {
    marginTop: 20,
  },
  subHeader: {
    fontSize: 16,
    marginBottom: 15,
    paddingTop: 10,
    borderTop: "1px solid #ddd",
  },
  step: {
    marginBottom: 20,
  },
  commentBox: {
    border: "1px solid #ddd",
    borderRadius: 5,
    padding: 25,
    marginTop: 5,
  },
  nameAndCheckboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-start",
    marginBottom: 5,
  },
  space: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  statusText: {
    fontSize: 10,
    color: "red",
    fontWeight: "bold",
    marginBottom: 5,
  },
  recurringTextLable: {
    fontSize: 10,
    color: "green",
    fontStyle: "italic",
  },
  recurringText: {
    fontSize: 10,
    marginBottom: 5,
  },
});

const WorkOrderPdfTemplate: React.FC<{ workOrder: ITask }> = ({
  workOrder,
}) => {
  const [procedure, setProcedure] = useState<IProcedure | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloadTriggered, setDownloadTriggered] = useState(false);

  const { user } = useSelector((state: IRootState) => state.auth);

  const renderAssets = () => {
    if (workOrder.isGeneric) {
      return null;
    }

    const assetCount = (workOrder.assets || []).length;
    const areaCount = (workOrder.areas || []).length;
    const totalCount = assetCount + areaCount;

    if (totalCount === 1) {
      if (assetCount === 1) {
        return (
          <Text style={styles.recurringText}>
            Sub Asset: {(workOrder.assets[0] as any)?.asset?.name || "-"}
          </Text>
        );
      } else if (areaCount === 1) {
        return (
          <Text style={styles.recurringText}>
            Parent Asset: {(workOrder.areas[0] as any)?.area?.name || "-"}
          </Text>
        );
      }
    } else if (totalCount > 1) {
      return [...workOrder.assets, ...workOrder.areas].map((asset: any) => {
        return (
          <View style={{ ...styles.space, marginLeft: 10 }} key={asset?.id}>
            <AlectifyBulletIconPdf />
            <Text
              style={{ ...styles.recurringText, marginTop: -2, marginLeft: 5 }}
            >
              {asset?.asset?.name || asset?.area?.name || "-"}
            </Text>
          </View>
        );
      });
    }

    return <Text>-</Text>;
  };

  const getProcedureDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchProcedure(workOrder?.procedure?.id || "");
      setProcedure(response.data);
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    setDownloadTriggered(true); // Trigger the download process
    await getProcedureDetails(); // Fetch procedure details
  };

  useEffect(() => {
    if (downloadTriggered && procedure) {
      // Generate and download the PDF after the procedure is fetched
      const doc = (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.titlePageContainer}>
              <Text style={styles.titlePageSubHeading}>Generated by</Text>
              <Text style={styles.titlePageSubHeading}>
                {`${user?.first_name} ${user?.last_name}`}
              </Text>
              <Text style={styles.titlePageSubHeading}>
                {moment().format("MMMM DD, YYYY, h:mm A")}
              </Text>
            </View>

            {!isEmpty(workOrder) && (
              <>
                <View style={styles.section}>
                  <Text style={styles.header}>
                    {workOrder?.workTitle || "-"}
                  </Text>
                  <View style={styles.space}>
                    <Text style={{ fontSize: 9, fontWeight: 600 }}>
                      Status:
                    </Text>
                    {workOrder?.status && "Status: "}{" "}
                    <Text style={styles.recurringText}>
                      {workOrder?.status && PM_STATUS_TEXT[workOrder?.status]},
                    </Text>
                    <Text style={styles.statusText}>
                      {" "}
                      Due Date:{" "}
                      {workOrder?.dueDate &&
                        displayDateTime(workOrder?.dueDate, false)}
                    </Text>
                  </View>

                  <Text style={styles.recurringText}>
                    Work Order Type:{" "}
                    {workOrder?.pmType === "TASK"
                      ? "Task"
                      : "Preventive Maintenance"}
                  </Text>
                  {renderWorkOrderRepeats(workOrder) != null ? (
                    <View style={styles.space}>
                      <Text style={styles.recurringTextLable}>Recurring: </Text>{" "}
                      <Text style={styles.recurringText}>
                        {renderWorkOrderRepeats(workOrder)}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <Text style={styles.recurringText}>
                    Work Id: {workOrder.workId || "-"}
                  </Text>
                  <Text style={styles.recurringText}>
                    Assets:{" "}
                    {isEmpty(renderAssets()) ? AlectifyText.NO_ASSET : <></>}
                  </Text>
                  {renderAssets()}
                  <Text style={styles.text}>Description:</Text>
                  <Text
                    style={{
                      ...styles.text,
                      backgroundColor: "#eaf1fb",
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    {htmlToReactPdf(workOrder?.detail)}
                  </Text>
                </View>

                <View style={styles.procedure} wrap>
                  <Text style={styles.subHeader}>
                    Checklist Steps (
                    {workOrder?.procedure?.procedureStepTotalCount || 0})
                  </Text>
                  {procedure?.procedureSteps
                    ?.sort((a, b) => Number(a.order) - Number(b.order))
                    .map((step, index) => (
                      <View key={index} style={styles.step}>
                        <View style={styles.nameAndCheckboxContainer}>
                          <View
                            style={{
                              width: 15,
                              height: 15,
                              marginRight: 5,
                              border: "1px solid #000",
                            }}
                          ></View>
                          <Text style={styles.stepText}>
                            {index + 1}. {step.name}
                          </Text>
                        </View>
                        <View style={styles.commentBox}></View>
                      </View>
                    ))}
                </View>
              </>
            )}
          </Page>
        </Document>
      );

      // Use BlobProvider to generate the PDF and trigger the download
      const blobProvider = (
        <BlobProvider document={doc}>
          {({ url }) => {
            if (url) {
              const link = document.createElement("a");
              link.href = url;
              link.download = `work-order-${moment().format("YYYY-MM-DD")}.pdf`;
              link.click();
              setDownloadTriggered(false); // Reset the download trigger
            }
            return null;
          }}
        </BlobProvider>
      );

      // Render the BlobProvider to trigger the download
      // Note: This is a workaround to force the download
      const container = document.createElement("div");
      document.body.appendChild(container);
      const root = createRoot(container);
      root.render(blobProvider);
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(container);
      }, 100);
    }
  }, [procedure, downloadTriggered]);

  return (
    <AlectifyButton
      name="download-as-pdf"
      text={<span className="font-size-13">Download Pdf</span>}
      loading={loading}
      className="light-blue-button"
      type="primary"
      onClick={handleDownload}
    />
  );
};

export default WorkOrderPdfTemplate;
