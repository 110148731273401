import React from "react";
import { isEmpty } from "lodash";
import { ITask } from "redux/components/tasks";
import {
  Avatar,
  Col,
  Dropdown,
  Popover,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import {
  displayDateTime,
  enumToTile,
  getFullUserName,
  isVideoFileType,
  truncateText,
} from "utils/helpers";
import { PM_STATUS, PME_ACTION_REQUIRED_ENUM } from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import {
  EllipsisOutlined,
  CloseOutlined,
  ExportOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { actionItems } from "../TaskTable.rules";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import ViewImage from "components/shared/view-image/ViewImage";

import { AssetPackageTag, AssetTag, GenericTag } from "components/shared/tags";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import "assets/scss/global.scss";
import { useNavigate } from "react-router-dom";
import { CalendarDateIcon, WarningIcon } from "components/icons";
import { IPmAssignees } from "redux/interfaces";
import { PM_TYPES } from "redux/components/pm-external";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import ProcedureDetail from "components/shared/procedure-detail";
import { undoTaskStatus } from "services/tasks/tasks.service";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import { ROUTES } from "routes/Routes.constants";
import { actions as NotificationActions } from "redux/components/notifications";
import PmDetail from "components/shared/pm-detail";
import TextToLink from "components/shared/text-to-link";
import AlectifyTable from "components/shared/table";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AlectifyButton from "components/shared/button";
import { statusMap } from "utils/constants";
import AlectifyCarousel from "components/carousel/AlectifyCarousel";

import "../TasksTable.scss";
import RecurringIcon from "components/icons/RecurringIcon";
import { renderWorkOrderRepeats } from "pages/my-work-orders/details/pm/WorkOrder.helper";
import { getTaskStatusIcon, taskStatusStepIcon } from "../TasksTable.helper";
import {
  ITaskTableColumnHookProps,
  PM_STATUS_COMPONENTS,
} from "./useTasksColumns";
import { getPMEActionRequired } from "pages/work-order/WorkOrderCreateEdit.constants";
import { AlectifyDetailCardImage } from "components/shared/detail-card";
import { setSimpleTableDetail } from "redux/components/common/sources";

const useSimpleColumns = (props: ITaskTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTaskType = props.type === PM_TYPES.TASK;
  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const goToPmDetail = (task: ITask) => {
    //to activate redux store values tracking for page and per_page [prototype]
    dispatch(
      setSimpleTableDetail({
        pageIsOnMaintenanceDetail: true,
      }),
    );
    const pmId = task.id;
    const masterProjectId = task?.project?.id;
    const subProjectId = task?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
    dispatch(NotificationActions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER],
    });
  };

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },
    onClick: () => {
      if (!props.isMasterPM) {
        goToPmDetail(record);
      } else {
        DrawerServiceInstance.open(AlectifyDrawer, {
          width: 480,
          title: truncateText(record.workTitle, 40),
          name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
          closable: true,
          className: "bg-grey",
          closeIcon: <CloseOutlined />,
          onClose: () =>
            DrawerServiceInstance.close(AlectifyDrawer, {
              name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
            }),
          children: (
            <PmDetail
              pmExternalRecord={record as any}
              record={record}
              pmId={record.id}
              pmType={props.type === "ALL" ? record.pmType : props.type}
              isMasterPM={props.isMasterPM}
              hideDetailBtn
            />
          ),
        });
      }
    },
  });

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.TITLE,
          dataIndex: "workTitle",
          width: 200,
          visible: true,
          ellipsis: true,
          searchable: true,
          onCell,
          render: (value: string, task: ITask) => {
            const openProcedureCheckListDrawer = () => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 680,
                title: "Checklist",
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () => {
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                  });
                  props.fetchTasks();
                },
                showFooter: true,
                destroyOnClose: true,
                readOnly: true,
                cancelText: AlectifyText.CLOSE,
                children: (
                  <ProcedureDetail
                    procedure={task.procedure as any}
                    taskId={task.id}
                    isDisabled={
                      task.status === PM_STATUS.COMPLETED ||
                      task.status === PM_STATUS.SKIPPED
                    }
                  />
                ),
              });
            };

            const content = (
              <div>
                {task.summary ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (task.summary as string)?.replace(/```html|```/g, "") ||
                        "",
                    }}
                    className="ai-summary-wo-content"
                  />
                ) : (
                  <div className="mt-10">
                    <AlectifyEmpty
                      description="No Summary Found"
                      width="100"
                      height="80"
                      className="dark-grey"
                    />
                  </div>
                )}
              </div>
            );
            const alectifyCarousel = () => {
              ModalServiceInstance.open(AlectifyModal, {
                name: "alectify-image-carousel",
                title: task.imageUrl ? task?.workTitle : "",
                footer: null,
                width: 800,
                className: "carousel-modal-body",
                onCancel: () => {
                  ModalServiceInstance.close(AlectifyModal, {
                    name: "alectify-image-carousel",
                  });
                },
                children: (
                  <AlectifyCarousel
                    videoWidth={"50rem"}
                    videoHeight={"auto"}
                    details={task}
                    thumbnailLimit={5}
                    recordID={
                      task?.masterPreventiveMaintenance?.id || task?.id || ""
                    }
                  />
                ),
              });
            };
            return (
              <Row gutter={16} wrap={false}>
                <div className="row-width d-flex align-items-center">
                  <Col
                    span={task?.procedure ? 19 : 24}
                    className="title-container-wo"
                  >
                    <span className="mr-5">
                      {!props.isMasterPM ? (
                        <>
                          {isVideoFileType(task?.imageUrl || "") ? (
                            <Avatar
                              shape="square"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                alectifyCarousel();
                              }}
                            >
                              <PlayCircleOutlined />
                            </Avatar>
                          ) : (
                            <Avatar
                              size={35}
                              shape="square"
                              src={task.imageUrl || NO_IMAGE_AVATAR}
                              className="cursor-pointer alectify-avatar-clickable-border "
                              onClick={(e: any) => {
                                // handleFetchAttachments();
                                e.stopPropagation();
                                alectifyCarousel();
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <AlectifyDetailCardImage
                          src={task?.imageUrl || NO_IMAGE_AVATAR}
                          className="procedure-card-table-img"
                          showImage
                        />
                      )}
                    </span>
                    {!props.isMasterPM ? (
                      <Popover
                        content={content}
                        trigger="hover"
                        placement="rightBottom"
                        title={
                          <div className="d-flex align-items-center justify-content-space-between">
                            <div className="ml-5 font-size-17 text-overflow-none">
                              {task?.workTitle}
                            </div>
                            {/* <div className="mr-10">{<ChatBotIcon />}</div> */}
                          </div>
                        }
                        overlayClassName="ai-summary-work-order-popover ai-table-summary"
                        arrow={true}
                      >
                        <span
                          className="text-ellipsis"
                          onClick={(e) => {
                            e.stopPropagation();
                            DrawerServiceInstance.open(AlectifyDrawer, {
                              width: 480,
                              title: truncateText(task.workTitle, 40),
                              name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                              closable: true,
                              className: "bg-grey",
                              closeIcon: <CloseOutlined />,
                              onClose: () =>
                                DrawerServiceInstance.close(AlectifyDrawer, {
                                  name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                                }),
                              children: (
                                <PmDetail
                                  pmExternalRecord={task as any}
                                  pmId={task.id}
                                  pmType={
                                    props.type === "ALL"
                                      ? task.pmType
                                      : props.type
                                  }
                                  isMasterPM={props.isMasterPM}
                                />
                              ),
                            });
                          }}
                        >
                          <span
                            className={`${
                              value ? " text-link-title" : "no-text-link-title"
                            }`}
                          >
                            {value || "(No title)"}
                          </span>
                        </span>
                      </Popover>
                    ) : (
                      <span
                        className="text-ellipsis"
                        onClick={(e) => {
                          e.stopPropagation();
                          DrawerServiceInstance.open(AlectifyDrawer, {
                            width: 480,
                            title: truncateText(task.workTitle, 40),
                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                            closable: true,
                            className: "bg-grey",
                            closeIcon: <CloseOutlined />,
                            onClose: () =>
                              DrawerServiceInstance.close(AlectifyDrawer, {
                                name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                              }),
                            children: (
                              <PmDetail
                                pmExternalRecord={task as any}
                                record={task}
                                pmId={task.id}
                                pmType={
                                  props.type === "ALL"
                                    ? task.pmType
                                    : props.type
                                }
                                isMasterPM={props.isMasterPM}
                                hideDetailBtn={props.isMasterPM}
                              />
                            ),
                          });
                        }}
                      >
                        <span
                          className={`${
                            value ? " text-link-title" : "no-text-link-title"
                          }`}
                        >
                          {value || "(No title)"}
                        </span>
                      </span>
                    )}
                  </Col>

                  {/* <Col span={2}>
                        {task?.priority === TaskPriorityEnum.CRITICAL && (
                          <Tooltip title={TaskPriorityEnum.CRITICAL}>
                            <WarningIcon height={12} width={17} />
                          </Tooltip>
                        )}
                      </Col> */}
                  <Col span={5} className="simple-col-notifications">
                    {!isEmpty(task.procedure) && (
                      <Tooltip
                        title={`${AlectifyText.PROCEDURE_CHECKLIST} ${task.procedure?.procedureStepCheckedTotalCount}/${task.procedure?.procedureStepTotalCount}`}
                      >
                        <div
                          className="procedure-col"
                          onClick={(e) => {
                            e.stopPropagation();
                            openProcedureCheckListDrawer();
                          }}
                        >
                          <span className="cursor-pointer d-flex align-items-center alectify-task-table-collaboration-count-text">
                            {!isEmpty(task.procedure) &&
                              getTaskStatusIcon(task.procedure)}
                          </span>
                          <span className="count-procedure">
                            {task.procedure?.procedureStepCheckedTotalCount}/
                            {task.procedure?.procedureStepTotalCount}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  </Col>
                </div>
              </Row>
            );
          },
        },

        {
          title: AlectifyText.ASSET,
          key: "asset_type",
          dataIndex: "asset_type",
          searchable: true,
          width: 150,
          visible: true,
          onCell,
          render: (_: any, record: ITask) => {
            const assetCount = record.assets.length || 0;
            const areaCount = record.areas.length || 0;
            const total = assetCount + areaCount || 0;
            const displayText =
              total > 1 ? (
                <strong>{`Multiple Assets [${total}]`}</strong>
              ) : (
                (record.assets[0] as any)?.asset?.name ||
                (record.areas[0] as any)?.area?.name ||
                "No Asset"
              );
            const isGeneric = record?.isGeneric;
            return (
              <Space direction="horizontal" size={15}>
                <Tooltip
                  title={
                    (!isEmpty(record.assets) || !isEmpty(record.areas)) &&
                    "Assets"
                  }
                >
                  {displayText === "No Asset" ? (
                    displayText
                  ) : (
                    <TextToLink
                      className="text-to-link-options"
                      text={displayText}
                      underline={!isGeneric}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isGeneric) {
                          const mergedRecords =
                            [...record.assets, ...record.areas].length > 1;

                          if (mergedRecords) {
                            // Open the modal with the list of assets and areas
                            ModalServiceInstance.open(AlectifyModal, {
                              name: MODAL_NAMES.ASSET_NAME_MODAL,
                              title: `Asset list of (${record.workTitle})`,
                              footer: null,
                              width: 800,
                              children: (
                                <AlectifyTable
                                  onDataFetch={() => {}}
                                  dataSource={[
                                    ...record.assets.map((asset: any) => {
                                      return { ...asset.asset, type: "Tag" };
                                    }),
                                    ...record.areas.map((ar: any) => {
                                      return {
                                        ...ar.area,
                                        type: "PackageRoom",
                                      };
                                    }),
                                  ]}
                                  total={
                                    [...record.assets, ...record.areas].length
                                  }
                                  showPagination={false}
                                  columns={[
                                    {
                                      dataIndex: "name",
                                      width: 180,
                                      ellipsis: true,
                                      title: "Name",
                                      render: (value, assetRecord) => {
                                        const goToAssetDetails = () => {
                                          let url: string;
                                          if (
                                            assetRecord.type === "PackageRoom"
                                          ) {
                                            url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                                          } else {
                                            url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                                          }
                                          window.open(url, "_blank");
                                        };
                                        return (
                                          <TextToLink
                                            text={
                                              <Space>
                                                <Tooltip title={value || "NA"}>
                                                  <span>
                                                    {truncateText(value, 29)}
                                                  </span>
                                                </Tooltip>
                                                <ExportOutlined />
                                              </Space>
                                            }
                                            onClick={goToAssetDetails}
                                          />
                                        );
                                      },
                                    },

                                    {
                                      title: AlectifyText.ASSET_TYPE,
                                      key: "asset_type",
                                      width: 140,
                                      render: (value, assetRecord) => {
                                        return (
                                          <>
                                            {assetRecord.type === "Tag" ? (
                                              <AssetTag />
                                            ) : assetRecord.type ===
                                              "PackageRoom" ? (
                                              <AssetPackageTag />
                                            ) : (
                                              <GenericTag />
                                            )}
                                          </>
                                        );
                                      },
                                    },

                                    {
                                      dataIndex: "location",
                                      title: AlectifyText.LOCATION,
                                      width: 160,
                                      ellipsis: true,
                                      render: (location, assetRecord) => {
                                        return (
                                          <Tooltip title={location || "NA"}>
                                            {truncateText(location || "-", 30)}
                                          </Tooltip>
                                        );
                                      },
                                    },

                                    {
                                      dataIndex: "description",
                                      title: "description",
                                      width: 200,
                                      ellipsis: true,
                                      render: (description, assetRecord) => {
                                        return (
                                          <Tooltip title={description || "NA"}>
                                            {truncateText(
                                              description || "-",
                                              37,
                                            )}
                                          </Tooltip>
                                        );
                                      },
                                    },
                                  ]}
                                />
                              ),
                              onCancel: () =>
                                ModalServiceInstance.close(AlectifyModal, {
                                  name: MODAL_NAMES.ASSET_NAME_MODAL,
                                }),
                            });
                          } else {
                            // Redirect directly to asset/area detail page
                            const singleAsset: any =
                              record.assets.length === 1
                                ? record.assets[0]
                                : null;
                            const singleArea: any =
                              record.areas.length === 1
                                ? record.areas[0]
                                : null;

                            if (singleAsset) {
                              const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
                              window.open(url, "_blank");
                            } else if (singleArea) {
                              const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
                              window.open(url, "_blank");
                            }
                          }
                        }
                      }}
                    />
                  )}
                </Tooltip>
              </Space>
            );
          },
        },
        {
          title: AlectifyText.WORK_ORDER_TYPE,
          key: "taskCategory",
          dataIndex: "taskCategory",
          visible: true,
          ellipsis: true,
          width: 160,
          onCell,
          render: (target: any, record: ITask) => (
            <div className="d-flex justify-content-space-between align-items-center">
              <Tooltip
                title={`${
                  (record.taskCategory && enumToTile(record.taskCategory)) ||
                  "-"
                }`}
              >
                <span className="text-ellipsis">
                  {`${
                    (record.taskCategory && enumToTile(record.taskCategory)) ||
                    "-"
                  }`}
                </span>
              </Tooltip>
              {record.isRecurring && (
                <Tooltip title={renderWorkOrderRepeats(record)}>
                  <span className="ml-5">
                    <RecurringIcon />
                  </span>
                </Tooltip>
              )}
            </div>
          ),
        },

        {
          title: AlectifyText.STATUS,
          dataIndex: "status",
          key: "status",
          width: 180,
          visible: !props.isMasterPM,
          onCell,
          render: (value: Date, task: ITask) => {
            return <>{PM_STATUS_COMPONENTS[task.status]}</>;
          },
        },

        {
          title: AlectifyText.DUEDATE,
          key: "dueDate",
          dataIndex: "dueDate",
          visible: true,
          width: 100,
          onCell,
          render: (dueDate: Date, record: ITask) => (
            <div className="d-flex justify-content-space-between">
              <Space direction="vertical" size={15}>
                <div className="creator-container">
                  <CalendarDateIcon />
                  <span className="creator-name-date">
                    {`${displayDateTime(dueDate, false, false)}`}
                  </span>
                </div>
              </Space>
            </div>
          ),
        },

        {
          title: AlectifyText.ACTION_REQUIRED_BY,
          dataIndex: "assignees",
          key: "assignees",
          width: 130,
          onCell,
          filters: projectTeamMembers?.data.map((user) => ({
            text: getFullUserName(user),
            value: user.id,
          })),
          visible:
            !props.isMasterPM &&
            (props.filter.includes(PM_STATUS.PENDING) ||
              props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW)),
          render: (assignees: IPmAssignees[], record: ITask) => {
            const actionRequired = getPMEActionRequired(record);
            if (!isEmpty(assignees) || !isEmpty(record.approvers)) {
              return (
                <Space direction="vertical" size={5}>
                  <AvatarGroup
                    maxCount={2}
                    showImage
                    users={
                      actionRequired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                        ? assignees.map(({ user }) => user)
                        : record.approvers?.map(({ user }) => user)
                    }
                  />
                </Space>
              );
            }
            return <></>;
          },
        },

        {
          title: AlectifyText.CREATED,
          visible: true,
          key: "createdAt",
          dataIndex: "createdAt",
          width: 180,
          render: (created: any, record: ITask) => {
            return (
              <>
                <Space>
                  <Tooltip
                    title={`${getFullUserName(record?.createdBy as any)}`}
                  >
                    <Avatar
                      src={record?.createdBy?.image_url || NO_IMAGE_AVATAR}
                      className="cursor-pointer"
                      onClick={() =>
                        ModalServiceInstance.open(AlectifyModal, {
                          name: "alectify-task-image-viewer",
                          title: "Preview",
                          footer: null,
                          onCancel: () => {
                            ModalServiceInstance.close(AlectifyModal, {
                              name: "alectify-task-image-viewer",
                            });
                          },
                          children: (
                            <ViewImage
                              imageUrl={
                                record?.createdBy?.image_url || NO_IMAGE_AVATAR
                              }
                              isDownloadable={
                                !isEmpty(record?.createdBy?.image_url)
                              }
                            />
                          ),
                        })
                      }
                    />
                  </Tooltip>
                  <span className="creator-name-date">
                    {`${displayDateTime(record?.createdAt, false, false)}`}
                  </span>
                </Space>
              </>
            );
          },
        },
        {
          width: 60,
          title: AlectifyText.ACTIONS,
          align: "center",
          fixed: "right",
          dataIndex: "",
          visible: !props.isMasterPM,
          render: (_: any, task: ITask) => {
            if (!props.showSelection) {
              const openAttachmentsDrawer = () => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 860,
                  title: `${task.workTitle || "Work Order"} Attachments`,
                  name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () => {
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
                    });
                    props.fetchTasks();
                  },
                  children: <PreventiveDocumentsAttachment details={task} />,
                });
              };
              const openProcedureCheckListDrawer = () => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 680,
                  title: "Checklist",
                  name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () => {
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                    });
                    props.fetchTasks();
                  },
                  showFooter: true,
                  destroyOnClose: true,
                  readOnly: true,
                  cancelText: AlectifyText.CLOSE,
                  children: (
                    <ProcedureDetail
                      procedure={task.procedure as any}
                      taskId={task.id}
                      isDisabled={
                        task.status === PM_STATUS.COMPLETED ||
                        task.status === PM_STATUS.SKIPPED
                      }
                    />
                  ),
                });
              };

              const handleUndoConfirm = async () => {
                try {
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  });
                  const resp = await undoTaskStatus(task.id);
                  if (resp?.status) {
                    message.success(
                      `${task?.workTitle} has changed the state from ${
                        statusMap[task?.status] || task?.status
                      } to ${
                        statusMap[resp.data?.status] || resp.data?.status
                      }`,
                    );
                    props.fetchTasks();
                  }
                } catch (error) {
                  message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
                }
              };
              const openWarningmodal = () => {
                ModalServiceInstance.open(AlectifyModal, {
                  name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  title: AlectifyText.CONFIRMATION,
                  footer: null,
                  onCancel: () => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.UNDO_WARNING_MODAL,
                    });
                  },
                  children: (
                    <ConfirmationModal
                      icon={WarningIcon}
                      message={MESSAGES.PM_EXTERNAL.REOPEN_WARNING}
                      note={MESSAGES.PM_EXTERNAL.REOPEN_NOTE}
                      onConfirm={handleUndoConfirm}
                      okText="Re open"
                      cancelText="Cancel"
                      onCancel={() =>
                        ModalServiceInstance.close(AlectifyModal, {
                          name: MODAL_NAMES.UNDO_WARNING_MODAL,
                        })
                      }
                    />
                  ),
                });
              };

              const dropdownItems = actionItems({
                currentUser,
                showMyWorkOrders,
                task,
                projectId: props.masterProjectId || "",
                subProjectId: props.subProjectId || "",
                navigate,
                myItemsColumns: props.myItemsColumns,
                onEdit: props.onEdit,
                updateStatus: props.updateStatus,
                openSubmitForReviewModal: props.openSubmitForReviewModal,
                onUpload: openAttachmentsDrawer,
                openProcedureCheckListDrawer,
                removeTask: props.removeTask,
                openSpareParts: props.openSpareParts,
                openWarningmodal: openWarningmodal,
                taskStatusStepIcon: taskStatusStepIcon,
                showDetailPageLink: true,
                goToPmDetail,
              });

              const renderDropdown: any =
                dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

              return (
                <>
                  <Dropdown
                    menu={{
                      items: renderDropdown,
                    }}
                    trigger={["click"]}
                  >
                    <EllipsisOutlined
                      rotate={90}
                      className="alectify-action-icon"
                    />
                  </Dropdown>
                </>
              );
            } else {
              return (
                <AlectifyButton
                  type="primary"
                  className="alectify-primary-btn"
                  text="Select"
                  onClick={() =>
                    props.onSelectWorkOrder && props.onSelectWorkOrder(task)
                  }
                />
              );
            }
          },
        },
      ].filter((column) => column.visible),
    [isTaskType, props.myItemsColumns],
  );
};
export default useSimpleColumns;
