//libs
import {
  Card,
  Col,
  Form,
  Row,
  Space,
  Spin,
  Steps,
  UploadProps,
  message,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

//components
import ProcedureBasicInformation from "./basic-info";
import ProcedureCheckList from "./check-list";

//interfaces
import { IProcedureCreateEditProps } from "./ProcedureCreateEdit.interface";

//redux & sources
import {
  createProdure,
  updateProcedure,
} from "services/procedures/procedures.service";

//utils & constants
import { MESSAGES } from "constants/messages";
import {
  createProcedureRequestPayload,
  initializeFormValues,
} from "./ProcedureCreateEdit.helpers";
import AlectifyText from "static/texts.json";

//styles
import "./ProcedureCreateEdit.scss";
import AlectifyButton from "components/shared/button";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { getBase64 } from "components/shared/user-profile-form/UserProfileForm.helper";
import { useDispatch, useSelector } from "react-redux";
import { getProcedures } from "redux/components/procedures/sources";
import { IRootState } from "redux/rootReducer";
import ProcedureIcon from "components/icons/ProceduresIcon";

const ProcedureCreateEdit: React.FC<IProcedureCreateEditProps> = (
  props: IProcedureCreateEditProps,
) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [formSteps, setFormSteps] = useState<{
    selectedValues: number[];
    currentActiveTab: number;
    procedureId: string | null;
  }>({
    selectedValues: [1],
    currentActiveTab: 1,
    procedureId: null,
  });

  const [selectedCategory, setSelectedCategory] = useState<{
    key: string;
    value: string;
    label: string;
  } | null>(null);
  const { meta, activeMasterProject } = useSelector((state: IRootState) => ({
    meta: state.procedures.meta,
    activeMasterProject: state.common.activeMasterProject,
  }));

  const [api, contextHolder] = notification.useNotification();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<any>(null);
  const handleImageUpload: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      handleImagePreview(newFileList[0].originFileObj);
    }
  };

  const handleImagePreview = async (file: any) => {
    if (file) {
      file.preview = await getBase64(file);
      setPreviewImage(file.preview);
    }
  };

  const openNotification = (description: string) => {
    const key = `open${Date.now()}`;
    api.open({
      message: "",
      description: description,
      key,
      duration: 3,
      icon: <ProcedureIcon />,
    });
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (
      initialFormValues &&
      JSON.stringify(initialFormValues) !== JSON.stringify(allValues)
    ) {
      setFormChanged(true);
    }
  };

  const onSubmit = async (values: any) => {
    setLoader(true);
    // Check if a category is selected and the selected category's value is different from the current form value.
    // If so, reset the categoryId to null.
    // Otherwise, if a category is selected, update the form's categoryId with the selected category's key.
    if (
      !isEmpty(selectedCategory) &&
      selectedCategory?.value !== values.category
    ) {
      values.categoryId = null;
    } else if (!isEmpty(selectedCategory)) {
      values.categoryId = selectedCategory.key;
    }

    try {
      const payload = createProcedureRequestPayload(values);
      let response: any;
      const formData = new FormData();
      formData.append("name", payload.name);
      formData.append("jobType", payload.jobType);
      formData.append("reference", payload.reference);
      formData.append("comments", payload.comments as any);
      formData.append("fileUpload", payload.fileUpload as any);
      formData.append("description", payload.description);
      formData.append("categoryId", payload.categoryId || "");
      formData.append("categoryName", payload.categoryName || "");
      formData.append("projectId", payload.projectId || "");
      if (!isEmpty(fileList)) {
        formData.append("image", fileList[0]?.originFileObj);
      }
      if (props.isEdit || formSteps.procedureId) {
        response = await updateProcedure(
          props.procedure?.id || formSteps.procedureId || "",
          formData,
        );

        if (formChanged && formSteps.currentActiveTab === 1) {
          openNotification(MESSAGES.PROCEDURE_MESSAGES.UPDATED);
          setFormChanged(false);
        }
      } else {
        response = await createProdure(formData);
        openNotification(MESSAGES.PROCEDURE_MESSAGES.CREATED_SUCCESS);
      }
      if (response.status) {
        setFormSteps((prevState) => ({
          ...prevState,
          selectedValues: [1, 2],
          currentActiveTab: prevState.currentActiveTab + 1,
          procedureId: response.data?.id,
        }));
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      message.error(MESSAGES.PROCEDURE_MESSAGES.CREATED_FAILURE);
      console.log(ex);
    }
  };

  // Function to navigate to the next step
  const goNext = async () => {
    try {
      if (formSteps.currentActiveTab === 1) {
        await props.FormInstance.validateFields();
        props.FormInstance.submit();
        return;
      }
      setFormSteps((prevState) => ({
        ...prevState,
        selectedValues: [1, 2],
        currentActiveTab: prevState.currentActiveTab + 1,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  // Function to navigate to the previous step
  const goBack = () => {
    setFormSteps((prevState) => ({
      ...prevState,
      selectedValues: [1],
      currentActiveTab: prevState.currentActiveTab - 1,
    }));
  };

  const closeProcedureDrawer = () => {
    props.FormInstance.resetFields();
    let params = {
      page: meta.currentPage,
      limit: meta.itemsPerPage,
      search: props.searchText,
      projectIds: (activeMasterProject && [activeMasterProject?.id]) || null,
    };
    dispatch(getProcedures(params));
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
    });
  };

  const stages = [
    <Form
      layout="vertical"
      form={props.FormInstance}
      onFinish={onSubmit}
      onValuesChange={handleValuesChange}
    >
      <ProcedureBasicInformation
        onCategorySelect={setSelectedCategory as any}
        procedure={props?.procedure}
        fileList={fileList}
        previewImage={previewImage}
        handleImageUpload={handleImageUpload as any}
      />
    </Form>,
    <>
      <ProcedureCheckList procedureId={formSteps.procedureId} />
    </>,
  ];

  useEffect(() => {
    if (props.isEdit && !isEmpty(props.procedure)) {
      if (!isEmpty(props.procedure.imageUrl)) {
        setPreviewImage(props.procedure.imageUrl || "");
      }
      setFormSteps((prev) => ({
        ...prev,
        procedureId: props.procedure?.id || null,
      }));

      const initializedValues = initializeFormValues(
        props.procedure,
        props.FormInstance,
      );
      props.FormInstance.setFieldsValue(initializedValues);

      setInitialFormValues(initializedValues);

      if (props.procedure.procedureCategory) {
        setSelectedCategory({
          key: props.procedure.procedureCategory.id,
          value: props.procedure.procedureCategory.name,
          label: props.procedure.procedureCategory.name,
        });
      }
    } else {
      if (!isEmpty(activeMasterProject)) {
        props.FormInstance.setFieldValue(
          "masterProject",
          activeMasterProject?.id,
        );
      }
    }
  }, [props.isEdit, props.procedure]);

  return (
    <Spin spinning={loader}>
      {contextHolder}
      <Row className="create-procedure-body" gutter={16}>
        <Col span={4}>
          <div className="create-subProject-steps-body">
            <Card title={`${formSteps.currentActiveTab}/2 Steps Completed`}>
              <Steps
                direction="vertical"
                size="small"
                current={formSteps.currentActiveTab - 1}
                items={[
                  {
                    title: "Basic Information",
                  },
                  {
                    title: "Checklist Steps",
                  },
                ]}
              />
            </Card>
          </div>
        </Col>
        <Col span={20} className="alectify-procedure-information-container">
          {stages[formSteps.currentActiveTab - 1]}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="text-align-right mt-20 mb-10">
          <Space>
            <AlectifyButton
              text={
                formSteps.currentActiveTab === 1
                  ? AlectifyText.CANCEL
                  : AlectifyText.PREVIOUS
              }
              type="default"
              className="light-blue-button"
              onClick={
                formSteps.currentActiveTab === 2 ? goBack : closeProcedureDrawer
              }
            />
            {formSteps.currentActiveTab === 2 && (
              <AlectifyButton
                text={"Save & Close"}
                type="primary"
                onClick={closeProcedureDrawer}
              />
            )}
            {formSteps.currentActiveTab === 1 && (
              <AlectifyButton
                text={"Save & Next"}
                type="primary"
                onClick={goNext}
              />
            )}
          </Space>
        </Col>
      </Row>
    </Spin>
  );
};

export default ProcedureCreateEdit;
