import { useEffect, useState } from "react";
import AlectifyTable from "../table";
import {
  IRoundsCommentsTableProps,
  IRoundsTableState,
} from "./RoundsDetailTable.interface";
import { getRoundsCommentsTimeline } from "services/data-round/data-round.service";
import useCommentsTable from "./effects/useCommentsTable";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";

const RoundsCommentsTable: React.FC<IRoundsCommentsTableProps> = (
  props: IRoundsCommentsTableProps,
) => {
  const [state, setState] = useState<IRoundsTableState>({
    data: [],
    fetching: false,
    meta: {
      total_count: 0,
      total_pages: 0,
    },
  });

  useEffect(() => {
    fetchComments();
  }, [props.checkPointRoundId, props.drProjectId]);

  const fetchComments = async (paginationOptions?: IPagination) => {
    try {
      const params = {
        page: paginationOptions?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: paginationOptions?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };

      if (props.drProjectId) {
        setState((prev: IRoundsTableState) => ({
          ...prev,
          fetching: true,
        }));
        const response = await getRoundsCommentsTimeline(
          props.drProjectId,
          props.checkPointRoundId,
          params,
        );
        setState((prev: IRoundsTableState) => ({
          ...prev,
          data: response?.data ?? [],
          meta: response?.meta ?? { total_count: 0 },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setState((prev: IRoundsTableState) => ({
        ...prev,
        fetching: false,
      }));
    }
  };

  const columns = useCommentsTable();

  return (
    <AlectifyTable
      loading={state.fetching}
      dataSource={state.data}
      total={state.meta.total_count}
      columns={columns}
      onDataFetch={fetchComments}
      defaultPageSize={5}
    />
  );
};

export default RoundsCommentsTable;
