//libs
import { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Col, message, Row, Space, Tabs, TabsProps } from "antd";
import { get, isEmpty, truncate } from "lodash";

//interfaces
import { IWorkOrderPMDetails } from "pages/my-work-orders/details/pm/WorkOrderPMDetails.interface";

//services
import {
  deletePMExternal,
  fetchExternalPMDetails,
  updatePrevMaintenanceStatus,
} from "services/pm-external/pm-external.service";
import { undoTaskStatus } from "services/tasks/tasks.service";

//enums
import { CommentReferenceEnum, PM_STATUS } from "enums";
import { IPmExternal, PM_TYPES, actions } from "redux/components/pm-external";

//components
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import WorkOrderPMEDetailsActionItems from "./WorkOrderPMEDetails.actions";
import ProcedureDetail from "components/shared/procedure-detail";
import { WarningIcon } from "components/icons";
import { taskStatusStepIcon } from "components/shared/tasks-table/effects/useTasksColumns";

//utils & helpers
import AlectifyText from "static/texts.json";
import { isExternalUser, updateComponentByNotfication } from "utils/helpers";

//redux
import { IRootState } from "redux/rootReducer";
import { setUploadDocuments } from "redux/components/common/sources";
import { useDispatch, useSelector } from "react-redux";
import { ITask } from "redux/components/tasks";

//constants
import { ROUTES } from "routes/Routes.constants";
import { MODAL_NAMES } from "constants/modal.constants";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { MESSAGES } from "constants/messages";
import { PM_WARNING_MESSAGES } from "constants/warning-messages.constant";

//styles
import "./WorkOrderPMDetails.scss";
import ReviewConfirmationModal from "components/shared/confirmation/ReviewConfirm";
import WorkOrderSpareParts from "components/shared/work-order-spare-parts";
import DetailIcon from "components/icons/DetailIcon";
import { IAlectifyModalProps } from "components/shared/modal/AlectifyModal.interface";
import AlectifyButton from "components/shared/button";
import DraggableButton from "components/shared/draggable-button/DraggableButton";
import Comments from "components/shared/comments";
import WorkOrderStatus from "./WorkOrderStatus";
import BackArrowIcon from "components/icons/BackArrowIcon";
import AttachmentIcon from "components/icons/AttachmentIcon";
import WorkOrderCreateEdit from "pages/work-order/WorkOrderCreateEdit";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TaskSubmitForReviewForm from "components/shared/tasks-table/TaskSubmitForReviewForm";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import WorkOrderPdfTemplate from "../work-order-pdf/WorkOrderPdfTemplate";
import TaskDenialForm from "components/shared/tasks-table/TaskDenialForm";
import { actions as TaskActions } from "redux/components/tasks";

const WorkOrderPMDetails: React.FC<IWorkOrderPMDetails> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [FormInstance] = useForm();
  const [submitReviewForm] = useForm();
  const [deniedForm] = useForm();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const pmType = new URLSearchParams(location.search).get("pmType");
  const { pmId, subProjectId } = useParams();
  const { user } = useSelector((state: IRootState) => state.auth);
  const [proceduresActiveKey, setProceduresActiveKey] = useState("0");
  const [loader, setLoader] = useState<boolean>(false);

  const [pmExternal, setPmExternal] = useState<ITask | null>(null);
  const [activeTab, setActiveTab] = useState<string>("details");
  const [showComments, setShowComments] = useState<boolean>(false);

  const includeIds = ["additionalIncludeId1", "additionalIncludeId2"];
  const excludeIds = ["idToExclude"];
  const panelRef = useRef<HTMLDivElement>(null);

  const [activeHistoryAccordion, setActiveHistoryAccordion] = useState<
    string | string[]
  >(["active"]);

  const handleButtonClick = () => {
    setActiveHistoryAccordion("active");

    setTimeout(() => {
      if (panelRef.current) {
        panelRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, 200);
  };

  const getPmExternalDetails = async (id: string) => {
    setLoader(true);
    try {
      // dispatch(getPmInternalAttachments(id));
      const response = await fetchExternalPMDetails(id);
      if (response.status) {
        setPmExternal(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const onChangeProceduresActiveKey = (key: string) => {
    setProceduresActiveKey(key);
  };

  const isStatusPendingOrWaitingForReview = () => {
    const pm = pmExternal;
    return (
      pm &&
      [
        PM_STATUS.PENDING,
        PM_STATUS.WAITING_FOR_REVIEW,
        PM_STATUS.INPROGRESS,
      ].includes(pm.status)
    );
  };

  const prepareRoutes = (type: string): string => {
    let url: string = "";
    if (type === "home")
      url = (!isExternalUser(user) && navigate(`${ROUTES.PROJECT}`)) || "";
    if (type === "projects")
      url =
        (!isExternalUser(user) &&
          `${ROUTES.PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.project, "id")
          }`) ||
        "";
    if (type === "subproject")
      url =
        (!isExternalUser(user) &&
          `${ROUTES.PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.project, "id")
          }${ROUTES.SUB_PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.subProject, "id")
          }?page=${
            pmType === PM_TYPES.PM_EXTERNAL
              ? "assets"
              : pmType === PM_TYPES.TASK
              ? "tasks"
              : "pm-internal"
          }`) ||
        "";
    return url;
  };

  const updatePMEStatus = async (
    pmExternal: ITask | IPmExternal,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ): Promise<boolean> => {
    const messageText =
      PM_WARNING_MESSAGES[status] || MESSAGES.PM_EXTERNAL.COMMON_WARNING;
    return new Promise((resolve, reject) => {
      openWarningModal(
        messageText,
        async () => {
          setLoader(true);
          try {
            dispatch(TaskActions.showTasksLoader(true));
            await updatePrevMaintenanceStatus(pmExternal.id, status, {
              date: date ? date : new Date(),
              estimatedHours,
              estimatedCost,
              commentId: commentId || undefined,
            });
            dispatch(TaskActions.showTasksLoader(false));
            ModalServiceInstance.forceCloseAll();
            if (status === PM_STATUS.SKIPPED) {
              message.success(AlectifyText.ORDER_SKIPPED);
            }
            if (status === PM_STATUS.COMPLETED) {
              message.success(AlectifyText.ORDER_CLOSED);
            }
            submitReviewForm.resetFields();
            dispatch(setUploadDocuments([]));
            getPmExternalDetails(pmExternal?.id);
            resolve(true);
          } catch (ex) {
            dispatch(TaskActions.showTasksLoader(false));
            setLoader(false);
            console.log(ex);
            reject(ex);
          }
        },
        () => resolve(false),
        "",
        status,
        pmExternal,
      );
    });
  };
  const openCreationModal = (
    _: any,
    isEditing: boolean = false,
    editType?: string,
    task?: ITask,
    isCopying: boolean = false,
  ) => {
    FormInstance.resetFields();
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      className: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      title: isCopying
        ? `Copying ${truncate(task?.workTitle, {
            length: 50,
            omission: "...",
          })}`
        : isEditing
        ? `Update ${truncate(task?.workTitle, {
            length: 50,
            omission: "...",
          })} (${editType === "this_event" ? "This Event" : "All Event"})`
        : AlectifyText.CREATE_WORK_ORDER,
      width: 800,
      style: { top: 30 },
      okText: "Submit",
      destroyOnClose: true,
      onOk: FormInstance.submit,
      onCancel: () => {
        getPmExternalDetails(pmId || "");
        FormInstance.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
        });
      },
      children: (
        <WorkOrderCreateEdit
          FormInstance={FormInstance}
          editing={isEditing}
          editType={editType || ""}
          taskId={pmId || ""}
          subProjectId={
            pmExternal?.subProject.id === "all"
              ? task?.subProject.id
              : pmExternal?.subProject?.id || ""
          }
          masterProjectId={
            pmExternal?.project?.id === "all"
              ? task?.project.id
              : pmExternal?.project?.id || task?.project?.id
          }
          isTask={pmType === PM_TYPES.TASK}
          copying={isCopying}
          callbackEffects={() => getPmExternalDetails(pmId || "")}
          type={
            pmType === "pm-external"
              ? PM_TYPES.PM_EXTERNAL
              : pmType === "TASK"
              ? PM_TYPES.TASK
              : PM_TYPES.TASK
          }
        />
      ),
    });
  };
  const openPMESubmitForReviewModal = (pm: IPmExternal) => {
    const config: IAlectifyModalProps = {
      name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
      title: AlectifyText.SUBMIT_REVIEW,
      className: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      style: { top: 30 },
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        });
        submitReviewForm.resetFields();
        dispatch(setUploadDocuments([]));
      },
      onOk: submitReviewForm.submit,
      width: 700,
      okText: "Submit",
      closeIcon: false,
      children: (
        <TaskSubmitForReviewForm
          form={submitReviewForm}
          subProjectId={pmExternal?.subProject.id || ""}
          pmExternal={pm}
          masterProjectId={pmExternal?.project.id || ""}
          fetchPmExternal={(options?: IPagination) =>
            getPmExternalDetails(pmId || "")
          }
          updateStatus={updatePMEStatus}
          isDetailPage
          procedureSteps={pmExternal?.procedure?.procedureSteps}
        />
      ),
    };
    ModalServiceInstance.open(AlectifyModal, config);
  };

  const openPMEAttachmentsDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 860,
      title: pmExternal?.asset?.name || pmExternal?.area?.name || "",
      onClose: () => DrawerServiceInstance.close(AlectifyDrawer),
      children: <PreventiveDocumentsAttachment details={pmExternal} />,
    });
  };

  const openProcedureCheckListDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 680,
      title: "Checklist",
      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        });
      },
      showFooter: true,
      readOnly: true,
      cancelText: AlectifyText.CLOSE,
      destroyOnClose: true,
      children: (
        <ProcedureDetail
          procedure={pmExternal?.procedure as any}
          taskId={pmExternal?.id}
          isDisabled={
            pmExternal?.status === PM_STATUS.COMPLETED ||
            pmExternal?.status === PM_STATUS.SKIPPED
          }
        />
      ),
    });
  };

  const removePMExternal = async (pmId: string) => {
    dispatch(actions.setPMExterLoader(true));
    try {
      await deletePMExternal(pmId);
      message.success("Deleted");
      navigate(-1);
      dispatch(actions.setPMExterLoader(false));
    } catch (ex) {
      dispatch(actions.setPMExterLoader(false));
      console.log(ex);
    }
  };

  const onTaskDeny = (pmExternal?: ITask | IPmExternal) => {
    ModalServiceInstance.open(AlectifyModal, {
      title: AlectifyText.DENY_WORK_ORDER,
      name: MODAL_NAMES.TASK_DENY_MODAL,
      okText: "Submit",
      cancelText: "Cancel",
      onOk: () => {
        deniedForm.submit();
      },
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.TASK_DENY_MODAL,
        });
      },
      children: (
        <TaskDenialForm
          form={deniedForm}
          isDetailPage
          subProjectId={pmExternal?.id || ""}
          pmExternal={pmExternal as any}
          masterProjectId={pmExternal?.project?.id || ""}
          updateStatus={updatePMEStatus}
        />
      ),
    });
  };

  const openWarningModal = (
    message: string,
    successCb: () => void,
    cancelCb?: () => void,
    note?: string,
    status?: string,
    pmExternal?: ITask | IPmExternal,
  ) => {
    if (
      status === "COMPLETED" &&
      pmExternal?.approvers &&
      pmExternal.approvers.length > 0
    ) {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.REVIEW_COMPLETED,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
        title: AlectifyText.REVIEW_COMPLETE,
        className: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
        style: { top: 30 },
        footer: (
          <Space>
            <AlectifyButton
              onClick={() => onTaskDeny(pmExternal)}
              type="primary"
              text={AlectifyText.DENY}
              danger
            />
            <AlectifyButton
              onClick={() => successCb()}
              type="primary"
              text={"Submit"}
            />
          </Space>
        ),
        onCancel,
        children: (
          <ReviewConfirmationModal
            pmExternal={pmExternal}
            isDetailPage
            icon={WarningIcon}
            message={message}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    } else {
      successCb && successCb();
    }
  };
  const openWarningModalCallback = () => {
    openWarningModal(
      `You are about to UNDO the State from ${pmExternal?.status} to ${
        pmExternal?.completedAt
          ? AlectifyText.WAITING_FOR_REVIEW
          : AlectifyText.PENDING
      }`,
      handleUndoConfirm,
      () => {},
      "Are you sure?",
    );
  };
  const openRemoveWaringModal = (pmId: string) => {
    openWarningModal(MESSAGES.PM_EXTERNAL.REMOVER_WARNING, () =>
      removePMExternal(pmId),
    );
  };

  const submittedInformationModal = () => {
    const onCancel = () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
      });
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.REVIEW_COMPLETED,
      title: AlectifyText.REVIEW_COMPLETE,
      onCancel,
      style: { top: 20 },
      footer: (
        <AlectifyButton
          type="default"
          className="alectify-default-button"
          text="Close"
          onClick={onCancel}
        />
      ),
      children: (
        <ReviewConfirmationModal
          pmExternal={pmExternal as any}
          icon={WarningIcon}
          message={"message"}
          isDetailPage
          onConfirm={() => {
            onCancel();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (pmId) {
      getPmExternalDetails(pmId);
    }
  }, [pmId]);

  const openSpareParts = (record: ITask | IPmExternal) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1000,
      name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
      title: AlectifyText.SPARE_PARTS_USED,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
        });
      },
      closeIcon: true,
      footer: null,
      children: (
        <WorkOrderSpareParts
          record={record}
          callback={() => getPmExternalDetails(pmExternal?.id || "")}
          showDrawSparePartsButton={
            record.status !== PM_STATUS.COMPLETED &&
            record.status !== PM_STATUS.SKIPPED
          }
        />
      ),
    });
  };

  const getActionButton = (): ReactNode => {
    return (
      <div className="alectify-detail-page-action-buttons-container">
        <Space>
          {/* {pmExternal?.reviewedAt ? (
            <AlectifyButton
              name="waiting-for-review"
              text={
                <span className="font-size-13">
                  {AlectifyText.SUBMITTED_REVIEW}
                </span>
              }
              className="light-blue-button"
              type="primary"
              onClick={submittedInformationModal}
            />
          ) : (
            <></>
          )} */}

          <AlectifyButton
            name="download-as-pdf"
            text={<span className="font-size-13">Messaging Center</span>}
            className="light-blue-button"
            type="primary"
            onClick={handleButtonClick}
          />

          <WorkOrderPdfTemplate workOrder={pmExternal as any} />

          <WorkOrderPMEDetailsActionItems
            showStatus={false}
            currentUser={user}
            pmExternal={pmExternal}
            projectId={pmExternal?.project?.id || ""}
            subProjectId={pmExternal?.subProject?.id || ""}
            type={PM_TYPES.PM_EXTERNAL}
            updateStatus={updatePMEStatus}
            onEdit={openCreationModal as any}
            openAttachmentsDrawer={openPMEAttachmentsDrawer}
            openSubmitForReviewModal={openPMESubmitForReviewModal}
            openProcedureCheckListDrawer={openProcedureCheckListDrawer}
            removePMExternal={openRemoveWaringModal}
            openWarningModal={openWarningModalCallback}
            taskStatusStepIcon={taskStatusStepIcon}
            openSpareParts={openSpareParts as any}
            downloadPDF={downloadPDF}
            pmDetailPageActive
          />
        </Space>
      </div>
    );
  };

  const downloadPDF = async () => {
    setLoader(true);
    setProceduresActiveKey("2");

    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(5000);

    const input = contentRef.current;
    if (!input) {
      console.error("Component ref is not defined.");
      return;
    }

    // Create an off-screen container for rendering
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.width = "100%";
    container.style.top = "0";
    document.body.appendChild(container);

    // Clone and append elements to container
    const fragment = document.createDocumentFragment();
    includeIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        const clonedElement = element.cloneNode(true) as HTMLElement;

        // Replace specific child elements as required
        const targetElements =
          clonedElement.querySelectorAll("#child-to-replace");
        targetElements.forEach((targetElement) => {
          const blackBoxContainer = document.createElement("div");
          blackBoxContainer.style.marginBottom = "10px";

          const textElement = document.createElement("div");
          textElement.style.fontSize = "10px";
          textElement.innerText = "Add time and comment";

          const blackBox = document.createElement("div");
          blackBox.style.width = "92rem";
          blackBox.style.height = "50px";
          blackBox.style.border = "1px solid #b3b5b4";
          blackBox.style.borderRadius = "8px";
          blackBox.style.marginTop = "5px";

          blackBoxContainer.appendChild(textElement);
          blackBoxContainer.appendChild(blackBox);

          targetElement.replaceWith(blackBoxContainer);
        });

        fragment.appendChild(clonedElement);
      }
    });

    // Remove duplicate IDs from the fragment
    const uniqueIds = new Set();
    fragment.querySelectorAll("*").forEach((el) => {
      const id = el.id;
      if (id && uniqueIds.has(id)) {
        el.remove();
      } else if (id) {
        uniqueIds.add(id);
      }
    });

    // Exclude specified elements from the fragment
    excludeIds.forEach((id) => {
      const element = fragment.querySelector(`#${id}`);
      if (element) {
        element.remove();
      }
    });

    container.appendChild(fragment);

    // Disable animations and transitions
    container.querySelectorAll("*").forEach((el) => {
      (el as HTMLElement).style.animation = "none";
      (el as HTMLElement).style.transition = "none";
    });

    // Ensure fonts are loaded
    await document.fonts.ready;

    // Generate PDF with html2canvas and jsPDF
    try {
      const canvas = await html2canvas(container, {
        scale: 1.5, // Higher scale for better quality
        useCORS: true,
        height: container.scrollHeight,
      });
      const imgData = canvas.toDataURL("image/jpeg", 0.5); // Compress image with 50% quality
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.width - 40;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      if (pdfHeight > pdf.internal.pageSize.height) {
        const totalPages = Math.ceil(pdfHeight / pdf.internal.pageSize.height);
        for (let i = 0; i < totalPages; i++) {
          if (i > 0) {
            pdf.addPage();
          }
          pdf.addImage(
            imgData,
            "JPEG",
            20,
            -(i * pdf.internal.pageSize.height),
            pdfWidth,
            pdfHeight,
          );
        }
      } else {
        pdf.addImage(imgData, "JPEG", 20, 20, pdfWidth, pdfHeight);
      }

      pdf.save("WorkOrderPMDetails.pdf");
    } catch (err) {
      console.error("Error generating PDF: ", err);
    } finally {
      document.body.removeChild(container);
      setLoader(false);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "details",
      label: (
        <>
          <DetailIcon /> {AlectifyText.DETAILS}
        </>
      ),
      children: (
        <WorkOrderStatus
          activeHistoryAccordion={activeHistoryAccordion}
          setActiveHistoryAccordion={setActiveHistoryAccordion}
          panelRef={panelRef}
          showComments={showComments}
          activeTab={activeTab}
          reference_type={CommentReferenceEnum[pmType as CommentReferenceEnum]}
          downloadPDF={downloadPDF}
          onChangeProceduresActiveKey={onChangeProceduresActiveKey}
          proceduresActiveKey={proceduresActiveKey}
          task={pmExternal}
          actionButton={getActionButton()}
          loader={loader}
          prepareRoutes={prepareRoutes}
          submittedInformationModal={submittedInformationModal}
          getPmExternalDetails={getPmExternalDetails}
          procedure={pmExternal?.procedure as any}
          taskId={pmExternal?.id}
          subProjectId={subProjectId || "-"}
          referenceId={pmId || ""}
          isDisabled={!isStatusPendingOrWaitingForReview()}
        />
      ),
    },
    {
      key: "documents",
      label: (
        <>
          <AttachmentIcon /> {AlectifyText.ATTACHMENTS} (
          {(pmExternal?.documents || 0) +
            (pmExternal?.masterPreventiveMaintenance?.files || 0)}
          )
        </>
      ),
      children: (
        <PreventiveDocumentsAttachment
          details={pmExternal}
          isDetailPage
          callback={() =>
            pmExternal?.id && getPmExternalDetails(pmExternal?.id)
          }
        />
      ),
    },
  ];

  const handleUndoConfirm = async () => {
    try {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.UNDO_WARNING_MODAL,
      });

      const id = pmExternal?.id || "";
      const resp = await undoTaskStatus(id);
      if (resp?.status) {
        message.success(
          `${pmExternal?.workTitle} ${AlectifyText.TASK_STATUS_CHANGE_CONTEXT} ${pmExternal?.status} to ${resp.data?.status}`,
        );
        getPmExternalDetails(pmId || "");
      }
    } catch (error) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  updateComponentByNotfication(
    pmType as string,
    (payload) => {
      if (!isEmpty(payload)) {
        if (payload.data?.userId !== user.id) {
          window.location.reload();
        }
      }
    },
    true,
  );

  const closeMessagingCenterDrawer = () => {
    setShowComments(false);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.MESSAGING_CENTER,
    });
  };

  const openMessagingCenterDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 500,
      readOnly: true,
      title: "",
      className: "messaging-center-drawer",
      name: DRAWER_CONSTANTS.MESSAGING_CENTER,
      onClose: closeMessagingCenterDrawer,
      children: (
        <Comments
          details={pmExternal}
          referenceId={pmId || ""}
          reference_type={CommentReferenceEnum[pmType as CommentReferenceEnum]}
          subProjectId={subProjectId || ""}
          onChangeHideButton={(show: boolean) => setShowComments(!show)}
          showHideButton
          showTitleAndCount
          isDetailPage
        />
      ),
    });
  };
  useEffect(() => {
    if (showComments) {
      openMessagingCenterDrawer();
    }
  }, [showComments]);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
    setActiveHistoryAccordion([]);
  };

  return (
    <div ref={contentRef}>
      <Row className="alectify-work-order-details-container">
        <Col
          span={24}
          className="sub-project-tabs alectify-work-order-task-documents-details-section "
        >
          {!isEmpty(pmExternal) && (
            <div id="idToExclude" className="mb-10 d-flex align-items-center">
              <div
                className="cursor-pointer mt-2 mr-5"
                onClick={() => navigate(-1)}
              >
                <BackArrowIcon height="12" width="20" fill="#3e403f" />
              </div>

              <Breadcrumb
                items={[
                  {
                    title: "Home",
                    href: `#`,
                    onClick: () => navigate(prepareRoutes("home")),
                  },
                  {
                    title: `${pmExternal?.project.name || "-"}`,
                    href: "#",
                    onClick: () => navigate(prepareRoutes("projects")),
                  },
                  {
                    title: `${pmExternal?.subProject.name || "-"}`,
                    href: "#",
                    onClick: () => navigate(prepareRoutes("subproject")),
                  },
                  {
                    title: `${
                      !isEmpty(pmExternal) ? pmExternal.workTitle : "-"
                    }`,
                  },
                ]}
              />
            </div>
          )}
          <Tabs
            tabBarExtraContent={<span>{getActionButton()}</span>}
            items={items}
            defaultActiveKey="details"
            activeKey={activeTab}
            onChange={onChangeTab}
          />
        </Col>
        <>
          {!pmExternal?.isFuture ? (
            <DraggableButton
              details={pmExternal}
              showComments={showComments}
              onShowComments={setShowComments}
              referenceId={pmId || ""}
              reference_type={
                CommentReferenceEnum[
                  pmType as keyof typeof CommentReferenceEnum
                ]
              }
              subProjectId={subProjectId || ""}
              showTitleAndCount
              isDetailPage
              count={Number(pmExternal?.comments) || 0}
              callback={() => getPmExternalDetails(pmId || "")}
            />
          ) : null}
        </>
      </Row>
    </div>
  );
};

export default WorkOrderPMDetails;
