import DayJs from "dayjs";
import { Badge, Card, Col, Row, Space, Spin, Typography } from "antd";
import { CountInColCard } from "components/shared/custom-cards/count-card";
import AlectifyBarChart from "components/shared/chart";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import {
  fetchClosedWorkOrders,
  fetchClosedWorkOrdersByAssignees,
  fetchPrevMaintenanceCounts,
  fetchWorkOrdersSummary,
} from "services/pm-internal/pm-internal.service";
import {
  IDashboardPlatformSummary,
  IDashboardResponse,
  IPmState,
} from "services/dashboard/dashboard.interface";
import {
  fetchDashboardOverDueWorkOrdersDetails,
  fetchPlatformSummary,
} from "services/dashboard/dashboard.service";
import {
  openWorkOrdersStatisticsOptions,
  barOverDueWorkChartOptions,
  overDueWorkChartSeries,
  overDueWorkOrdersOptions,
  overDueWorkOrdersSeries,
  openWorkOrdersStatisticsSeriesExternal,
  openWorkOrdersStatisticsOptionsV2,
  openWorkOrdersStatisticsSeriesV2,
  closedWorkOrderOptions,
  recurringChartOptions,
  openWorkOrderOptions,
  getSparePartsUtilizationChartOptions,
  getSparePartsUtilizationChartSeries,
  recurringVerticalChartOptions,
} from "./sources";
import "./Dashboard.styles.scss";
import {
  enumToTile,
  formatDate,
  getFullUserName,
  isExternalUser,
  renderAmount,
} from "utils/helpers";
import { SERVER_DATE_FORMAT } from "constants/index";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { isEmpty } from "lodash";
import DashboardWorkOrders from "components/shared/dashboard-work-orders";
import {
  MaintenanceCategoriesEnum,
  PM_STATUS,
  TaskCategoriesEnum,
} from "enums";
import {
  DueDateDelayFiltersEnum,
  DueDateDelayFiltersStringToEnum,
} from "redux/components/pm-external";
import ReactApexChart from "react-apexcharts";
import {
  fetchSparePartDashboardMonthlyCost,
  fetchSparePartDashboardStats,
} from "services/spare-parts/spare-parts.service";
import {
  ISparePartDashboardMonthlyCost,
  ISparePartDashboardStats,
} from "services/spare-parts/spare-parts.interface";
import moment from "moment";
import {
  IClosedWorkOrderData,
  IClosedWorkOrdersByAssignees,
  IWorkOrderSummaryResponse,
} from "services/pm-internal/pm-internal.interface";
import AlectifyTable from "components/shared/table";
import SparePartsTable from "components/shared/spare-parts-table";
import { WORK_ORDER_CATEGORY_COLORS } from "utils/constants";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import { resetTableFilterState } from "redux/components/table-filters/sources";

const DashboardStatistics: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pmState, setPmState] = useState<IPmState>({
    taskCounts: null,
    taskCountsWithDateFilter: null,
    pmCounts: null,
    pmCountsWithDateFilter: null,
    loading: false,
  });
  const [platformSummary, setPlatformSummary] =
    useState<IDashboardPlatformSummary>();
  const [workOrdersSummary, setWorkOrdersSummary] =
    useState<IWorkOrderSummaryResponse>();
  const [closedWorkOrdersByAssignees, setClosedWorkOrdersByAssginees] =
    useState<IClosedWorkOrdersByAssignees[]>();
  const [closedWorkOrders, setClosedWorkOrders] =
    useState<IClosedWorkOrderData>();
  const [sparePartsDashboardStats, setSparePartsDashboardStats] =
    useState<ISparePartDashboardStats>();
  const [sparePartsDashboardMonthlyCost, setSparePartsDashboardMonthlyCost] =
    useState<ISparePartDashboardMonthlyCost[]>();

  const [overdueWorkOrderDetails, setOverdueWorkOrderDetails] =
    useState<IDashboardResponse>();

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const { auth } = useSelector((auth: IRootState) => auth);

  const getPlatformSummary = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchPlatformSummary(params);
      setPlatformSummary(response.data);
    } catch (error) {}
  };

  const getWorkOrdersSummary = async () => {
    try {
      const response = await fetchWorkOrdersSummary(
        activeMasterProject?.id || "all",
        "all",
      );
      setWorkOrdersSummary(response);
    } catch (error) {}
  };

  const getClosedWorkOrdersByAssignees = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchClosedWorkOrdersByAssignees(params);
      setClosedWorkOrdersByAssginees(response.data);
    } catch (error) {}
  };

  const getClosedWorkOrders = async () => {
    try {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const response = await fetchClosedWorkOrders(params);
      setClosedWorkOrders(response.data);
    } catch (error) {}
  };

  const getPmCountDetails = async (filters?: any[]) => {
    try {
      setPmState((prevState: IPmState) => ({
        ...prevState,
        loading: true,
      }));
      const currentDate = DayJs();
      const startOfMonth = currentDate.startOf("month");
      const formattedStartDate = formatDate(startOfMonth, SERVER_DATE_FORMAT);
      const lastDayOfMonth = currentDate.endOf("month");
      const formattedEndDate = lastDayOfMonth.format("YYYY-MM-DD");

      const params = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectIds: activeMasterProject?.id ? [activeMasterProject.id] : [],
        teamMembers: !isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
        createdById: !isExternalUser(auth?.user) ? auth?.user?.id : undefined,
      };

      const paramsForWithoutDateFilter = {
        projectIds: activeMasterProject?.id ? [activeMasterProject.id] : [],
        assignees: isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
        createdById: !isExternalUser(auth?.user) ? auth?.user?.id : undefined,
        teamMembers: !isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
        // approvers: !isExternalUser(auth?.user) ? [auth?.user?.id] : undefined,
      };

      const [responseWithDateFilter, responseWithoutDateFilter] =
        await Promise.all([
          fetchPrevMaintenanceCounts("all", "all", params),
          fetchPrevMaintenanceCounts("all", "all", paramsForWithoutDateFilter),
        ]);
      setPmState((prevState) => ({
        ...prevState,
        taskCounts: responseWithoutDateFilter.data,
        taskCountsWithDateFilter: responseWithDateFilter.data,
        pmCounts: responseWithoutDateFilter.data,
        pmCountsWithDateFilter: responseWithDateFilter.data,
        loading: false,
      }));
    } catch (error) {
      setPmState((prevState: IPmState) => ({
        ...prevState,
        loading: false,
      }));
      console.log(error);
    }
  };
  const getDashboardOverDueWorkOrdersDetails = async (ids?: string[]) => {
    const params = {
      projectIds: activeMasterProject?.id ? [activeMasterProject.id] : [],
    };
    try {
      const response: IDashboardResponse =
        await fetchDashboardOverDueWorkOrdersDetails(params);
      setOverdueWorkOrderDetails(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getSparePartsStats = async () => {
    try {
      // if (config.REACT_APP_ENV !== AppEnvEnum.PRODUCTION) {
      const params = {
        projectId: activeMasterProject?.id,
      };
      const [dashboardStats, monthlyCounts] = await Promise.all([
        fetchSparePartDashboardStats(params),
        fetchSparePartDashboardMonthlyCost(params),
      ]);
      setSparePartsDashboardStats(dashboardStats.data);
      setSparePartsDashboardMonthlyCost(
        monthlyCounts.data?.splice(0, moment().month() + 1),
      );
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const openSparePartsModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1400,
      name: MODAL_NAMES.DASHBOARD_SPARE_PARTS_MODAL,
      title: "Spare Pats as of Today",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_SPARE_PARTS_MODAL,
        });
      },
      footer: null,
      children: <SparePartsTable showAction={false} />,
    });
  };
  const openWorkOrdersOnTimeVsDelayed = (userId?: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      title: AlectifyText.SCHEDULED_WORK_ORDERS_STATUS_BY_ASSIGNEE,
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
      width: 1400,
      footer: null,
      className: "alectify-pdf-viewer-container",
      onCancel: () => {
        dispatch(resetTableFilterState());
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
        });
      },
      children: (
        <DashboardWorkOrders
          type="BY_ASSIGNEES"
          defaultParams={{
            status: [PM_STATUS.PENDING],
            assignees: [
              userId || overdueWorkOrderDetails?.data.openItems?.[0]?.id,
            ],
          }}
          filters={{
            key: "assignees",
            options: overdueWorkOrderDetails?.data.openItems?.map((user) => ({
              label: getFullUserName(user),
              value: user.id,
            })),
          }}
        />
      ),
    });
  };
  useEffect(() => {
    Promise.all([
      getPlatformSummary(),
      getWorkOrdersSummary(),
      getClosedWorkOrdersByAssignees(),
      getClosedWorkOrders(),
      getPmCountDetails(),
      getDashboardOverDueWorkOrdersDetails(),
      getSparePartsStats(),
    ]);
  }, [activeMasterProject?.id]);

  const openWorkOrdersModal = (
    defaultParams: any = {},
    status?: PM_STATUS,
    pmType?: string,
  ) => {
    const statusToParams =
      status?.toUpperCase() === "SCHEDULED"
        ? PM_STATUS.PENDING
        : status?.toUpperCase();
    ModalServiceInstance.open(AlectifyModal, {
      title:
        status === PM_STATUS.COMPLETED
          ? "Closed Work Orders"
          : AlectifyText.OPEN_WORK_ORDERS_STATISTICS,
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
      width: 1400,
      style: { top: 30 },
      footer: null,
      className: "alectify-pdf-viewer-container",
      onCancel: () => {
        dispatch(resetTableFilterState());
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
        });
      },
      children: (
        <DashboardWorkOrders
          defaultParams={{
            status: status
              ? [statusToParams?.toUpperCase()]
              : [
                  PM_STATUS.PENDING,
                  PM_STATUS.WAITING_FOR_REVIEW,
                  PM_STATUS.DENIED,
                ],
            ...defaultParams,
            pmType: pmType === "Task" ? "TASK" : undefined,
            taskCategory:
              (pmType === "PM"
                ? MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                : pmType === "CM"
                ? MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                : pmType?.toUpperCase()) || undefined,
          }}
          filters={{
            key: "taskCategory",
            defaultValue:
              (pmType === "PM"
                ? MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
                : pmType === "CM"
                ? MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                : pmType?.toUpperCase()) || undefined,
            options: [
              {
                label: `All`,
                value: "all",
              },
              ...Object.values(TaskCategoriesEnum).map((category) => ({
                label: enumToTile(category),
                value: category,
              })),
            ],
          }}
        />
      ),
    });
  };

  const openWorkOrderRecurrenceModal = (category?: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      title: "Pending (Scheduled & In Progress)",
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
      width: 1400,
      footer: null,
      className: "alectify-pdf-viewer-container",
      onCancel: () => {
        dispatch(resetTableFilterState());
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
        });
      },
      children: (
        <DashboardWorkOrders
          defaultParams={{
            status: [PM_STATUS.PENDING],
            dueDate: category
              ? DueDateDelayFiltersStringToEnum[
                  category as keyof typeof DueDateDelayFiltersStringToEnum
                ]
              : DueDateDelayFiltersEnum.ON_TIME,
          }}
          filters={{
            key: "dueDate",
            options: [
              {
                label: `On Time`,
                value: DueDateDelayFiltersEnum.ON_TIME,
              },
              {
                label: `< 7 Days`,
                value: DueDateDelayFiltersEnum.SEVEN_DAYS_AGO,
              },
              {
                label: `7 to 14 Days`,
                value: DueDateDelayFiltersEnum.SEVEN_TO_14_DAYS_AGO,
              },
              {
                label: `14+ Days`,
                value: DueDateDelayFiltersEnum.MORE_THAN_14_DAYS_AGO,
              },
            ],
          }}
        />
      ),
    });
  };
  const gridStyle: React.CSSProperties = {
    width: "50%",
    padding: "13px",
  };

  return (
    <div>
      {!isExternalUser(auth.user) ? (
        <Spin spinning={pmState.loading}>
          <h1>{AlectifyText.PLATFORM_SUMMARY}</h1>
          <Row gutter={16} className="mt-20 mb-20">
            <Col span={24}>
              <div>
                <Row gutter={[16, 16]} className="m-10">
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.SITE}
                      count={platformSummary?.totalSites || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.ASSETS}
                      count={platformSummary?.totalAssets || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.PROCEDURES}
                      count={platformSummary?.totalProcedures || 0}
                    />
                  </Col>
                  <Col span={6}>
                    <CountInColCard
                      title={AlectifyText.DOCUMENTS}
                      count={platformSummary?.totalDocuments || 0}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <h1>Work Orders Summary</h1>
          <Row gutter={[16, 16]} className="mt-20 mb-40">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              onClick={() => {
                // dispatch(
                //   setTableFilters({
                //     statusShifterButtons: [
                //       "PENDING",
                //       "WAITING FOR REVIEW",
                //       "DENIED",
                //     ],
                //     filterType: "OPEN",
                //   }),
                // );
                navigate(`${ROUTES.ALL_WORK_ORDERS}?page=all`);
              }}
            >
              <div className="cards-body">
                <h2 className="stat-headings-clickable ">
                  {`${AlectifyText.OPEN}`}
                </h2>
                <div className="chart-container active-card-hover">
                  <ReactApexChart
                    options={openWorkOrderOptions("open", dispatch)}
                    series={[
                      (pmState.pmCounts?.task?.pending || 0) +
                        (pmState?.pmCounts?.pmExternal?.pending || 0),
                      (pmState?.pmCounts?.task?.waitingForReview || 0) +
                        (pmState?.pmCounts?.pmExternal?.waitingForReview || 0),
                      (pmState?.pmCounts?.pmExternal?.denied || 0) +
                        (pmState?.pmCounts?.task?.denied || 0),
                    ]}
                    type="donut"
                    width={"100%"}
                    height={440}
                  />
                </div>
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={6}
              onClick={() => {
                // dispatch(
                //   setTableFilters({
                //     statusShifterButtons: ["COMPLETED", "SKIPPED"],
                //     filterType: "PAST",
                //   }),
                // );
                navigate(`${ROUTES.ALL_WORK_ORDERS}?page=all`);
              }}
            >
              <div className="cards-body">
                <h2 className="stat-headings-clickable">{`Past`}</h2>
                <div className="chart-container chart-container1">
                  <ReactApexChart
                    options={openWorkOrderOptions("past", dispatch)}
                    series={[
                      (pmState.pmCounts?.pmExternal?.completed || 0) +
                        (pmState.pmCounts?.task?.completed || 0),
                      (pmState.pmCounts?.pmExternal?.skipped || 0) +
                        (pmState.pmCounts?.task?.skipped || 0),
                    ]}
                    type="donut"
                    width={"100%"}
                    height={440}
                  />
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <div
                className="cards-body"
                /*   onClick={() => {
                  ModalServiceInstance.open(AlectifyModal, {
                    title: "Pending (Scheduled & In Progress)",
                    name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                    width: 1400,
                    footer: null,
                    className: "alectify-pdf-viewer-container",
                    onCancel: () => {
                      dispatch(resetTableFilterState());
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
                      });
                    },
                    children: (
                      <DashboardWorkOrders
                        defaultParams={{
                          status: [PM_STATUS.PENDING],
                          dueDate: DueDateDelayFiltersEnum.ON_TIME,
                        }}
                        filters={{
                          key: "dueDate",
                          options: [
                            {
                              label: `On Time`,
                              value: DueDateDelayFiltersEnum.ON_TIME,
                            },
                            {
                              label: `< 7 Days`,
                              value: DueDateDelayFiltersEnum.SEVEN_DAYS_AGO,
                            },
                            {
                              label: `7 to 14 Days`,
                              value:
                                DueDateDelayFiltersEnum.SEVEN_TO_14_DAYS_AGO,
                            },
                            {
                              label: `14+ Days`,
                              value:
                                DueDateDelayFiltersEnum.MORE_THAN_14_DAYS_AGO,
                            },
                          ],
                        }}
                      />
                    ),
                  });
                }} */
              >
                <h2
                  className="stat-headings-clickable"
                  onClick={() => openWorkOrderRecurrenceModal()}
                >
                  On Time vs Delayed (For Scheduled)
                </h2>

                {overdueWorkOrderDetails?.data?.openItems &&
                overdueWorkOrderDetails.data.openItems.length > 0 ? (
                  <AlectifyBarChart
                    options={barOverDueWorkChartOptions((category: string) => {
                      openWorkOrderRecurrenceModal(category);
                    })}
                    series={overDueWorkChartSeries(
                      overdueWorkOrderDetails.data,
                    )}
                    type="bar"
                    height={330}
                  />
                ) : (
                  <AlectifyBarChart
                    options={barOverDueWorkChartOptions()}
                    series={[{ name: "No Data", data: [] }]}
                    type="bar"
                    height={300}
                  />
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <div className="cards-body">
                <h2 className="stat-headings">
                  {AlectifyText.RECURRENCE_FOR_OPEN}
                </h2>
                <ReactApexChart
                  type="bar"
                  height={332}
                  options={recurringVerticalChartOptions("") as any}
                  series={[
                    {
                      name: "Recurring",
                      data: [
                        (workOrdersSummary?.data?.PREVENTIVE_MAINTENANCE
                          ?.recurring || 0) +
                          (workOrdersSummary?.data?.CORRECTIVE_MAINTENANCE
                            ?.recurring || 0) +
                          (workOrdersSummary?.data?.TASK?.recurring || 0) +
                          (workOrdersSummary?.data?.DAMAGE?.recurring || 0) +
                          (workOrdersSummary?.data?.INSPECTION?.recurring ||
                            0) +
                          (workOrdersSummary?.data?.SAFETY?.recurring || 0),
                        0,
                      ],
                    },
                    {
                      name: "Non-Recurring",
                      data: [
                        0,
                        (workOrdersSummary?.data?.PREVENTIVE_MAINTENANCE
                          ?.nonRecurring || 0) +
                          (workOrdersSummary?.data?.CORRECTIVE_MAINTENANCE
                            ?.nonRecurring || 0) +
                          (workOrdersSummary?.data?.TASK?.nonRecurring || 0) +
                          (workOrdersSummary?.data?.DAMAGE?.nonRecurring || 0) +
                          (workOrdersSummary?.data?.INSPECTION?.nonRecurring ||
                            0) +
                          (workOrdersSummary?.data?.SAFETY?.nonRecurring || 0),
                      ],
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
          <h1>{AlectifyText.WORK_ORDER_TYPE_STATISTIC}</h1>
          <Row gutter={[16, 16]}>
            <Col span={7}>
              <div className="cards-body">
                <h2
                  className="stat-headings-clickable"
                  onClick={() => openWorkOrdersModal()}
                >
                  {AlectifyText.BY_STATUS_FOR_OPEN}
                </h2>

                <AlectifyBarChart
                  type="bar"
                  height={340}
                  options={openWorkOrdersStatisticsOptionsV2(
                    (status: string, category: string) => {
                      openWorkOrdersModal(null, status as any, category);
                    },
                  )}
                  series={openWorkOrdersStatisticsSeriesV2(workOrdersSummary)}
                />
              </div>
            </Col>
            <Col span={7}>
              <div className="cards-body">
                <h2 className="stat-headings">
                  {AlectifyText.BY_RECURRENCE_FOR_OPEN}
                </h2>
                <ReactApexChart
                  options={recurringChartOptions("PM") as any}
                  height={340}
                  series={[
                    {
                      name: "PM",
                      data: [
                        workOrdersSummary?.data?.PREVENTIVE_MAINTENANCE
                          ?.recurring || null,
                        workOrdersSummary?.data?.PREVENTIVE_MAINTENANCE
                          ?.nonRecurring || null,
                      ],
                    },
                    {
                      name: "CM",
                      data: [
                        workOrdersSummary?.data?.CORRECTIVE_MAINTENANCE
                          ?.recurring || null,
                        workOrdersSummary?.data?.CORRECTIVE_MAINTENANCE
                          ?.nonRecurring || null,
                      ],
                    },
                    {
                      name: "Task",
                      data: [
                        workOrdersSummary?.data?.TASK?.recurring || null,
                        workOrdersSummary?.data?.TASK?.nonRecurring || null,
                      ],
                    },
                    {
                      name: "Damage",
                      data: [
                        workOrdersSummary?.data?.DAMAGE?.recurring || null,
                        workOrdersSummary?.data?.DAMAGE?.nonRecurring || null,
                      ],
                    },
                    {
                      name: "Inspection",
                      data: [
                        workOrdersSummary?.data?.INSPECTION?.recurring || null,
                        workOrdersSummary?.data?.INSPECTION?.nonRecurring ||
                          null,
                      ],
                    },
                    {
                      name: "Safety",
                      data: [
                        workOrdersSummary?.data?.SAFETY?.recurring || null,
                        workOrdersSummary?.data?.SAFETY?.nonRecurring || null,
                      ],
                    },
                    {
                      name: "Deficiency",
                      data: [
                        workOrdersSummary?.data?.DEFICIENCY?.recurring || null,
                        workOrdersSummary?.data?.DEFICIENCY?.nonRecurring ||
                          null,
                      ],
                    },
                  ]}
                  type="bar"
                  width={"100%"}
                />
              </div>
            </Col>
            <Col span={10}>
              <div className="cards-body">
                <h2 className="stat-headings">
                  {AlectifyText.PAST_WORK_ORDERS}
                </h2>
                <Space>
                  <Badge
                    text={<span className="font-size-12">PM</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.PREVENTIVE_MAINTENANCE}
                  />
                  <Badge
                    text={<span className="font-size-12">CM</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.CORRECTIVE_MAINTENANCE}
                  />
                  <Badge
                    text={<span className="font-size-12">Task</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.TASK}
                  />
                  <Badge
                    text={<span className="font-size-12">Damage</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.DAMAGE}
                  />
                  <Badge
                    text={<span className="font-size-12">Inspection</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.INSPECTION}
                  />
                  <Badge
                    text={<span className="font-size-12">Safety</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.SAFETY}
                  />
                  <Badge
                    text={<span className="font-size-12">Deficiency</span>}
                    color={WORK_ORDER_CATEGORY_COLORS.DEFICIENCY}
                  />
                </Space>
                <div className="cards-bottom">
                  <Row className="mt-10">
                    <Col span={8}>
                      <div>
                        <ReactApexChart
                          options={closedWorkOrderOptions("PM") as any}
                          series={[
                            closedWorkOrders?.PREVENTIVE_MAINTENANCE
                              ?.completedThisWeek || 0,
                            closedWorkOrders?.CORRECTIVE_MAINTENANCE
                              ?.completedThisWeek || 0,
                            closedWorkOrders?.TASK?.completedThisWeek || 0,
                            // closedWorkOrders?.CLEANUP?.completedThisWeek || 0,
                            // closedWorkOrders?.REPLACEMENT?.completedThisWeek || 0,
                            closedWorkOrders?.DAMAGE?.completedThisWeek || 0,
                            closedWorkOrders?.INSPECTION?.completedThisWeek ||
                              0,
                            closedWorkOrders?.SAFETY?.completedThisWeek || 0,
                            closedWorkOrders?.DEFICIENCY?.completedThisWeek ||
                              0,
                            // closedWorkOrders?.OTHERS?.completedThisWeek || 0,
                          ]}
                          type="donut"
                          width={"100%"}
                          // height={280}
                        />
                        <div className="donut-chart-name">This Week</div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <ReactApexChart
                        options={closedWorkOrderOptions("CM") as any}
                        series={[
                          closedWorkOrders?.PREVENTIVE_MAINTENANCE
                            ?.completedThisMonth || 0,
                          closedWorkOrders?.CORRECTIVE_MAINTENANCE
                            ?.completedThisMonth || 0,
                          closedWorkOrders?.TASK?.completedThisMonth || 0,
                          // closedWorkOrders?.CLEANUP?.completedThisMonth || 0,
                          // closedWorkOrders?.REPLACEMENT?.completedThisMonth || 0,
                          closedWorkOrders?.DAMAGE?.completedThisMonth || 0,
                          closedWorkOrders?.INSPECTION?.completedThisMonth || 0,
                          closedWorkOrders?.SAFETY?.completedThisMonth || 0,
                          closedWorkOrders?.DEFICIENCY?.completedThisMonth || 0,
                          // closedWorkOrders?.OTHERS?.completedThisMonth || 0,
                        ]}
                        type="donut"
                        width={"100%"}
                        // height={280}
                      />
                      <div className="donut-chart-name">This Month</div>
                    </Col>
                    <Col span={8}>
                      <ReactApexChart
                        options={closedWorkOrderOptions("Task") as any}
                        series={[
                          closedWorkOrders?.PREVENTIVE_MAINTENANCE
                            ?.completedSinceInception || 0,
                          closedWorkOrders?.CORRECTIVE_MAINTENANCE
                            ?.completedSinceInception || 0,
                          closedWorkOrders?.TASK?.completedSinceInception || 0,
                          // closedWorkOrders?.CLEANUP?.completedSinceInception || 0,
                          // closedWorkOrders?.REPLACEMENT
                          //   ?.completedSinceInception || 0,
                          closedWorkOrders?.DAMAGE?.completedSinceInception ||
                            0,
                          closedWorkOrders?.INSPECTION
                            ?.completedSinceInception || 0,
                          closedWorkOrders?.SAFETY?.completedSinceInception ||
                            0,
                          closedWorkOrders?.DEFICIENCY
                            ?.completedSinceInception || 0,
                          // closedWorkOrders?.OTHERS?.completedSinceInception || 0,
                        ]}
                        type="donut"
                        width={"100%"}
                        // height={280}
                      />
                      <div className="donut-chart-name">Since Inception</div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <h1>{AlectifyText.ASSIGNEE_STATISTIC}</h1>
          <Row gutter={16}>
            <Col span={12}>
              <div className="cards-body">
                <h2
                  className="stat-headings-clickable"
                  onClick={() => {
                    openWorkOrdersOnTimeVsDelayed();
                  }}
                >
                  Top 10 on Time v/s Delayed (For Scheduled)
                </h2>
                <div>
                  <AlectifyBarChart
                    options={overDueWorkOrdersOptions(
                      overdueWorkOrderDetails?.data,
                      (userId: string) => {
                        openWorkOrdersOnTimeVsDelayed(userId);
                      },
                    )}
                    series={overDueWorkOrdersSeries(
                      overdueWorkOrderDetails?.data.openItems,
                    )}
                    type="bar"
                    height={330}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                className="cards-body"
                onClick={() => openWorkOrdersModal({}, PM_STATUS.COMPLETED)}
              >
                <h2 className="stat-headings-clickable">
                  Closed Work Orders By Assignees (Completed By)
                </h2>
                <AlectifyTable
                  bordered={false}
                  columns={[
                    {
                      title: "User",
                      dataIndex: "completedBy",
                      render: (_, record) =>
                        getFullUserName(record.completedBy),
                    },
                    { title: "This Week", dataIndex: "completedThisWeek" },
                    { title: "This Month", dataIndex: "completedThisMonth" },
                    {
                      title: "Since Inception",
                      dataIndex: "completedSinceInception",
                    },
                  ]}
                  dataSource={closedWorkOrdersByAssignees || []}
                  loading={false}
                  onDataFetch={() => {}}
                  total={closedWorkOrdersByAssignees?.length || 0}
                  showPagination={false}
                  scroll={{ y: 280 }}
                />
              </div>
            </Col>
          </Row>
          <h1>Spare Parts</h1>
          <Row gutter={30} className="mb-30">
            <Col span={10}>
              <div className="cards-body" onClick={() => openSparePartsModal()}>
                <h2 className="stat-headings-clickable">
                  Spare Parts as of Today
                </h2>
                <Card hoverable={false} className="spare-parts-stats-card">
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title># Low Inventory</Typography.Title>
                    <Typography.Text className="spare-parts-stats-card-value">
                      {sparePartsDashboardStats?.lowInventoryCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title># Out of Stock</Typography.Title>
                    <Typography.Text>
                      {sparePartsDashboardStats?.outOfStockCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      Available Spare Parts Counts
                    </Typography.Title>
                    <Typography.Text>
                      {sparePartsDashboardStats?.totalCount || 0}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      # Available Spart Parts Value
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardStats?.currentYearCost || 0,
                      )}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      Total Receive $$ (in 2024)
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardMonthlyCost?.reduce(
                          (accum, item) => accum + (item.restockTotal || 0),
                          0,
                        ),
                      )}
                    </Typography.Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={gridStyle}>
                    <Typography.Title>
                      # Total Draw $$ (in 2024)
                    </Typography.Title>
                    <Typography.Text>
                      {renderAmount(
                        sparePartsDashboardMonthlyCost?.reduce(
                          (accum, item) => accum + (item.drawTotal || 0),
                          0,
                        ),
                      )}
                    </Typography.Text>
                  </Card.Grid>
                </Card>
              </div>
            </Col>
            <Col span={14}>
              <div className="cards-body">
                <h2 className="stat-headings-v2">
                  Spare Parts Utilization (in {new Date().getFullYear()})
                </h2>
                {!isEmpty(sparePartsDashboardMonthlyCost) && (
                  <ReactApexChart
                    options={
                      getSparePartsUtilizationChartOptions(
                        sparePartsDashboardMonthlyCost as any,
                      ) as any
                    }
                    series={getSparePartsUtilizationChartSeries(
                      sparePartsDashboardMonthlyCost as any,
                    )}
                    type="line"
                    height={350}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      ) : (
        <Spin spinning={pmState.loading}>
          <Row gutter={16} className="mt-20">
            <Col span={12}>
              <div className="cards-body-external">
                <h2 className="stat-headings">
                  {AlectifyText.OPEN_WORK_ORDERS}
                </h2>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CountInColCard
                      title={AlectifyText.PM_EXTERNAL}
                      count={
                        (pmState?.pmCounts?.pmExternal?.pending || 0) +
                        (pmState?.pmCounts?.pmExternal?.waitingForReview || 0)
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <CountInColCard
                      title={AlectifyText.TASKS}
                      count={
                        (pmState?.pmCounts?.task?.pending || 0) +
                        (pmState?.pmCounts?.task?.waitingForReview || 0)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={12}>
              <div className="cards-body-external">
                <h2 className="stat-headings">
                  {AlectifyText.OPEN_WORK_ORDERS_STATISTICS}
                </h2>

                <AlectifyBarChart
                  type="bar"
                  height={330}
                  options={openWorkOrdersStatisticsOptions()}
                  series={openWorkOrdersStatisticsSeriesExternal(pmState)}
                />
              </div>
            </Col>
          </Row>
        </Spin>
      )}
    </div>
  );
};

export default DashboardStatistics;
