export const GET_DATA_ROUND_PROJECT =
  "/operator-rounds/masterprojects/{masterProjectId}/dr-projects/";
export const CREATE_DR_PROJECT = "/operator-rounds/dr-projects/";
export const UPDATE_DR_PROJECT = "/operator-rounds/dr-projects/{projectId}/";
export const GET_DR_PROJECT_DETAILS =
  "/operator-rounds/dr-projects/{projectId}/";
export const GET_ALL_GROUPS_OF_DR_PROJECT =
  "/operator-rounds/dr-projects/{projectId}/area/";
export const CREATE_NEW_GROUP =
  "/operator-rounds/dr-projects/{projectId}/area/";
export const UPDATE_GROUP =
  "/operator-rounds/dr-projects/{projectId}/area/{areaId}/";
export const GET_GROUP_AND_CHECKPOINT_LISTING =
  "/operator-rounds/dr-projects/{projectId}/area-checkpoint/";
export const CREATE_NEW_CHECKPOINT =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/";
export const UPDATE_CHECKPOINT =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/{checkpointId}/";
export const RESET_CHECKPOINT_GROUP_ORDER =
  "/operator-rounds/dr-projects/{projectId}/area-checkpoint/reset-order/";
export const DATA_ROUND_INSIGHTS =
  "/operator-rounds/dr-projects/{projectId}/round-insights/datewise/";
export const GET_ROUND_DETAILS =
  "/operator-rounds/dr-projects/{projectId}/round/{roundId}/insights/";
export const GET_DATA_ROUND_ANALYTICS =
  "/operator-rounds/dr-projects/{projectId}/round/checkpoint/reading-analytics/";
export const GET_ALL_CHECKPOINTS =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/";
export const GET_SHIFT_REPORT =
  "/operator-rounds/dr-projects/{projectId}/round/reports/date-shift/";
export const GET_SHIFT_REPORT_ON_RANGE =
  "/operator-rounds/dr-projects/{projectId}/round/reports/date-range/";
export const GET_DATE_WISE_STATS =
  "/operator-rounds/dr-projects/{projectId}/checkpoint-round/reports/datewise-stats/";
export const GET_ROUNDS_DOC_TIME_LINE =
  "/operator-rounds/dr-projects/{dr_project_id}/checkpoint-round/{checkpoint_round_id}/documents/";
export const GET_ROUNDS_COMMENT_TIME_LINE =
  "/operator-rounds/dr-projects/{dr_project_id}/checkpoint-round/{checkpoint_round_id}/comments/";

export const GET_ROUNDS_HISTORY_TIME_LINE =
  "/operator-rounds/dr-projects/{dr_project_id}/checkpoint/{checkpoint_round_id}/insights/datewise/";

export const GET_CHECKPOINT_DETAIL =
  "/operator-rounds/dr-projects/{dr_project_id}/checkpoint/{checkpoint_id}/";

export const GET_DATA_ROUND_REPORT = "/analytics/dr-projects/{dr_project_id}/";

export const GET_SITE_REPORT = "/agents/monthly-site-report";
