import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Col,
  DatePicker,
  Row,
  Skeleton,
  Spin,
  Timeline,
} from "antd";
import AlectifyText from "static/texts.json";
import { ROUTES } from "routes/Routes.constants";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  getCheckpointDetail,
  getRoundsHistory,
} from "services/data-round/data-round.service";
import { CheckpointData, CheckpointResponse, IShift } from "./Rounds.interface";
import { IRangeState } from "../dr-project-details/DataRoundProjectDetails.interface";
import { RangePickerProps } from "antd/es/date-picker";
import RoundsHistoryTimeline from "./RoundsHistoryTimeline";
import "./roundsHistory.style.scss";
import logger from "utils/logger";

const RoundsHistory: React.FC = (props) => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { dr_project_id, checkpoint_id } = useParams();
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const [shifts, setShift] = useState({
    data: [],
    fetching: false,
  });

  const [checkpoint, setCheckpoint] = useState<CheckpointResponse>({
    data: {} as CheckpointData,
    fetching: false,
  });

  const [dateRangeState, setDateRangeState] = useState<IRangeState>({
    start_date: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (dateRangeState.start_date && dateRangeState.end_date) {
      fetchRoundsHistory();
    }
  }, [
    dr_project_id,
    checkpoint_id,
    dateRangeState.start_date,
    dateRangeState.end_date,
  ]);

  useEffect(() => {
    fetchCheckpointDetail();
  }, [dr_project_id, checkpoint_id]);

  const fetchCheckpointDetail = async () => {
    try {
      setCheckpoint({ ...checkpoint, fetching: true });

      if (dr_project_id && checkpoint_id) {
        const response = await getCheckpointDetail(
          dr_project_id,
          checkpoint_id,
        );
        setCheckpoint({ data: response.data[0], fetching: false });
      }
    } catch (err) {
      logger.error(err as Error);
      setCheckpoint({ ...checkpoint, fetching: false });
    }
  };

  const fetchRoundsHistory = async () => {
    try {
      setShift({ data: [], fetching: true });

      if (dr_project_id && checkpoint_id) {
        const params = {
          start_date: dateRangeState.start_date,
          end_date: dateRangeState.end_date,
        };
        const response = await getRoundsHistory(
          dr_project_id,
          checkpoint_id,
          params,
        );
        setShift({ data: response.data, fetching: false });
      }
    } catch (err) {
      setShift({ ...shifts, fetching: false });
      logger.error(err as Error);
    }
  };

  const onDateRangeSelect: RangePickerProps["onChange"] = (
    dates,
    dateStrings,
  ) => {
    setDateRangeState((prevState: IRangeState) => ({
      ...prevState,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    }));
  };

  return (
    <>
      <div className="mb-10">
        {/* <div className="cursor-pointer mt-2 mr-5" onClick={() => navigate(-1)}>
          <BackArrowIcon height="12" width="20" fill="#3e403f" />
        </div> */}

        <Breadcrumb
          items={[
            {
              title: AlectifyText.DATA_ROUND,
            },
            {
              title: activeMasterProject?.name,
              href: "#",
              onClick: () =>
                navigate(`${ROUTES.DATA_ROUND}/${activeMasterProject?.id}`),
            },
            {
              title: checkpoint?.data?.dr_project_name || "-",
              href: "#",
              onClick: () =>
                navigate(`${ROUTES.DATA_ROUND}/${activeMasterProject?.id}`),
            },

            { title: checkpoint?.data?.checkpoint_name || "-" },
          ]}
        />
      </div>

      <Row gutter={6} className="rounds-history-container">
        <Col span={6} className="detail-col">
          <Spin spinning={checkpoint.fetching}>
            <div className="fields-container">
              <Typography.Title
                level={4}
                className="title-summary-container pl-10"
              >
                {"Checkpoint Details"}
              </Typography.Title>
              <div className="field-row">
                <Row
                  justify={"space-between"}
                  align={"middle"}
                  gutter={[20, 20]}
                >
                  <Col span={10} className="mb-20">
                    {AlectifyText.CHECKPOINT_NAME}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {checkpoint?.data?.checkpoint_name || "N/A"}
                  </Col>
                  <Col span={10} className="mb-15">
                    {AlectifyText.GROUP}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {checkpoint?.data?.area_name || "N/A"}
                  </Col>

                  <Col span={10} className="mb-15">
                    {AlectifyText.OPERATING_RANGE}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {checkpoint?.data?.description || "N/A"}
                  </Col>

                  <Col span={10} className="mb-15">
                    {AlectifyText.DEVICE_STATUS_REQUIRED}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {Boolean(checkpoint?.data?.on_off_feature) ? "Yes" : "No"}
                  </Col>

                  <Col span={10} className="mb-15">
                    {AlectifyText.READING_REQUIRED}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {Boolean(checkpoint?.data?.is_reading_required)
                      ? "Yes"
                      : "No"}
                  </Col>

                  <Col span={10} className="mb-15">
                    {AlectifyText.TOTAL_ISSUE_RAISED}
                  </Col>
                  <Col
                    span={14}
                    className="text-align-right mb-15 alectify-key-value"
                  >
                    {checkpoint?.data?.total_issues_count || "N/A"}
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </Col>

        <Col span={18}>
          <div className="history-timeline-container">
            <Typography.Title level={4} className="title-summary-container">
              {AlectifyText.TIMELINE}
            </Typography.Title>

            <div>
              <RangePicker
                defaultValue={[dayjs().subtract(15, "day"), dayjs()]}
                className="round-range-picker-insight"
                onChange={onDateRangeSelect}
              />
            </div>
          </div>

          <Row>
            {shifts.fetching ? (
              <Skeleton />
            ) : (
              <div className="ml-10 width-100">
                <Col span={24} id="scrollableDiv">
                  <div className="pt-10 mr-0 timeline-container-history">
                    <Timeline mode="left" className="round-timeline">
                      {Array.isArray(shifts.data) &&
                        shifts.data.map((shift: IShift, index: number) => (
                          <Timeline.Item
                            key={index}
                            label={
                              <span className="shift-date">
                                {shift.shift_date}
                              </span>
                            }
                          >
                            <RoundsHistoryTimeline
                              shift={shift}
                              checkpoint={checkpoint.data}
                            />
                          </Timeline.Item>
                        ))}
                    </Timeline>
                  </div>
                </Col>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RoundsHistory;
