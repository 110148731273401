import { CloudDownloadOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Avatar, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { IUser } from "redux/components/Auth";
import { IRootState } from "redux/rootReducer";
import { downloadSingleFile } from "services/documents/documents.service";
import { displayDateTime, getFullUserName, getUserImage } from "utils/helpers";
import { ICommentItem } from "./Comments.interface";

const TimelineCommentItem: React.FC<{ comment: ICommentItem }> = ({
  comment,
}) => {
  const user = useSelector((state: IRootState) => state.auth.user);

  const getAuthor = (comment: ICommentItem) => {
    let userName = getFullUserName(comment?.sent_by as any);

    if (comment.is_system_generated) {
      userName = "SYSTEM MESSAGE";
    }

    if (comment?.is_system_generated) {
      return <span className="author-title system-author">SYSTEM MESSAGE</span>;
    }

    const body = `${userName} ${
      !comment?.is_system_generated && isCurrentUser(comment.sent_by)
        ? "(You)"
        : ""
    }`;

    return (
      <span
        className={`author-title ${
          comment?.is_system_generated ? "system-author" : ""
        }`}
      >
        {body}
      </span>
    );
  };

  const isCurrentUser = (author: IUser | undefined) => {
    return author?.id === user.id;
  };

  return (
    <div className="comment-item">
      <div className="sender-avatar">
        <Tooltip
          title={
            comment.is_system_generated
              ? "System Message"
              : getFullUserName(comment?.sent_by as any)
          }
        >
          <Avatar
            className={
              comment.is_system_generated ? "system-author-avatar" : ""
            }
            src={
              comment.is_system_generated ? (
                <InfoCircleFilled style={{ color: "blue", fontSize: "24px" }} />
              ) : (
                getUserImage(comment?.sent_by as any)
              )
            }
            alt={comment?.sent_by?.first_name}
          />
        </Tooltip>
      </div>
      <div className={`comment-content ${""}`}>
        <div className="comment-message">
          <Typography.Paragraph className="mb-0">
            {comment.is_system_generated ? (
              <>
                {getAuthor({
                  ...comment,
                  is_system_generated: false,
                })}
                :{" "}
              </>
            ) : (
              <></>
            )}
            {comment.text}
          </Typography.Paragraph>
        </div>
        {comment?.content_type === "attachment" && (
          <Tooltip
            title={!comment.is_system_generated && "Download"}
            placement="right"
          >
            {!comment.is_system_generated ? (
              <div
                className="comment-attachment d-flex text-ellipsis"
                onClick={() =>
                  downloadSingleFile(comment.s3_key, comment.file_name)
                }
              >
                <CloudDownloadOutlined className="icon-size-15" />
                <span className="ml-10">{comment.file_name}</span>
              </div>
            ) : (
              <span>{comment.file_name}</span>
            )}
          </Tooltip>
        )}

        <div className="date-text">
          {displayDateTime(comment.created_at, true)}
        </div>
      </div>
    </div>
  );
};

export default TimelineCommentItem;
