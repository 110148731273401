import { Col, DatePicker, Row, Spin, Typography } from "antd";
import {
  IDataRoundInsightsProps,
  IRangeState,
} from "../../DataRoundProjectDetails.interface";
import { CountInColCard } from "components/shared/custom-cards/count-card";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import {
  getDRProjectDetails,
  getDataRoundProjectInsights,
} from "services/data-round/data-round.service";
import {
  IDataRoundInsights,
  IDataRoundProject,
} from "services/data-round/data-round.interface";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DataRoundShiftTimeline from "./DataRoundShiftTimeline/DataRoundShiftTimeline";
import { RangePickerProps } from "antd/es/date-picker";
import "../../style.scss";

const { RangePicker } = DatePicker;

const DataRoundInsights: React.FC<IDataRoundInsightsProps> = (
  props: IDataRoundInsightsProps,
) => {
  const [insights, setInsights] = useState<IDataRoundInsights[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [project, setProject] = useState<IDataRoundProject | null>(null);
  const { projectId } = useParams();

  const [state, setState] = useState<IRangeState>({
    start_date: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const getInsights = async () => {
    setLoader(true);

    try {
      const params = {
        start_date: state.start_date,
        end_date: state.end_date,
      };
      const response = await getDataRoundProjectInsights(
        props.projectId,
        params,
      );
      if (response.success) {
        setInsights(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const getProjectDetails = async () => {
    setLoader(true);
    try {
      const response = await getDRProjectDetails(
        props.projectId || projectId || "",
      );
      setLoader(false);
      if (response.success) {
        setProject(response.data);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props.projectId) {
      Promise.all([getProjectDetails(), getInsights()]);
    }
  }, [props.projectId, state.start_date, state.end_date]);

  const onDateRangeSelect: RangePickerProps["onChange"] = (
    dates,
    dateStrings,
  ) => {
    setState((prevState: IRangeState) => ({
      ...prevState,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    }));
  };

  return (
    <Spin spinning={loader}>
      <Row
        justify={"start"}
        align={"middle"}
        className="data-round-timeline-container"
      >
        <Col span={24}>
          <Typography.Title level={4} className="mt-5">
            Assets & Lifecycle Summary
          </Typography.Title>
          <Row
            gutter={16}
            className="mt-10 mb-10"
            justify={"center"}
            align={"middle"}
          >
            <Col span={24}>
              <div>
                <Row gutter={[16, 16]} className="m-10">
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.CHECKPOINTS}
                      count={project?.total_checkpoints_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.GROUPS}
                      count={project?.total_areas_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.COMMENTS}
                      count={project?.total_comments_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.DOCUMENTS}
                      count={project?.total_documents_count || 0}
                    />
                  </Col>
                  <Col span={4}>
                    <CountInColCard
                      title={AlectifyText.ISSUES_REPORTED}
                      count={project?.total_issues_count || 0}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="timeline-container">
            <Typography.Title level={4} className="title-summary-container">
              {AlectifyText.TIMELINE}{" "}
            </Typography.Title>

            <RangePicker
              defaultValue={[dayjs().subtract(15, "day"), dayjs()]}
              className="round-range-picker-insight"
              onChange={onDateRangeSelect}
            />
          </div>
        </Col>

        <Col span={24}>
          <DataRoundShiftTimeline
            dateRange={state}
            insights={insights}
            project={project}
            loader={loader}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default DataRoundInsights;
