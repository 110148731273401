import React, { useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import "./style.scss";
import { cancelApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { IAsset } from "redux/components/assets";
import { fetchAssetsAiSuggestions } from "services/assets/assets.service";
import { isEmpty } from "lodash";
import AlectifyEmpty from "../empty/AlectifyEmpty";
import { IAI_RECOMMENDATIONS_PROPS } from "./AssetsTable.interface";

const AiRecommendations: React.FC<
  IAI_RECOMMENDATIONS_PROPS & { shouldFetchData?: boolean }
> = ({ defaultSummary = "", asset = {}, shouldFetchData = true }) => {
  const [aiSummary, setAiSummary] = useState({ data: "", loading: false });
  const fetchAiSummary = async (asset: IAsset) => {
    try {
      setAiSummary((prev) => ({ ...prev, loading: true }));
      const response = await fetchAssetsAiSuggestions(
        asset?.type === "PackageRoom",
        asset?.subproject_id,
        asset?.tag_id,
        asset?.package_id,
        SERVICE_UNIQUE_ID.UNIQUE_AI_ASSET_SUMMARY,
      );

      if (response?.success) {
        setAiSummary({ data: response.data.summary, loading: false });
      }
    } catch (error) {
      console.log(error);
      setAiSummary((prev) => ({ ...prev, loading: false }));
    } finally {
      setAiSummary((prev) => ({ ...prev, loading: false }));
    }
  };

  const sanitizedHtml = useMemo(() => {
    const content = defaultSummary || aiSummary.data || "";
    const cleanedHtml = content
      .replace(/```html\s*([\s\S]*?)\s*```/g, "$1") // Remove wrapping ```html and ```
      .trim();
    return cleanedHtml;
  }, [defaultSummary, aiSummary.data]);

  useEffect(() => {
    if (shouldFetchData) {
      fetchAiSummary(asset);
    }

    return () => cancelApiRequests(SERVICE_UNIQUE_ID.UNIQUE_AI_ASSET_SUMMARY);
  }, [asset?.id, shouldFetchData]);

  return (
    <div
      className={`ai-summary-container ${
        !isEmpty(defaultSummary) || !isEmpty(aiSummary.data)
          ? ""
          : "ai-summary-empty-centered"
      }`}
    >
      <Spin spinning={aiSummary.loading}>
        {!aiSummary.loading && (
          <>
            {isEmpty(defaultSummary) && isEmpty(aiSummary.data) ? (
              <>
                <AlectifyEmpty
                  description="No AI suggestions available"
                  className="dark-grey"
                />
              </>
            ) : (
              <div
                className="ai-summary-container"
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
              />
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default AiRecommendations;
