import { IAssetPackage } from "redux/components/asset-packages";
import {
  IIncidentReportCreateEditInitialState,
  IIncidentReportCreateEditProps,
} from "./IncidentReportCreateEdit.interface";
import DayJs from "dayjs";
import { IAsset } from "redux/components/assets";
import { IR_FIELD_NAMES } from "./IncidentReportCreateEdit.constants";
import { FormInstance } from "antd";
import { IncidentReports } from "services/incident-reports/incidnent-reports.interface";
import { isEmpty } from "lodash";

export const createIncidentReportPayload = (
  state: IIncidentReportCreateEditInitialState,
  values: any,
  props: IIncidentReportCreateEditProps,
) => {
  const formatDateIfValid = (date: any) => {
    return date && DayJs(date).isValid() ? DayJs(date).format() : undefined;
  };
  const teamInfo =
    state?.selectedMembertype === "users" && state.selectedTeamMembers
      ? {
          teamMembers: state?.selectedTeamMembers?.map(
            (member: any) => member.id,
          ),
        }
      : state?.selectedMembertype === "teams" && state.selectedTeamMembers
      ? { teamId: state?.selectedTeamId }
      : undefined;
  const payload = {
    ...values,
    ...teamInfo,
    emailToClient: true,
    title:
      state.selectedMasterProject?.site_code && !props.editing
        ? `${state.selectedMasterProject.site_code}: ${values.title}`
        : values.title,
    projectId: state.selectedMasterProject?.id,
    subProjectId: state.selectedSubProject?.id,
    priority: values.priority,
    areaIds: state.selectedAssetPackage?.map(
      (assetPackage: IAssetPackage) => assetPackage.package_id,
    ),
    assetIds: state.selectedAssets?.map((asset: IAsset) => asset.tag_id),
    incidentDate: formatDateIfValid(values[IR_FIELD_NAMES.DATE]),
    callAt: formatDateIfValid(values[IR_FIELD_NAMES.TIME_OF_CALL]),
    nextUpdate: formatDateIfValid(values[IR_FIELD_NAMES.NEXT_UPDATE]),
    isDraft: false,
  };
  if (props.editing) {
    delete payload.teamMembers;
  }
  // deleting unwanted clutters
  delete payload.masterProject;
  delete payload.subProject;
  delete payload.date;
  delete payload.assets;
  delete payload.teamMemberProp;

  const filteredPayload = Object.entries(payload).reduce(
    (acc: any, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
  return filteredPayload;
};

export const initializeIncidentReportForm = (
  form: FormInstance,
  incidentReport: IncidentReports | null | undefined,
) => {
  if (!isEmpty(incidentReport)) {
    const teamMemberIncident = isEmpty(incidentReport?.teamMembers)
      ? incidentReport?.team?.projectTeamMembers
      : incidentReport?.teamMembers;
    const teamSValue = teamMemberIncident?.map(
      (member: any) => member?.user?.id,
    );

    form.setFields([
      {
        name: IR_FIELD_NAMES.TITLE,
        value: incidentReport.title,
      },
      {
        name: IR_FIELD_NAMES.TYPE,
        value: incidentReport.type,
      },
      {
        name: IR_FIELD_NAMES.DATE,
        value:
          incidentReport.incidentDate && DayJs(incidentReport.incidentDate),
      },
      {
        name: IR_FIELD_NAMES.INCIDENT_NUMBER,
        value: incidentReport.incidentNo,
      },
      {
        name: IR_FIELD_NAMES.EMAIL_TO_CLIENT,
        value: incidentReport.emailToClient,
      },
      {
        name: "subProject",
        value: incidentReport.subProject?.name,
      },
      {
        name: IR_FIELD_NAMES.AFFECTED_SYSTEMS,
        value: incidentReport.affectedSystem?.id,
      },
      {
        name: IR_FIELD_NAMES.INCIDENT_IMPACT,
        value: incidentReport.impact?.id,
      },
      {
        name: IR_FIELD_NAMES.PRIORITY,
        value: incidentReport?.priority,
      },
      {
        name: "teamMembers",
        value: teamSValue,
      },
      {
        name: IR_FIELD_NAMES.MASTER_PROJECT,
        value: incidentReport?.project?.id,
      },
      {
        name: "teamMemberProp",
        value: incidentReport?.team
          ? [incidentReport?.team.id]
          : incidentReport.teamMembers
          ? incidentReport.teamMembers.map((d: any) => d.user.id)
          : [],
      },
    ]);
  } else {
    form.setFields([
      {
        name: IR_FIELD_NAMES.TYPE,
        value: "Incident",
      },
      {
        name: IR_FIELD_NAMES.PRIORITY,
        value: "NORMAL",
      },
    ]);
  }
};
