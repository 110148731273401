import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AlectifyCarouselProps } from "./AlectifyCarousel.interface";
import { Image, message, Skeleton, Upload } from "antd";
import { debounce, isEmpty } from "lodash";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlayCircleFilled,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { UploadFile } from "antd/lib";
import { MESSAGES } from "constants/messages";
import { UploadChangeParam } from "antd/es/upload";
import { IPmAttachmentDocuments } from "redux/components/pm-internal";
import RightArrowIcon from "components/icons/RightArrowIcon";
import { LeftArrowIcon } from "components/icons";
import "./AlectifyCarousel.scss";
import { IMetaNest } from "redux/interfaces";
import {
  fetchWorkOrderMedia,
  uploadWorkOrderDocuments,
} from "services/work-orders/work-order.service";

const AlectifyCarousel: React.FC<AlectifyCarouselProps> = ({
  preview = false,
  itemsPerPage = 5,
  recordID,
  details,
  videoWidth,
  videoHeight,
  openInModal,
}) => {
  const [media, setMedia] = useState<{
    data: IPmAttachmentDocuments[];
    meta: IMetaNest;
    loader: boolean;
  }>({
    data: [],
    meta: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 5,
    },
    loader: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const [sliderKey, setSliderKey] = useState(0);

  const handleNext = () => {
    const isLastSlide = currentSlide === media.data.length - 1;
    if (isLastSlide && media.meta.currentPage < media.meta.totalPages) {
      const nextPage = media.meta.currentPage + 1;
      if (recordID) {
        fetchMedia(nextPage);
      }
    }
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    const isFirstSlide = currentSlide === 0;
    if (isFirstSlide && media.meta.currentPage > 1) {
      const prevPage = media.meta.currentPage - 1;
      if (recordID) {
        fetchMedia(prevPage);
      }
    }
    sliderRef.current?.slickPrev();
  };

  const uploadDocuments = async (fileList: UploadFile[]) => {
    try {
      setMedia((prev: any) => ({
        ...prev,
        loader: true,
      }));
      if (details) {
        message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append("images", file.originFileObj as Blob);
        });

        /*  formData.append("userId", user?.user?.id);
        formData.append("projectId", details.project?.id || "-");
        formData.append("subProjectId", details.subProject.id); */

        await uploadWorkOrderDocuments(
          details?.masterPreventiveMaintenance?.id,
          formData,
        );
        message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      }
    } catch (error) {
      message.error(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_ERROR);
      throw error;
    } finally {
      if (recordID) {
        fetchMedia();
      }
    }
  };

  // This method only handles the upload without interfering with the slider
  const handleUploadChange = debounce((info: UploadChangeParam) => {
    uploadDocuments(info.fileList);
  }, 300);

  const defaultSettings = {
    customPaging: (i: number) => {
      const thumbnailCount = media?.data?.length || 0;
      const shouldShowUpload =
        thumbnailCount === 1 ||
        thumbnailCount === 2 ||
        (thumbnailCount >= 3 && i === thumbnailCount - 1);

      const file = media?.data?.[i];
      const isVideo = file?.fileType === "video/mp4";

      return (
        <div className="d-flex align-items-center justify-content-center">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            {media.loader ? (
              <Skeleton.Image
                active
                style={{ width: "auto", height: "auto" }}
              />
            ) : isVideo ? (
              <div className="video-thumbnail-container">
                <PlayCircleFilled style={{ fontSize: "20px" }} />
              </div>
            ) : (
              <>
                <img
                  src={file?.filePath}
                  alt={`Thumbnail ${i + 1}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
                {!file?.isActive && (
                  <div className="deleted-overlay-thumbnail">
                    <StopOutlined height={50} width={50} color="red" />
                  </div>
                )}
              </>
            )}
          </a>
          {shouldShowUpload && i === thumbnailCount - 1 && (
            <div
              className={`avatar-uploader plus-icon ${
                !openInModal ? "in-modal-carousel" : ""
              } ${preview ? "" : "plus-white-icon"}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                showUploadList={false}
                onChange={handleUploadChange}
                beforeUpload={() => false}
                multiple
                accept="image/*,video/*"
                fileList={[]}
              >
                <div>
                  {media.loader ? <LoadingOutlined /> : <PlusOutlined />}
                </div>
              </Upload>
            </div>
          )}
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_current: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  const fetchMedia = async (page?: any) => {
    setMedia((prev: any) => ({
      ...prev,
      loader: true,
    }));
    try {
      const params = {
        page: page || 1,
        limit: 5,
      };
      const response = await fetchWorkOrderMedia(recordID || "", params);
      if (response.status) {
        setMedia((prev: any) => ({
          ...prev,
          data: response.data,
          meta: response.meta,
          loader: false,
        }));
      }
    } catch (ex) {
      setMedia((prev: any) => ({
        ...prev,
        loader: true,
      }));
      console.log(ex);
    }
  };

  useEffect(() => {
    if (media?.data) {
      sliderRef.current?.slickGoTo(0);
      setSliderKey((prev) => prev + 1);
      setTimeout(() => {
        setCurrentSlide(0);
      }, 1);
    }
  }, [media]);

  useEffect(() => {
    if (recordID) {
      fetchMedia();
    }
  }, [recordID]);

  const totalItems = media?.meta?.totalItems || 0;

  const globalSlideIndex =
    (media?.meta?.currentPage - 1) * itemsPerPage + currentSlide + 1;
  return (
    <div className="carousel-container">
      {!isEmpty(media?.data) ? (
        <>
          <Slider key={sliderKey} {...defaultSettings} ref={sliderRef}>
            {media?.data?.map((slide: any, index: number) => (
              <div key={`slide-${index}`} className="carousel-item">
                {media.loader ? (
                  <Skeleton.Image
                    active
                    style={{ width: "20rem", height: "21rem" }}
                  />
                ) : (
                  <div
                    className={`media-container ${
                      !slide.isActive ? "inactive-file" : ""
                    }`}
                  >
                    {slide.fileType === "video/mp4" ? (
                      <div className="video-carousel-container">
                        <video
                          src={slide.filePath}
                          controls={slide.isActive}
                          className="carousel-video"
                          style={{
                            width: videoWidth,
                            height: videoHeight,
                          }}
                        />
                      </div>
                    ) : (
                      <Image
                        preview={preview}
                        onClick={() => openInModal && openInModal()}
                        className={`img-carousel ${
                          openInModal ? "cursor-pointer" : ""
                        }`}
                        src={slide.filePath}
                        alt={`Slide ${index + 1}`}
                      />
                    )}
                    {!slide.isActive && (
                      <div className="deleted-overlay">
                        <StopOutlined height={50} width={50} color="red" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </Slider>
          {/* carousel navigation buttons  */}
          <div className="carousel-navigation">
            <div className="slick-prev custom-arrow" onClick={handlePrev}>
              <LeftArrowIcon stroke={"#fff"} />
            </div>

            <div className="slick-next custom-arrow" onClick={handleNext}>
              <RightArrowIcon stroke={"#fff"} />
            </div>
          </div>

          {!media.loader && (
            <div className="image-counter">
              {globalSlideIndex}/{totalItems}
            </div>
          )}
        </>
      ) : (
        <div className="empty-image">
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            onChange={handleUploadChange}
            beforeUpload={() => false}
            multiple
            fileList={[]}
            disabled={details?.isFuture}
          >
            <div className="upload-items">
              <div>
                {media.loader ? (
                  <LoadingOutlined />
                ) : (
                  <CloudUploadOutlined className="icon-font-size-20" />
                )}
              </div>
              <span className="upload-msg-container">
                {details && !details.isFuture
                  ? "No image uploaded, click here to upload"
                  : "No image uploaded"}
              </span>
            </div>
          </Upload>
        </div>
      )}
    </div>
  );
};

export default AlectifyCarousel;
