import React, { memo, useEffect, useRef, useState } from "react";
import { debounce, get, isEmpty } from "lodash";
import { Col, Row, Table, Space, Typography, Form, Badge } from "antd";
import AlectifyText from "static/texts.json";
import { PAGINATION } from "../../../constants";
import {
  TablePaginationConfig,
  TableRowSelection,
} from "antd/lib/table/interface";
import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  IAlectifyTableProps,
  ITableFilterType,
} from "./AlectifyTable.interface";
import AlectifyInput from "../input";
import AlectifyButton from "../button";
import SearchIcon from "components/icons/SearchIcon";
import AIBotIcon from "components/icons/AiBotIcon";
import MaintenancePredictionDatePicker from "../maintenance-prediction/MaintenancePredictionDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setSearchedText } from "redux/components/common/sources";
import AlectifySelect from "../select";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AddDocument from "components/shared/add-document";
import DownloadIcon from "components/icons/DownloadIcon";
import FilterAssets from "../assets-table/filter-buttons/FilterAssets";
import AlectifyTableFilters from "./AlectifyTableFilters";
import {
  resetTableFilterState,
  setTableFilters,
} from "redux/components/table-filters/sources";
import { IRootState } from "redux/rootReducer";
import { ITableFilterTypeEnum } from "redux/components/table-filters";
import "./AlectifyTable.scss";

const AlectifyTable: React.FC<IAlectifyTableProps> = (
  props: IAlectifyTableProps,
) => {
  const dispatch = useDispatch();
  const [documentForm] = Form.useForm();

  const { searchedText } = useSelector(({ common }: IRootState) => common);
  const { tableFilters } = useSelector(({ tableFilter }) => tableFilter);
  const searchedTextRef = useRef(searchedText);
  const [pageSize, setPageSize] = useState<number | undefined>(
    props.defaultPageSize,
  );

  const [page, setPage] = useState<number | undefined>(
    props.defaultStartPage || PAGINATION.DEFAULT_START_PAGE,
  );
  const [orderingColumn, setOrderingColumn] = useState<
    string | undefined | null
  >(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    props?.selectedRowKeys || [],
  );
  const [searchText, setSearchText] = useState<string | null>("");

  const [filter, setFilter] = useState<string>(
    get(props, "filters.defaultValue", "") ||
      get(props, "filters.options[0].value", ""),
  );
  const [columnFilters, setColumnFilters] = useState<any>(null);

  useEffect(() => {
    searchedTextRef.current = searchedText;
  }, [searchedText]);

  const getSearchColumns = (newSearchText: string | null) => {
    const searchableColumnsList: { [key: string]: string } = {};
    (props.columns || []).forEach((column: any) => {
      if (column.searchable && newSearchText) {
        if (props.customSearchKey) {
          searchableColumnsList[`${props.customSearchKey}`] = newSearchText;
        } else if (!props.noIcontainsSearch) {
          searchableColumnsList[`${column.dataIndex}__icontains`] =
            newSearchText;
        } else {
          searchableColumnsList[`${column.dataIndex}`] = newSearchText;
        }
      }
    });
    return searchableColumnsList;
  };

  useEffect(() => {
    props.fetchSelectedRowKeys && props.fetchSelectedRowKeys(selectedRowKeys);
  }, [selectedRowKeys]);

  const getCheckboxProps = (record: any) => ({
    disabled: !record?.is_active, // Column configuration not to be checked
  });

  const onRowSelection = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Update Selected Records from keys
    props.onRowSelectionAction?.(selectedRows, newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onRowSelection,
    ...(props.getCheckboxProps && { getCheckboxProps: getCheckboxProps }),
  };

  useEffect(() => {
    setSelectedRowKeys(props.selectedRowKeys || []);
  }, [props.selectedRowKeys]);

  const onChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    let newFilters: any = {};

    // if (props.columnFilterAsString) {
    Object.keys(filters).forEach((key: string) => {
      if (props.columnFilterAsString && !isEmpty(filters[key])) {
        newFilters[key] = filters[key].join(",");
      } else {
        const column = [...props.columns].find(
          (tblColumn: any) => tblColumn?.dataIndex === key,
        ) as any;
        if (!column?.filterMultiple && !isEmpty(filters[key])) {
          newFilters[key] = filters[key][0];
        } else {
          newFilters[key] = filters[key];
        }
      }
    });

    const defaultFilters =
      props.filters && filter
        ? {
            [props.filters.key]: filter,
          }
        : {};

    let ordering = sorter?.field;
    if (sorter.order === "descend") {
      ordering = `${sorter?.column?.sortingColumn ?? sorter?.field} DESC`;
    } else if (sorter.order === "ascend") {
      ordering = `${sorter?.column?.sortingColumn ?? sorter?.field} ASC`;
    } else {
      ordering = null;
    }

    setOrderingColumn(ordering);
    setPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setColumnFilters(newFilters ?? defaultFilters);

    props.onDataFetch({
      id: props.id,
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...(ordering && { orderBy: ordering }),
      ...getSearchColumns(searchText),
      ...(props.filters && filter
        ? {
            [props.filters.key]: filter,
          }
        : {}),
      ...(newFilters ?? defaultFilters),
    });
  };

  const onSearchChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = e.target.value;
      if (props.searchDataRef && props.searchDataRef.current) {
        props.searchDataRef.current.searchText = newSearchText;
      }

      setSearchText(newSearchText);
      setPage(1);
      props.onDataFetch({
        id: props.id,
        page: 1,
        per_page: pageSize,
        order_by: orderingColumn || null,
        ...getSearchColumns(newSearchText),
        ...(props.filters && filter
          ? {
              [props.filters.key]: filter,
            }
          : {}),
        ...columnFilters,
      });
    },
    500,
  );

  const onSearchTaskMaintenanceChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = e.target.value;

      if (props.searchDataRef && props.searchDataRef.current) {
        props.searchDataRef.current.searchText = newSearchText;
      }

      await dispatch(setSearchedText(newSearchText));
      setSearchText(newSearchText);
      setPage(1);

      props.onDataFetch({
        id: props.id,
        page: 1,
        per_page: pageSize,
        search: searchedTextRef.current || undefined,
      });
    },
    500,
  );

  const downloadZip = () => {
    const selectedFiles = props.dataSource
      .filter((a: any) => selectedRowKeys.includes(a?.id || a?.document_id))
      .map((a: any) => (a.file_path ? a.file_path : a.filePath)); // Extract only the file_path values
    props.onDownloadZip && props.onDownloadZip(selectedFiles);
    setSelectedRowKeys([]);
  };

  const handleTemporaryFilterChange = (value: any) => {
    setFilter(value);
    setPage(1);
    setPageSize(50);
    props.onDataFetch({
      id: props.id,
      page: 1,
      per_page: 50,
      order_by: orderingColumn || null,
      ...getSearchColumns(searchText),
      ...(props.filters && value
        ? {
            [props.filters.key]: value,
          }
        : {}),
      ...columnFilters,
    });
  };

  const onFilterChange = (value: any, type: ITableFilterTypeEnum) => {
    if (tableFilters.filterType !== type && tableFilters.status) {
      dispatch(resetTableFilterState());
    }
    dispatch(
      setTableFilters({ statusShifterButtons: value, filterType: type }),
    );
    setFilter(value);
    setPage(1);
    setPageSize(50);
    props.onDataFetch({
      id: props.id,
      page: 1,
      per_page: 50,
      order_by: orderingColumn || null,
      ...getSearchColumns(searchText),
      ...(props.filters && value
        ? {
            [props.filters.key]: value,
          }
        : {}),
      ...columnFilters,
    });
  };

  const customPagination = (
    <div className="alectify-table-customPagition">
      {props.total > 0 && (
        <Typography.Text>
          {`${
            (page ? page - 1 : 0) * (pageSize ? pageSize : 0) + 1
          } - ${Math.min(
            page && pageSize ? page * pageSize : 0,
            props.total,
          )} of ${props.total}`}
        </Typography.Text>
      )}
    </div>
  );

  // This function is intended to send an email with shared files based on the provided 'values'.
  /* const onSendEmail = async (values: IEmailFormValues) => {
    const { emails } = values;
    const params = {
      to_users: emails,
      document_type: props.documentType || "",
      file_ids: selectedRowKeys,
    };

    if (!activeSubProject) return;

    try {
      const response = await shareSubProjectDocuments(
        activeSubProject.id,
        params,
      );
      if (response.success) {
        message.success("Files Shared");
      }
    } catch (error) {
      throw error;
    }

    ModalServiceInstance.close(AlectifyModal, { name: "emails" });
    form.resetFields();
  }; */

  const getBadgeCount = () => {
    if (!tableFilters) return 0;

    let count = Object.entries(tableFilters).filter(([key, value]) => {
      if (
        key === "statusShifterButtons" ||
        key === "orderTypeTab" ||
        key === "filterType"
      ) {
        return false;
      }
      if (tableFilters.orderTypeTab === "tasks" && key === "taskCategory") {
        return false;
      }
      return value !== null;
    }).length;
    if (
      !tableFilters.status &&
      tableFilters.statusShifterButtons &&
      ["COMPLETED", "SKIPPED"].includes(tableFilters.statusShifterButtons)
    ) {
      count += 1;
    }

    if (tableFilters.orderTypeTab === "tasks") {
      count += 1;
    }
    return count;
  };

  return (
    <Row justify={"start"} align={"middle"}>
      {props?.aIBotHeader ? (
        <div className="d-flex justify-content-space-between width-100">
          <Space className="bot-container">
            <AIBotIcon />
            <Typography.Title level={5} className="bot-text">
              {AlectifyText.AI_ASSISTANT_TEXT}
            </Typography.Title>
            <AlectifyInput
              name="Search"
              type="text"
              placeholder={props.searchPlaceholder || "Search"}
              onChange={onSearchChange}
              prefix={<SearchOutlined />}
              className="alectify-table-search-input"
              allowClear
            />
          </Space>
          <MaintenancePredictionDatePicker
            disableDatePicker={true}
            getPredictions={props.onDataFetch}
            getDateRangeFromChild={props.getDateRangeFromChild}
            setShowMaintenanceDetail={props.setShowMaintenanceDetail}
          />
        </div>
      ) : (
        <></>
      )}
      {(props.tableTitle ||
        props.extraButton ||
        props.filters ||
        props.showSearch ||
        props.downloadable) && (
        <Col span={24} className="mb-8">
          <Row justify={"space-between"}>
            <Col span={16}>
              <Space wrap>
                {props?.tableTitle ? (
                  <Typography className="alectify-table-text mb-0">
                    <Typography.Text>{props?.tableTitle}</Typography.Text>
                  </Typography>
                ) : (
                  <></>
                )}
                {!isEmpty(props.filters) && (
                  <>
                    {props.filterType === ITableFilterType.DROPDOWN ? (
                      <AlectifySelect
                        name="filters"
                        onChange={(value) => handleTemporaryFilterChange(value)}
                        options={props.filters.options}
                        className="alectify-table-filters-select"
                        value={props.defaultFilterValue || filter}
                        noFormItem
                        defaultValue={props.defaultFilterValue}
                        defaultActiveFirstOption={true}
                      />
                    ) : (
                      <Space>
                        {props.filters?.options.map((option: any) => (
                          <AlectifyButton
                            key={option.value}
                            text={option.label}
                            type="primary"
                            className={
                              (Array.isArray(tableFilters.statusShifterButtons)
                                ? tableFilters.statusShifterButtons.join(",")
                                : tableFilters.statusShifterButtons) ===
                              (Array.isArray(option.value)
                                ? option.value.join(",")
                                : option.value)
                                ? "button-active"
                                : "button-not-active"
                            }
                            onClick={() =>
                              onFilterChange(option.value, option.type)
                            }
                          />
                        ))}
                      </Space>
                    )}
                  </>
                )}
                {!props?.tableTitle && props.showSearch ? (
                  <>
                    {props.toggleOptions && (
                      <FilterAssets
                        options={props?.toggleOptions}
                        label={props?.toggleLabel}
                        onChange={props.toggleChange}
                      />
                    )}
                    {props.activeTaskMaintenanceSearch ? (
                      <div
                        className={`table-search ${
                          searchedTextRef.current
                            ? "table-search-active"
                            : "table-search-not-active"
                        }`}
                      >
                        <AlectifyInput
                          defaultValue={searchedTextRef.current as any}
                          name="Search"
                          type="text"
                          noFormitem
                          placeholder={props.searchPlaceholder || "Search"}
                          onChange={onSearchTaskMaintenanceChange}
                          prefix={<SearchIcon />}
                          allowClear
                        />
                      </div>
                    ) : (
                      <AlectifyInput
                        name="Search"
                        type="text"
                        placeholder={props.searchPlaceholder || "Search"}
                        onChange={onSearchChange}
                        prefix={<SearchIcon />}
                        className="alectify-table-search-input"
                        allowClear
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}

                {props.enableFilters && (
                  <div className="filter-container">
                    <Badge
                      style={{ backgroundColor: "#0954f1" }}
                      count={getBadgeCount()}
                    >
                      <AlectifyTableFilters
                        enableFilters={props.enableFilters}
                      />
                    </Badge>
                    {(() => {
                      const filters: string[] = [];
                      if (!isEmpty(tableFilters.createdAt))
                        filters.push("Created at");
                      if (!isEmpty(tableFilters.dueDate))
                        filters.push(`Due Date (default)`);
                      if (!isEmpty(tableFilters.status)) filters.push("Status");
                      if (tableFilters.orderTypeTab === "tasks")
                        filters.push("Work Order Type");

                      if (!isEmpty(tableFilters.taskCategory))
                        filters.push("Work Order Type");
                      return filters.length > 0 ? (
                        <span className="filter-tag">
                          <span className="filter-applied-text">
                            Filters Applied:
                          </span>
                          <span className="ml-2 filter-value-text">
                            {filters.join(", ")}
                          </span>
                        </span>
                      ) : null;
                    })()}
                  </div>
                )}
              </Space>
            </Col>

            {props?.tableTitle && props.showSearch ? (
              <AlectifyInput
                name="Search"
                type="text"
                placeholder="Search"
                onChange={onSearchChange}
                prefix={<SearchOutlined />}
                className="alectify-table-search-input"
                allowClear
              />
            ) : (
              <></>
            )}

            <Col span={8} style={{ textAlign: "right" }}>
              {props.downloadable ? (
                <Space>
                  <AlectifyButton
                    text={`Download Zip (${selectedRowKeys.length})`}
                    type="primary"
                    className="light-blue-button"
                    icon={
                      <DownloadIcon
                        fill={`${
                          selectedRowKeys.length === 0 ? "grey" : "#0954F1"
                        }`}
                      />
                    }
                    onClick={downloadZip}
                    disabled={selectedRowKeys.length === 0}
                  />
                  {props.addDocumentButton && (
                    <AlectifyButton
                      text="Add Document"
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => {
                        DrawerServiceInstance.open(AlectifyDrawer, {
                          width: "600",
                          title: "Upload Documents",
                          name: DRAWER_CONSTANTS.DOCUMENTS_MAIN_DRAWER,
                          closable: true,
                          closeIcon: <CloseOutlined />,
                          onClose: () =>
                            DrawerServiceInstance.close(AlectifyDrawer, {
                              name: DRAWER_CONSTANTS.DOCUMENTS_MAIN_DRAWER,
                            }),
                          submitText: "Upload",
                          showFooter: true,
                          onSubmit: () => documentForm.submit(),
                          children: (
                            <AddDocument
                              documentForm={documentForm}
                              getDocuments={props.onDataFetch}
                            />
                          ),
                          className: "alectify-drawer-add-documents",
                        });
                      }}
                    />
                  )}

                  {/* Note: Commented it for temporary basis */}
                  {/* <AlectifyButton
                      text="Share"
                      type="primary"
                      icon={<ShareAltOutlined />}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => {
                        ModalServiceInstance.open(AlectifyModal, {
                          onCancel: () => {
                            ModalServiceInstance.close(AlectifyModal, {
                              name: "emails",
                            });
                          },
                          onOk: form.submit,
                          children: (
                            <AlectifyEmailShareModal
                              form={form}
                              onSendEmail={onSendEmail}
                              name="emails"
                              label="Send to"
                              placeholder="Enter email ids"
                            />
                          ),
                          okText: "Send",
                          title: "Share Documents",
                          name: "emails",
                          destroyOnClose: true,
                        });
                      }}
                    /> */}
                </Space>
              ) : (
                <></>
              )}
              <Space size={20}>{props.extraButton && props.extraButton}</Space>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Table
          expandable={props.expandable}
          bordered={props.bordered}
          onRow={(record, rowIndex) => {
            if (props.onRowClick) {
              return {
                onClick: () => props.onRowClick?.(record, rowIndex),
              };
            }
            return {};
          }}
          className={`alectify-table ${props.className}`}
          columns={props.columns}
          loading={props.loading}
          {...(props.rowSelection
            ? {
                rowSelection: {
                  type: props.selectionType,
                  selectedRowKeys: selectedRowKeys,
                  preserveSelectedRowKeys: true,
                  ...rowSelection,
                },
              }
            : {})}
          onChange={onChange}
          pagination={
            props.showPagination && {
              pageSize,
              current: page,
              total: props.total,
              showSizeChanger: props.showSizeChanger,
              pageSizeOptions: PAGINATION.PAGE_SIZE_OPTIONS,
              locale: {
                items_per_page: "",
              },
            }
          }
          rowClassName={
            props.displayRowClass
              ? (record, index, indent) =>
                  props.displayRowClass?.(record, index, indent)
              : ("" as any)
          }
          size={props.size}
          dataSource={(!isEmpty(props.dataSource) ? props.dataSource : []).map(
            (d: any, idx: number) => ({
              ...d,
              key: d?.id || d?.document_id || idx,
            }),
          )}
          scroll={props.scroll}
          locale={props.locale}
        />
        {props.showPagination && customPagination}
      </Col>
    </Row>
  );
};

AlectifyTable.defaultProps = {
  size: "small",
  loading: false,
  className: "",
  rowSelection: false,
  showPagination: true,
  showSizeChanger: true,
  selectionType: "checkbox",
  noIcontainsSearch: false,
  defaultPageSize: PAGINATION.DEFAULT_PAGE_SIZE,
  onDownloadZip: () => {},
  fetchSelectedRowKeys: () => {},
};

export default memo(AlectifyTable);
