import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { IFiltersModalProps } from "./ProjectFilter.interface";
import AlectifyText from "static/texts.json";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { DownOutlined } from "@ant-design/icons";
import ProjectFilterModal from "./ProjectFilterModal";
import { setActiveMasterProject } from "redux/components/common/sources";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { IMasterProject } from "redux/components/master-project";
// import { getMasterProject } from "redux/components/master-project/sources";
import { isEmpty, truncate } from "lodash";

const ProjectFilter: React.FC<IFiltersModalProps> = (
  props: IFiltersModalProps,
) => {
  const [filters, setFilters] = useState<string | null>(null);
  const [selectedProjects, setSelectedProjects] = useState<string | null>(null);
  const masterProjects = useSelector(
    ({ MasterProject }: IRootState) => MasterProject,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const dispatch = useDispatch();

  const onSubmit = () => {
    const activeSite =
      selectedProjects !== "all"
        ? masterProjects.data.find(
            (project: IMasterProject) => project.id === selectedProjects,
          )
        : null;

    dispatch(setActiveMasterProject(activeSite));

    /* No need to route back when its on my-work-order or all-work-order applying this condition for that particular case. */
    if (
      !location.pathname.includes(ROUTES.ALL_WORK_ORDERS) &&
      !location.pathname.includes(ROUTES.MY_ITEMS) &&
      !location.pathname.includes(ROUTES.CALENDAR) &&
      !location.pathname.includes(ROUTES.ASSET_DOCUMENTS) &&
      !location.pathname.includes(ROUTES.SITE_DOCUMENTS) &&
      !location.pathname.includes(ROUTES.INVENTORY) &&
      !location.pathname.includes(ROUTES.DASHBOARD) &&
      !location.pathname.includes(ROUTES.INCIDENT_REPORTS) &&
      !location.pathname.includes(ROUTES.PROCEDURES) &&
      !location.pathname.includes(ROUTES.CONTACTS) &&
      !location.pathname.includes(ROUTES.REPORTS)
    ) {
      navigate(
        selectedProjects !== "all" && selectedProjects !== null
          ? `${
              location.pathname.includes(ROUTES.DATA_ROUND)
                ? ROUTES.DATA_ROUND
                : ROUTES.MASTER_PROJECT_DETAILS
            }/${selectedProjects}${page ? "?page=users" : ""}`
          : location.pathname.includes(ROUTES.DATA_ROUND)
          ? ROUTES.DATA_ROUND
          : ROUTES.MASTER_PROJECT_DETAILS,
      );
    }

    if (location.pathname.includes(ROUTES.DOCUMENTS)) {
      navigate(ROUTES.DOCUMENTS);
    }

    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
    });
  };

  const ModalConfigs = {
    onCancel: () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
      });
      setSelectedProjects(filters);
    },
    className: "dashboard-filter-modal",
    onOk: () => {
      setFilters(selectedProjects);
      onSubmit();
      // props.onSubmit(selectedProjects);
    },
    children: (
      <ProjectFilterModal
        setSelectedProjects={setSelectedProjects}
        selectedProjects={selectedProjects}
      />
    ),
    closeIcon: false,
    okText: "Save Changes",
    title: (
      <Col>
        <Typography.Text className="filter-modal-title">
          {AlectifyText.SELECT_SITE}
        </Typography.Text>
        <Typography.Paragraph className="filter-modal-sub-title mt-5">
          {AlectifyText.DASHBOARD_CONTEXT}
        </Typography.Paragraph>
      </Col>
    ),
    name: MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
    destroyOnClose: true,
  };

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
      ModalConfigs,
    );
  }, [selectedProjects]);

  useEffect(() => {
    // due to email redirection master data is not fetched, i.e fetching the master projects here.
    if (isEmpty(masterProjects.data)) {
      // dispatch(getMasterProject()); // commenting this out due to infinite loop when new user logs in.
    }

    // Open filter modal if activeMasterProject is null and route is /projects or /data-round (please ask @sarmad if you're not able to understand the logic)
    if (
      !activeMasterProject &&
      !isEmpty(masterProjects.data) &&
      (location.pathname === ROUTES.MASTER_PROJECT_DETAILS ||
        location.pathname === ROUTES.DATA_ROUND)
    ) {
      if (
        !ModalServiceInstance.isModalOpen(
          MODAL_NAMES.DASHHBOARD_MASTERPROJECT_FILTER,
        )
      ) {
        openMasterProjectFilter();
      }
    }
  }, [location.pathname, location.search, masterProjects.data]);

  const openMasterProjectFilter = () => {
    ModalServiceInstance.open(AlectifyModal, ModalConfigs);
  };

  return (
    <Row className="mt-10 mb-5">
      <Typography.Text>{AlectifyText.FILTER_ON_SITE}: </Typography.Text>
      <Button
        className="dashboard-filter-btn"
        size="small"
        onClick={openMasterProjectFilter}
      >
        <Space>
          {activeMasterProject ? (
            <>
              <Tooltip title={activeMasterProject?.name}>
                <span key={activeMasterProject?.id}>
                  {truncate(activeMasterProject?.name, {
                    length: 20,
                    omission: "...",
                  })}
                </span>
              </Tooltip>
            </>
          ) : (
            <span>{AlectifyText.ALL_SITES}</span>
          )}
          <DownOutlined />
        </Space>
      </Button>
    </Row>
  );
};

export default ProjectFilter;
