import {
  Col,
  Form,
  FormInstance,
  message,
  Radio,
  Row,
  Spin,
  Typography,
} from "antd";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import { MESSAGES } from "constants/messages";
import { MODAL_NAMES } from "constants/modal.constants";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { IPmExternal } from "redux/components/pm-external";
import { IProjectSparePart } from "redux/components/spare-parts";
import { ITask } from "redux/components/tasks";
import { ISparePartDrawPayload } from "services/spare-parts/spare-parts.interface";
import { drawSparePart } from "services/spare-parts/spare-parts.service";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyInput from "../input";
import AlectifyModal from "../modal";
import AlectifyQuantityInput from "../quantity-input";
import SparePartsTable from "../spare-parts-table";
import "./DrawWorkOrderSpareParts.scss";

interface Props {
  workOrder: ITask | IPmExternal;
  FormInstance: FormInstance;
  getHistory: () => void;
  callback?: () => void;
}

const DrawWorkOrderSparePart = (props: Props) => {
  const [newQty, setQty] = useState(0);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedSparePart, setSelectedSparePart] =
    useState<IProjectSparePart>();
  const [drawType, setDrawType] = useState<string>("draw");

  const onSelectSparePart = (sparePart: IProjectSparePart) => {
    props.FormInstance.setFieldValue("sparePartId", sparePart.id);
    setSelectedSparePart(sparePart);
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
    });
  };

  useEffect(() => {
    if (isNil(selectedSparePart?.remainingQuantity)) {
      props.FormInstance.setFields([
        {
          name: "sparePartId",
          errors: [MESSAGES.SPARE_PART_MESSAGES.PLEASE_SELECT_SPARE_PART],
        },
      ]);
    } else {
      props.FormInstance.setFields([
        {
          name: "sparePartId",
          errors: undefined,
        },
      ]);
    }
    setQty(selectedSparePart?.remainingQuantity || 0);
  }, [selectedSparePart?.remainingQuantity]);

  useEffect(() => {
    openSparePartSelection();
  }, []);

  const openSparePartSelection = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1350,
      className: "spare-parts-table-modal-class",
      name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
      title: AlectifyText.SELECT_SPARE_PART,
      children: (
        <SparePartsTable
          scroll={{ y: "calc(100vh - 350px)" }}
          onRowSelect={onSelectSparePart}
          selectRowOption
          specificMasterProjectId={props.workOrder?.project.id}
          showSelectbutton
        />
      ),
      footer: null,
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
        }),
    });
  };
  const onSubmit = async (values: any) => {
    try {
      if (!selectedSparePart) return;
      const drawQty = parseInt(values.quantity);

      if (drawQty === 0) {
        props.FormInstance.setFields([
          { name: "quantity", errors: ["Quantity must be greater than Zero"] },
        ]);
        return;
      } else if (
        drawQty > selectedSparePart.remainingQuantity &&
        drawType === "draw"
      ) {
        props.FormInstance.setFields([
          {
            name: "quantity",
            errors: [MESSAGES.SPARE_PART_MESSAGES.OVER_IN_HAND_QTY],
          },
        ]);
        return;
      }
      setSubmitting(true);
      const payload: ISparePartDrawPayload = {
        quantity: drawType === "draw" ? drawQty : drawQty * -1, //when draw type is return quantity will be in negative.
        comments: `${
          drawType === "draw" ? "Spare part drawn" : "Spare part returned"
        }: ${values.comments}`,
        projectId: props.workOrder.project.id,
        subProjectId: props.workOrder.subProject.id,
        preventiveMaintenanceId: props.workOrder.id,
      };
      if (!isEmpty(props.workOrder.asset)) {
        payload.assetId = props.workOrder.asset?.id;
      } else {
        payload.areaId = props.workOrder.area?.id;
      }
      await drawSparePart(selectedSparePart.id, payload);
      props.FormInstance.resetFields();
      setSubmitting(false);
      if (props.callback) props.callback();
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
      });
      message.success("Drawn Successfully");
      props.getHistory();
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Spin spinning={submitting}>
      <Form layout="vertical" form={props.FormInstance} onFinish={onSubmit}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="sparePartId"
              rules={[
                {
                  required: true,
                  message:
                    MESSAGES.SPARE_PART_MESSAGES.PLEASE_SELECT_SPARE_PART,
                },
              ]}
            >
              <AlectifyButton
                text={
                  selectedSparePart?.sparePart?.partNumber ||
                  "Select Spare Part"
                }
                type="primary"
                htmlType="button"
                className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                block
                onClick={openSparePartSelection}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-10 draw-spare-parts-toggle">
            <Radio
              checked={drawType === "draw"}
              onClick={() => {
                setDrawType("draw");
              }}
            >
              Draw Part
            </Radio>
            <Radio
              checked={drawType === "return"}
              onClick={() => {
                setDrawType("return");
              }}
            >
              Return Part
            </Radio>
          </Col>
          <Col span={24}>
            <AlectifyQuantityInput
              name="quantity"
              drawType={drawType}
              label={
                <>
                  {AlectifyText.QUANTITY}
                  <div className="form-label-extra">
                    <Typography.Text style={{ color: "#9A9A9A" }}>
                      {AlectifyText.IN_HAND_QTY}:{" "}
                      {selectedSparePart?.remainingQuantity || 0}
                    </Typography.Text>
                  </div>
                </>
              }
              placeholder=""
              rules={BASIC_REQUIRED_VALIDATION_RULES}
              form={props.FormInstance}
              max={selectedSparePart?.remainingQuantity}
              maxQtyErrorMsg={MESSAGES.SPARE_PART_MESSAGES.OVER_IN_HAND_QTY}
              disabled={isNil(selectedSparePart?.remainingQuantity)}
              extra={
                <>
                  <Row justify="space-between" align="middle">
                    <Col span={12}></Col>
                    <Col span={12} className="text-align-right">
                      <Typography.Text className="green-text">
                        New Remaining Quantity:{" "}
                        {newQty || selectedSparePart?.remainingQuantity || 0}
                      </Typography.Text>
                    </Col>
                  </Row>
                </>
              }
              onChange={(value) => {
                let qty;
                if (drawType === "draw") {
                  qty =
                    (selectedSparePart?.remainingQuantity || 0) -
                    parseInt(value);
                } else {
                  qty =
                    (selectedSparePart?.remainingQuantity || 0) +
                    parseInt(value);
                }
                setQty(qty);
              }}
            />
          </Col>
          <Col span={24}>
            <AlectifyInput
              name="comments"
              label={AlectifyText.COMMENTS}
              type="textarea"
              placeholder=""
              rules={BASIC_REQUIRED_VALIDATION_RULES}
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DrawWorkOrderSparePart;
