import { MONTHS_2023 } from "constants/dashboard.constants";
import { PM_STATUS } from "enums";
import { capitalize } from "lodash";
import { DueDateDelayFiltersStringToEnum } from "redux/components/pm-external";
import { setTableFilters } from "redux/components/table-filters/sources";
import {
  IChartDataItem,
  IDashboardDetails,
  IOpenItemsDetails,
  IPmState,
} from "services/dashboard/dashboard.interface";
import {
  // IPMExternalCounts,
  IPmCounts,
  // IWorkOrderSummary,
  IWorkOrderSummaryResponse,
} from "services/pm-internal/pm-internal.interface";
import { ISparePartDashboardMonthlyCost } from "services/spare-parts/spare-parts.interface";
import AlectifyText from "static/texts.json";
import {
  WORK_ORDER_CATEGORY_COLORS,
  WORK_ORDER_RECURRING_COLORS,
  WORK_ORDER_STATUS_COLORS,
} from "utils/constants";
import { enumToTile, renderAmount } from "utils/helpers";

export const pieChartOptions = () => {
  return {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["PM", "CM", "Task"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const openWorkOrderOptions = (
  type: "open" | "past",
  dispatch: (action: any) => void,
): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: "donut",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log("dataPointSelection", config.dataPointIndex);
          const selectedIndex = config.dataPointIndex;
          if (type === "open") {
            const statusMapping = ["PENDING", "WAITING FOR REVIEW", "DENIED"];
            if (statusMapping[selectedIndex]) {
              dispatch(
                setTableFilters({
                  statusShifterButtons: [
                    "PENDING",
                    "WAITING FOR REVIEW",
                    "DENIED",
                  ],
                  filterType: "OPEN",
                  status: [statusMapping[selectedIndex]],
                }),
              );
            }
          } else if (type === "past") {
            const statusMapping = ["COMPLETED", "SKIPPED"];
            if (statusMapping[selectedIndex]) {
              dispatch(
                setTableFilters({
                  statusShifterButtons: ["COMPLETED", "SKIPPED"],
                  filterType: "OPEN",
                  status: [statusMapping[selectedIndex]],
                }),
              );
            }
          }
        },
      },
    },
    labels:
      type === "open"
        ? [
            "Scheduled",
            enumToTile(PM_STATUS.WAITING_FOR_REVIEW),
            enumToTile(PM_STATUS.DENIED),
          ]
        : ["Closed", "Void"],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            total: {
              show: true,
              showAlways: true,
              label: "This Week",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
            },
          },
        },
      },
    },
    colors:
      type === "open"
        ? [
            WORK_ORDER_STATUS_COLORS.SCHEDULED,
            WORK_ORDER_STATUS_COLORS.WAITING_FOR_REVIEW,
            WORK_ORDER_STATUS_COLORS.DENIED,
          ]
        : [
            WORK_ORDER_STATUS_COLORS.COMPLETED,
            WORK_ORDER_STATUS_COLORS.SKIPPED,
          ],
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      position: "top",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "top",
          },
        },
      },
    ],
  };
};

export const closedWorkOrderOptions = (
  title: string,
): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: "donut",
      height: "100%",
    },
    labels: [
      "PM",
      "CM",
      "Task",
      "Damage",
      "Inspections",
      "Safety",
      "Deficiency",
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            total: {
              show: true,
              showAlways: true,
              label: "This Week",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
            },
          },
        },
      },
    },
    colors: [
      WORK_ORDER_CATEGORY_COLORS.PREVENTIVE_MAINTENANCE,
      WORK_ORDER_CATEGORY_COLORS.CORRECTIVE_MAINTENANCE,
      WORK_ORDER_CATEGORY_COLORS.TASK,
      WORK_ORDER_CATEGORY_COLORS.DAMAGE,
      WORK_ORDER_CATEGORY_COLORS.INSPECTION,
      WORK_ORDER_CATEGORY_COLORS.SAFETY,
      WORK_ORDER_CATEGORY_COLORS.DEFICIENCY,
    ],
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
      offsetY: 5,
      distributed: true,
      style: {
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    legend: {
      show: false,
      position: "bottom",
      offsetY: 10,
      height: 50,
      horizontalAlign: "center",
      labels: {
        useSeriesColors: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const recurringChartOptions = (title: string) => {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    labels: ["Recurring", "Non-Recurring"],
    plotOptions: {
      bar: {
        borderRadius: 3,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top", // Makes sure the labels are positioned at the top
        },
      },
    },
    colors: [
      WORK_ORDER_CATEGORY_COLORS.PREVENTIVE_MAINTENANCE,
      WORK_ORDER_CATEGORY_COLORS.CORRECTIVE_MAINTENANCE,
      WORK_ORDER_CATEGORY_COLORS.TASK,
      WORK_ORDER_CATEGORY_COLORS.DAMAGE,
      WORK_ORDER_CATEGORY_COLORS.INSPECTION,
      WORK_ORDER_CATEGORY_COLORS.SAFETY,
      WORK_ORDER_CATEGORY_COLORS.DEFICIENCY,
    ],
    stroke: {
      show: true,
      width: 0,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["#000"],
      },
      offsetY: -18,
    },
    legend: {
      position: "top",
      fontSize: "12px",
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const recurringVerticalChartOptions = (title: string) => {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        borderRadiusApplication: "end",
        columnWidth: "50%",
        barHeight: "70%",
        dataLabels: {
          position: "top", // ✅ Positions labels at the top
        },
      },
    },
    colors: [
      WORK_ORDER_RECURRING_COLORS.RECURRING,
      WORK_ORDER_RECURRING_COLORS.NON_RECURRING,
    ],
    stroke: {
      show: true,
      width: 0,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["#000"], // ✅ Changed to black for better visibility on top
      },
      offsetY: -22, // ✅ Moves labels slightly above the bars
      formatter: (val: number) => (val === 0 ? "" : val),
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: number) => (val === 0 ? "" : val),
      },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => (val === 0 ? "" : val),
        style: {
          fontSize: "12px",
        },
      },
    },
    legend: {
      position: "top",
      fontSize: "12px",
    },
    xaxis: {
      categories: ["Recurring", "Non-Recurring"],
      labels: {
        rotate: -25,
        rotateAlways: true,
        style: {
          fontSize: "12px",
        },
      },
      title: {
        style: {
          fontSize: "12px",
        },
        floating: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
          },
        },
      },
    ],
  };
};

export const openWorkOrdersStatisticsOptions: any = (
  callback?: (type: string) => void,
) => {
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          // const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          // const seriesName = config.w.config.series[seriesIndex].name;
          const category = config.w.config.xaxis.categories[dataPointIndex];

          /*   console.log(
            `Clicked on series: ${seriesName}, category: ${category}`,
          ); */
          if (callback) {
            callback(category);
          }
          // Handle click event here
          // For example, you could redirect the user or show more details
        },
      },
    },

    legend: {
      position: "top",
      fontSize: "12px",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },

    xaxis: {
      categories: ["Scheduled", "Waiting For Review"],
      title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          // color: "#333",
          fontWeight: 400,
        },
      },
      style: {
        fontSize: "14px",
      },

      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        lines: {
          show: false,
        },
      },
    },
    colors: [
      WORK_ORDER_STATUS_COLORS.SCHEDULED,
      WORK_ORDER_STATUS_COLORS.WAITING_FOR_REVIEW,
    ],
  };
};

export const openWorkOrdersStatisticsOptionsV2: any = (
  callback?: (type: string, category: string) => void,
) => {
  return {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false, // Hide the toolbar
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          const category = config.w.config.xaxis.categories[dataPointIndex];
          const seriesName = config.w.config.series[seriesIndex].name;

          const status =
            seriesName === "Scheduled" ? "PENDING" : capitalize(seriesName);
          if (callback) {
            callback(capitalize(status) as any, category);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadiusApplication: "end",
        borderRadius: 3,
        borderRadiusWhenStacked: "end",
        distributed: false,
        horizontal: true,
        barGap: 0,
        dataLabels: {
          enabled: false, // Disable individual data labels for each series
          total: {
            enabled: true, // Enable the total data label
            offsetX: 0, // Adjust the horizontal position
            offsetY: 6, // Adjust the horizontal position
            style: {
              fontSize: "11px",
              fontWeight: "normal", // Adjust font weight if needed
            },
            formatter: function (val: any) {
              return val > 0 ? val : ""; // Display the total value
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"], // Add borders to bars
    },
    xaxis: {
      categories: [
        "PM",
        "CM",
        "Task",
        "Damage",
        "Inspection",
        "Safety",
        "Deficiency",
      ],
      /*  title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          fontWeight: 400,
        },
      }, */
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: undefined, // No y-axis title
      },
    },
    dataLabels: {
      enabled: false, // Globally disable data labels
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toString(); // Format tooltip values
        },
      },
    },
    fill: {
      opacity: 1, // Solid fill for bars
    },
    legend: {
      position: "top", // Position the legend at the top
      horizontalAlign: "left", // Align the legend to the left
      offsetX: 40, // Adjust the horizontal offset
    },
    colors: [
      WORK_ORDER_STATUS_COLORS.SCHEDULED,
      WORK_ORDER_STATUS_COLORS.WAITING_FOR_REVIEW,
      WORK_ORDER_STATUS_COLORS.DENIED,
    ], // Custom colors for each series
  };
};

export const overDueWorkOrdersOptions: any = (
  xLabels: any,
  callback?: (userId: string) => void,
) => {
  const categories = Array.isArray(xLabels?.openItems)
    ? xLabels.openItems.map(
        (item: any) => `${item?.first_name} ${item?.last_name}`,
      )
    : [];
  const userIds = Array.isArray(xLabels?.openItems)
    ? xLabels.openItems.map((item: any) => item?.id) // ✅ Store user IDs
    : [];

  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          const dataPointIndex = config.dataPointIndex;
          const userId = userIds[dataPointIndex];
          if (callback) {
            callback(userId);
          }
        },
      },
    },
    legend: {
      position: "top",
      fontSize: "12px",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        download: false,
        columnWidth: "50%",
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          //   color: "#333",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: ["#0D8D12", "#faa307"],
  };
};
export const barOverDueWorkChartOptions = (
  callback?: (category: string) => void,
) => {
  return {
    chart: {
      type: "bar",
      horizontal: false,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          // const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          // const seriesName = config.w.config.series[seriesIndex].name;
          const category = config.w.config.xaxis.categories[
            dataPointIndex
          ] as keyof typeof DueDateDelayFiltersStringToEnum;

          /*   console.log(
          `Clicked on series: ${seriesName}, category: ${category}`,
        ); */
          if (callback) {
            callback(category);
          }
          // Handle click event here
          // For example, you could redirect the user or show more details
        },
      },
    },

    legend: {
      position: "top",
      fontSize: "12px",
    },

    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 10,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["#000"],
      },
      offsetY: -22,
      formatter: (val: number) => (val === 0 ? "" : val),
    },

    xaxis: {
      categories: ["On Time", "<7 Days", "7 to 14 Days", "14+ Days"],
      labels: {
        rotate: -25,
        rotateAlways: true,
        style: {
          fontSize: "14px",
        },
      },
      title: {
        style: {
          fontSize: "14px",
          fontWeight: 400,
        },
        floating: false,
      },
    },

    yaxis: {
      title: {
        text: "Number of Days",
        style: {
          fontSize: "14px",
          fontWeight: 400,
        },
      },
      tickAmount: 5,
      min: 0,
    },

    colors: ["#34B53A", "#FFC53F", "#FF5C00", "#FF3A29"],

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
          },
        },
      },
    ],
  };
};
export const annualBarChartOptions: any = {
  chart: {
    type: "bar",
    horizontal: false,
    toolbar: {
      show: false,
    },
  },

  legend: {
    show: false,
  },

  plotOptions: {
    bar: {
      distributed: true,
      horizontal: false,
      columnWidth: "50%",
    },
  },
  xaxis: {
    categories: ["Spare Parts", "Work Orders", "Total"],
    labels: {
      style: {
        fontSize: "14px",
        // color: "#333",
      },
    },
  },
  yaxis: {
    tickAmount: 4,
    title: {
      text: "Cost (in thousands)",
      style: {
        fontSize: "14px",
        fontWeight: 400,
        // color: "#000000",
      },
    },
  },
  colors: ["#0D8D12", "#00337C", "#faa307"],
};

export const annualMaintenanceChartOptions = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },

  markers: {
    size: 6,
  },

  stroke: {
    curve: "smooth",
    width: 2,
  },

  legend: {
    position: "top",
    fontSize: "12px",
  },
  xaxis: {
    categories: MONTHS_2023,

    grid: {
      show: true,
    },
  },
  yaxis: {
    tickAmount: 4,
    min: 0,
    max: 1000,
    labels: {
      formatter: function (value: any) {
        return "$" + value.toFixed(2); // Format y-axis labels as currency with two decimal places
      },
    },
    title: {
      text: "Cost (in thousands)",
      style: {
        fontSize: "14px",
        fontWeight: 400,
        //   color: "#000000",
      },
    },
    grid: {
      show: true,
    },
  },
  colors: ["#0D8D12", "#00337C"],
  grid: {
    show: true,
  },
};

export const overDueWorkOrdersSeries = (
  openItems: IOpenItemsDetails[] | undefined,
) => {
  return [
    {
      name: AlectifyText.ON_TIME,
      data:
        openItems?.map(
          (data: IOpenItemsDetails) =>
            (data?.onTimePmCounts || 0) + (data?.onTimeTaskCounts || 0),
        ) || [],
    },
    {
      name: AlectifyText.OVERDUE,
      data:
        openItems?.map(
          (data: IOpenItemsDetails) =>
            (data?.overDuePmCounts || 0) + (data?.overDueTaskCounts || 0),
        ) || [],
    },
  ];
};

export const openWorkOrdersStatisticsSeriesV2 = (
  chart?: IWorkOrderSummaryResponse,
) => {
  return [
    {
      name: AlectifyText.SCHEDULED,
      data: [
        chart?.data?.PREVENTIVE_MAINTENANCE?.pending || 0,
        chart?.data?.CORRECTIVE_MAINTENANCE?.pending || 0,
        chart?.data?.TASK?.pending || 0,
        chart?.data?.DAMAGE.pending || 0,
        chart?.data?.INSPECTION.pending || 0,
        chart?.data?.SAFETY.pending || 0,
        chart?.data?.DEFICIENCY.pending || 0,
      ],
    },
    {
      name: AlectifyText.WAITING_FOR_REVIEW,
      data: [
        chart?.data?.PREVENTIVE_MAINTENANCE?.waitingForReview || 0,
        chart?.data?.CORRECTIVE_MAINTENANCE?.waitingForReview || 0,
        chart?.data?.TASK?.waitingForReview || 0,
        chart?.data?.DAMAGE.waitingForReview || 0,
        chart?.data?.INSPECTION.waitingForReview || 0,
        chart?.data?.SAFETY.waitingForReview || 0,
        chart?.data?.DEFICIENCY.waitingForReview || 0,
      ],
    },
    {
      name: AlectifyText.DENIED,
      data: [
        chart?.data?.PREVENTIVE_MAINTENANCE?.denied || 0,
        chart?.data?.CORRECTIVE_MAINTENANCE?.denied || 0,
        chart?.data?.TASK?.denied || 0,
        chart?.data?.DAMAGE.denied || 0,
        chart?.data?.INSPECTION.denied || 0,
        chart?.data?.SAFETY.denied || 0,
        chart?.data?.DEFICIENCY.denied || 0,
      ],
    },
  ];
};

export const openWorkOrdersStatisticsSeries = (
  pmCounts: IPmCounts | undefined | null,
) => {
  return [
    {
      name: AlectifyText.PM_EXTERNAL,
      data: [
        pmCounts?.pmExternal.pending || 0,
        pmCounts?.pmExternal?.waitingForReview || 0,
      ],
    },
    {
      name: AlectifyText.TASK,
      data: [pmCounts?.task.pending || 0, pmCounts?.task.waitingForReview || 0],
    },
    // {
    //   name: AlectifyText.PM_INTERNAL,
    //   data: [pmCounts?.pmInternal.pending || 0, 0],
    // },
  ];
};

export const openWorkOrdersStatisticsSeriesExternal = (pmState: IPmState) => {
  return [
    {
      name: AlectifyText.PM_EXTERNAL,
      data: [
        pmState.pmCounts?.pmExternal?.pending || 0,
        pmState.pmCounts?.pmExternal?.waitingForReview || 0,
      ],
    },
    {
      name: AlectifyText.TASK,
      data: [
        pmState.pmCounts?.task?.pending || 0,
        pmState.pmCounts?.task?.waitingForReview || 0,
      ],
    },
  ];
};

export const overDueWorkChartSeries = (data: IDashboardDetails | undefined) => {
  return [
    {
      name: "Orders",
      data: [
        data?.onTimeCounts || 0,
        data?.sevenDaysDueCounts || 0,
        data?.sevenToFourteenDaysDueCounts || 0,
        data?.plusFourteenDaysDueCounts || 0,
      ],
    },
  ];
};

export const annualBarChartData: IChartDataItem[] = [
  {
    name: AlectifyText.COST,
    data: [121.3, 452.2, 573.5],
  },
];

export const annualSeriesData = [
  {
    name: AlectifyText.SPARE_PARTS,
    data: [0, 1000, 80, 900, 80, 900, 80, 800, 100, 720, 100, 300],
  },
  {
    name: AlectifyText.WORK_ORDERS,
    data: [200, 280, 650, 210, 550, 300, 400, 800, 250, 100, 200, 300],
  },
];

export const getSparePartsUtilizationChartOptions = (
  sparePartsDashboardMonthlyCost: ISparePartDashboardMonthlyCost[],
) => {
  const options = {
    chart: {
      id: "realtime",
      height: 350,
      type: "line",
      animations: {
        enabled: false,
        easing: "linear",
        // dynamicAnimation: {
        //   speed: 1000,
        // },
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [4, 4],
      curve: "smooth",
    },
    markers: {
      size: [5, 5], // Set the size of the markers (dots)
      colors: ["#0500FF", "#FF3A29"], // Set the colors for each series
      strokeWidth: 2, // Set the stroke width around the markers
      shape: "circle", // Can be 'circle', 'square', etc.
    },
    // dataLabels: {
    //   enabled: true,
    //   // formatter: (val: any) => `$${val}`,
    // },
    xaxis: {
      categories:
        sparePartsDashboardMonthlyCost &&
        sparePartsDashboardMonthlyCost.map((d) => d.month),
    },
    yaxis: {
      title: {
        text: "CAD",
      },
      labels: {
        formatter: (val: any) => `${renderAmount(val)}`,
      },
    },
    legend: {
      position: "top",
    },
    colors: ["#0500FF", "#FF3A29"],
  };
  return options;
};

export const getSparePartsUtilizationChartSeries = (
  sparePartsDashboardMonthlyCost: ISparePartDashboardMonthlyCost[],
) => {
  const series = [
    {
      name: "Spare Parts Added (Received)",
      type: "line",
      data: sparePartsDashboardMonthlyCost.map((i: any) => i.restockTotal),
    },
    {
      name: "Spare Parts Withdrawn (Draw)",
      type: "line",
      data: sparePartsDashboardMonthlyCost.map((i: any) => i.drawTotal),
    },
  ];
  return series;
};
