export const GET_PROCEDURES = "/procedures";
export const GET_PROCEDURE_MASTER_PROJECTS = "/procedures/library";
export const PROCEDURE_CATEGORIES = `${GET_PROCEDURES}/categories`;
export const UPDATE_PM_STEPS = "/preventive-maintenances/step-status/{{id}}";
export const GET_PROCEDURE_STEPS = "/procedures/{id}/steps";
export const CREATE_PROCEDURE_STEP = "/procedures/steps";
export const UPDATE_PROCEDURE_STEP = "/procedures/steps/{id}";
export const STEPS_ORDER = "/procedures/steps-order";
export const UPLOAD_DOCS = "/user/upload-doc";
export const USER_DELETE_NAME_SPACE = "/user/delete-namespace";
export const CHECK_ALL_STEPS = "/preventive-maintenances/all-step-status/{id}";
