import {
  Breadcrumb,
  Col,
  DatePicker,
  Divider,
  message,
  Row,
  Typography,
} from "antd";
import AlectifyText from "static/texts.json";
import WorkOrderReports from "./work-order-reports/WorkOrderReports";
import "./Reports.scss";
import SelectMasterProject from "components/shared/master-project-select";
import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
import { useEffect, useState } from "react";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import AlectifyButton from "components/shared/button";
import { getDownloadAssetsFile } from "services/assets/assets.service";
import { isEmpty } from "lodash";
import { downloadSparePartsAsCSV } from "services/spare-parts/spare-parts.service";
import { IDataRoundProject } from "services/data-round/data-round.interface";
import logger from "utils/logger";
import {
  fetchDataRoundProjects,
  getRoundsReport,
  getSiteReport,
} from "services/data-round/data-round.service";
import { MESSAGES } from "constants/messages";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import DataRoundReports from "components/shared/data-rounds-reports";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";

const Reports: React.FC<any> = () => {
  const [assetLoader, setAssetLoader] = useState<boolean>(false);
  const [sparePartsLoader, setSparePartsLoader] = useState<boolean>(false);
  const [siteReportLoader, setSiteReportLoader] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const [selectedMasterProject, setSelectedMasterProject] =
    useState<IMasterProject | null>(null);
  const [selectedSubProject, setSelectedSubProject] =
    useState<ISubProject | null>(null);
  const [selectedMasterProjectForSp, setSelectedMasterProjectForSp] =
    useState<IMasterProject | null>(null);
  const [selectedSubProjectForSp, setSelectedSubProjectForSp] =
    useState<ISubProject | null>(null);
  const [selectedDataRound, setSelectedDataRound] =
    useState<IDataRoundProject | null>(null);
  const [dataRoundProjects, setDataRoundProjects] = useState<
    IDataRoundProject[]
  >([]);

  const { user } = useSelector((state: IRootState) => state.auth);
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );

  const downloadAssetCSV = async () => {
    setAssetLoader(true);
    try {
      await getDownloadAssetsFile(selectedSubProject?.id || "");
      setAssetLoader(false);
    } catch (ex) {
      setAssetLoader(false);
      console.log(ex);
    }
  };

  const handleDownloadSparePartsCsv = async () => {
    try {
      setSparePartsLoader(true);
      const params = {
        projectId: selectedMasterProjectForSp?.id,
      };
      const response = await downloadSparePartsAsCSV(params);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "alectify_spare_parts.csv");

      document.body.appendChild(link);

      link.click();
      link?.parentNode?.removeChild(link);

      setSparePartsLoader(false);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setSparePartsLoader(false);
    }
  };

  const getdataRoundProjects = async () => {
    try {
      const response = await fetchDataRoundProjects(
        selectedMasterProject?.id || "",
      );
      if (response.success) {
        setDataRoundProjects(response.data);
      }
    } catch (ex) {
      logger.log(ex as any);
    }
  };

  const generateSiteReport = async () => {
    setSiteReportLoader(true);
    message.loading("Report is being generated.");
    try {
      const formData = new FormData();
      formData.append("site_id", selectedMasterProject?.id || "");
      formData.append(
        "start_date",
        endDate ? dayjs(endDate).subtract(1, "month").format("YYYY-MM-DD") : "",
      );
      formData.append(
        "end_date",
        endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
      );
      formData.append("user_email", user?.email || "");
      formData.append(
        "user_name",
        `${user?.first_name} ${user?.last_name}` || "",
      );

      const response = await getSiteReport(formData);
      setSiteReportLoader(false);
      if (response.success) {
        // message.success(MESSAGES.EMAIL.EMAIL_SENT_ROUND_REPORT);
        ModalServiceInstance.open(AlectifyModal, {
          name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
          title: "Site Report",
          onCancel: () =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
            }),
          footer: null,
          children: (
            <ConfirmationModal
              okText="Ok"
              cancelText=""
              onCancel={() => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
                });
              }}
              onConfirm={() => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
                });
              }}
              message={MESSAGES.EMAIL.EMAIL_SENT_ROUND_REPORT}
              hideCancelButton
            />
          ),
        });
      }
    } catch (ex) {
      setSiteReportLoader(false);
      message.error("Couldn't generate report pelase try later.");
      logger.log(ex as any);
    }
  };

  const generateRoundsReport = async () => {
    const hideMessage = message.loading(
      MESSAGES.EMAIL.GENERATING_EMAIL_WAIT,
      0,
    ); // 0 means it won't auto-close
    try {
      const response = await getRoundsReport(selectedDataRound?.id || "");
      if (response.success) {
        hideMessage();
        // message.success(MESSAGES.EMAIL.EMAIL_SENT_ROUND_REPORT);
        ModalServiceInstance.open(AlectifyModal, {
          name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
          title: "Site Report",
          onCancel: () =>
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
            }),
          footer: null,
          children: (
            <ConfirmationModal
              okText="Ok"
              onConfirm={() => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
                });
              }}
              onCancel={() => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.SITE_REPORT_CONFIRMATION_MODAL,
                });
              }}
              message={MESSAGES.EMAIL.EMAIL_SENT_ROUND_REPORT}
              hideCancelButton
            />
          ),
        });
      }
    } catch (ex) {
      hideMessage();
      message.error(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      logger.error(ex as Error);
    }
  };
  const handleSelectMasterProject = (project: IMasterProject) => {
    setSelectedMasterProject(project);
  };
  const handleRoundSelect = (project: IDataRoundProject | null) => {
    setSelectedDataRound(null);
    setSelectedDataRound(project);
  };

  useEffect(() => {
    if (selectedMasterProject?.id) {
      getdataRoundProjects();
    }
  }, [selectedMasterProject?.id]);

  useEffect(() => {
    setSelectedDataRound(null);
    setSelectedMasterProject(activeMasterProject);
    setSelectedMasterProjectForSp(activeMasterProject);
  }, [activeMasterProject?.id]);
  return (
    <>
      <Row
        justify="start"
        align="top"
        gutter={[16, 16]}
        className="alectify-work-reports-container"
      >
        {/* Breadcrumb */}
        <Col span={24}>
          <Breadcrumb
            className=""
            items={[
              {
                title: "Home",
                href: `#`,
              },
              {
                title: AlectifyText.REPORTS,
              },
            ]}
          />
        </Col>

        {/* Work Orders Section */}
        {/* <Divider className="mb-0" /> */}
        <Col span={24}>
          <Row
            justify="start"
            align="top"
            gutter={[16, 16]}
            className="alectify-work-reports-child-container"
          >
            <>
              <Col span={24}>
                <Typography.Title level={4} className="m-0">
                  Site Report
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Row
                  justify={"start"}
                  align={"middle"}
                  gutter={16}
                  className="alectify-wo-asset-report-container"
                >
                  <Col span={4}>
                    <Typography.Text className="mt-0">
                      Generate monthly report
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <SelectMasterProject
                      key={activeMasterProject?.id}
                      onChange={(value: any) => setSelectedDataRound(null)}
                      onSelect={(value: string, record: any) => {
                        setSelectedMasterProject(record);
                        setSelectedDataRound(null);
                        setEndDate(null);
                      }}
                      defaultValue={activeMasterProject?.id}
                      showLabel={false}
                    />
                  </Col>
                  <Col span={4}>
                    <DatePicker
                      picker="date"
                      placeholder="Select end date"
                      onChange={(date) => setEndDate(date)}
                      disabled={!selectedMasterProject}
                      value={endDate && endDate}
                      size="large"
                      className="alectify-work-order-reports-date-picker"
                    />
                  </Col>
                  <Col span={4}>
                    <AlectifyButton
                      text={"Generate"}
                      type="primary"
                      className="alectify-primary-btn alectify-regular-button"
                      onClick={generateSiteReport}
                      loading={siteReportLoader}
                      disabled={!selectedMasterProject || !endDate}
                    />
                  </Col>
                </Row>
              </Col>
              <Divider className="m-0" />
              <Col span={24}>
                <Typography.Title level={4} className="mt-0">
                  Assets
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Row
                  justify={"start"}
                  align={"middle"}
                  gutter={16}
                  className="alectify-wo-asset-report-container"
                >
                  <Col span={4}>
                    <Typography.Text className="mt-0">
                      Assets list
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <SelectMasterProject
                      key={activeMasterProject?.id}
                      onChange={(value: any) => setSelectedSubProject(null)}
                      onSelect={(value: string, record: any) =>
                        setSelectedMasterProject(record)
                      }
                      defaultValue={activeMasterProject?.id}
                      showLabel={false}
                    />
                  </Col>
                  <Col span={4}>
                    <SelectSubProject
                      onSelect={(value: string, record: any) =>
                        setSelectedSubProject(record)
                      }
                      masterProjectId={selectedMasterProject?.id || ""}
                      showLabel={false}
                    />
                  </Col>
                  <AlectifyButton
                    text={"Download CSV"}
                    type="primary"
                    className="alectify-primary-btn alectify-regular-button"
                    loading={assetLoader}
                    disabled={
                      isEmpty(selectedMasterProject) ||
                      isEmpty(selectedSubProject)
                    }
                    onClick={downloadAssetCSV}
                  />
                </Row>
              </Col>
              <Divider className="m-0" />
              <Col span={24}>
                <Typography.Title level={4} className="mt-0">
                  Spare Parts
                </Typography.Title>
              </Col>
              <Divider className="m-0" />
              <Col span={24}>
                <Row
                  justify={"start"}
                  align={"middle"}
                  gutter={16}
                  className="alectify-wo-asset-report-container"
                >
                  <Col span={4}>
                    <Typography.Text>Spare parts list</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <SelectMasterProject
                      key={activeMasterProject?.id}
                      onChange={(value: any) =>
                        setSelectedSubProjectForSp(null)
                      }
                      onSelect={(value: string, record: any) =>
                        setSelectedMasterProjectForSp(record)
                      }
                      defaultValue={activeMasterProject?.id}
                      showLabel={false}
                    />
                  </Col>
                  <Col span={4}>
                    <SelectSubProject
                      onSelect={(value: string, record: any) =>
                        setSelectedSubProjectForSp(record)
                      }
                      masterProjectId={selectedMasterProjectForSp?.id || ""}
                      showLabel={false}
                    />
                  </Col>
                  <AlectifyButton
                    text={"Download CSV"}
                    type="primary"
                    className="alectify-primary-btn alectify-regular-button"
                    loading={sparePartsLoader}
                    disabled={
                      isEmpty(selectedMasterProjectForSp) ||
                      isEmpty(selectedSubProjectForSp)
                    }
                    onClick={handleDownloadSparePartsCsv}
                  />
                </Row>
              </Col>
              <Divider className="m-0" />
              <WorkOrderReports />
              <Divider className="m-0" />
              <Col span={24} className="alectify-report-data-round-container">
                <DataRoundReports
                  projectId={activeMasterProject?.id || ""}
                  showReadings={false}
                  rounds={dataRoundProjects}
                  selectedDataRound={selectedDataRound}
                  selectedMasterProject={SelectMasterProject as any}
                  handleSelectRound={handleRoundSelect}
                  generateRoundsReport={generateRoundsReport}
                  handleSelectMasterProject={handleSelectMasterProject}
                  isReportPage
                />
              </Col>
            </>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Reports;
