import { AxiosResponse } from "axios";
import { MULTIPART_HEADERS, pmHttp as http, llmHttp } from "utils/client";
import {
  IProcedureCategoriesResponse,
  IProcedureCreationResponse,
  IProcedureListResponse,
  IProcedureResponse,
  IProcedureStepsResponse,
  IStepsOrder,
} from "./procedures.interface";
import {
  CHECK_ALL_STEPS,
  CREATE_PROCEDURE_STEP,
  GET_PROCEDURES,
  GET_PROCEDURE_MASTER_PROJECTS,
  GET_PROCEDURE_STEPS,
  PROCEDURE_CATEGORIES,
  STEPS_ORDER,
  UPDATE_PM_STEPS,
  UPDATE_PROCEDURE_STEP,
  UPLOAD_DOCS,
  USER_DELETE_NAME_SPACE,
} from "./procedures.endpoints";
import { IParams } from "redux/interfaces";

/**
 * Gets the Procedures library list
 * @returns { Promise<IProcedureListResponse> } promise which resolves to IProcedureListResponse
 */
export const fetchProcedures = async (
  params?: IParams,
): Promise<IProcedureListResponse> => {
  try {
    const response: AxiosResponse<IProcedureListResponse> = await http.get(
      GET_PROCEDURES,
      params,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Gets the single Procedure library
 * @param { string } id - id of a particular procedure to fetch.
 * @returns { Promise<IProcedureResponse> } promise which resolves to IProcedureResponse
 */
export const fetchProcedure = async (
  id: string,
): Promise<IProcedureResponse> => {
  try {
    const response: AxiosResponse<IProcedureResponse> = await http.get(
      `${GET_PROCEDURES}/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Gets the single Procedure library for master project
 * @param { string } id - id of a particular procedure to fetch.
 * @returns { Promise<IProcedureResponse> } promise which resolves to IProcedureResponse
 */
export const fetchMasterProjectProcedure = async (
  id: string,
): Promise<IProcedureResponse> => {
  try {
    const response: AxiosResponse<IProcedureResponse> = await http.get(
      `${GET_PROCEDURE_MASTER_PROJECTS}/${id}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Gets the single Procedure library
 * @param { string } id - id of a particular procedure to fetch.
 * @returns { Promise<IProcedureResponse> } promise which resolves to IProcedureResponse
 */
export const fetchProcedureSteps = async (
  id: string,
): Promise<IProcedureStepsResponse> => {
  try {
    const response: AxiosResponse<IProcedureStepsResponse> = await http.get(
      `${GET_PROCEDURE_STEPS.replace("{id}", id)}`,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Gets the Procedures library Categories
 * @returns { Promise<IProcedureCategoriesResponse> } promise which resolves to IProcedureResponse
 */
export const fetchProcedureCategories = async (
  params: IParams,
): Promise<IProcedureCategoriesResponse> => {
  try {
    const response: AxiosResponse<IProcedureCategoriesResponse> =
      await http.get(PROCEDURE_CATEGORIES, params);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new Procedure
 * @param { IProcedureCreationPayload } payload - accepts an argument for creation payload
 * @returns { Promise<IProcedureCreationResponse> } promise which resolves to IProcedureResponse
 */
export const createProdure = async (
  payload: FormData,
): Promise<IProcedureCreationResponse> => {
  try {
    const response: AxiosResponse<IProcedureCreationResponse> = await http.post(
      GET_PROCEDURES,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new Procedure Step
 * @param { IProcedureCreationPayload } payload - accepts an argument for creation payload
 * @returns { Promise<IProcedureStepsResponse> } promise which resolves to IProcedureStepsResponse
 */
export const createProcedureSteps = async (
  payload: FormData,
): Promise<IProcedureStepsResponse> => {
  try {
    const response: AxiosResponse<IProcedureStepsResponse> = await http.post(
      CREATE_PROCEDURE_STEP,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Update Procedure Step
 * @param { IProcedureCreationPayload } payload - accepts an argument for creation payload
 * @returns { Promise<IProcedureStepsResponse> } promise which resolves to IProcedureStepsResponse
 */
export const updateProcedureSteps = async (
  id: string,
  payload: FormData,
): Promise<IProcedureStepsResponse> => {
  try {
    const response: AxiosResponse<IProcedureStepsResponse> = await http.put(
      UPDATE_PROCEDURE_STEP.replace("{id}", id),
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * delete Procedure Step
 * @param { string } id - id of a procedure steps to be deleted.
 * @returns { Promise<IProcedureStepsResponse> } promise which resolves to IProcedureStepsResponse
 */
export const deleteProcedureSteps = async (
  id: string,
): Promise<IProcedureStepsResponse> => {
  try {
    const response: AxiosResponse<IProcedureStepsResponse> = await http.delete(
      UPDATE_PROCEDURE_STEP.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Updates a given Procedure.
 * @param { IProcedureCreationPayload } payload - accepts an argument for creation payload
 * @returns { Promise<IProcedureListResponse> } promise which resolves to IProcedureResponse
 */
export const updateProcedure = async (
  id: string,
  payload: FormData,
): Promise<IProcedureListResponse> => {
  try {
    const response: AxiosResponse<IProcedureListResponse> = await http.put(
      `${GET_PROCEDURES}/${id}`,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Updates PM steps of attached procedure.
 * @param { IUpdateStepsPayload } payload - payload for updating the pm procedure steps.
 * @returns { Promise<any> } - promise which resolves to any.
 */
export const updatePMSteps = async (
  pmId: string,
  payload: FormData,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.patch(
      UPDATE_PM_STEPS.replace("{{id}}", pmId),
      payload,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Reorders procedure steps.
 * @param { string } procedureLibraryId - payload for updating the pm procedure steps.
 * @returns { Promise<any> } - promise which resolves to any.
 */
export const updateOrder = async (
  procedureLibraryId: string,
  payload: IStepsOrder[],
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.put(
      `${STEPS_ORDER}/${procedureLibraryId}`,
      { StepOrderDto: payload },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/***
 * Uploads a file with auto-create feature using FormData payload.
 * @param payload FormData containing the file to upload.
 * @returns Promise<IProcedureStepsResponse> Response data from the upload operation.
 */
export const uploadAutoCreateFile = async (
  payload: FormData,
): Promise<IProcedureStepsResponse> => {
  try {
    const response: AxiosResponse<any> = await llmHttp.post(
      UPLOAD_DOCS,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/***
 * Deletes a user's files.
 * @param payload Object containing userId as string.
 * @returns Promise<any> Response data from the deletion operation.
 */
export const deleteUserNameSpace = async (payload: {
  userId: string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await llmHttp.delete(
      USER_DELETE_NAME_SPACE,
      payload,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Updates all PM steps of attached procedure.
 * @returns { Promise<any> } - promise which resolves to any.
 */
export const checkAllStatus = async (
  pmId: string,
  payload: {
    status: boolean;
  },
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.patch(
      CHECK_ALL_STEPS.replace("{id}", pmId),
      payload,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
