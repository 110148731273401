import React from "react";
import { Space, Tooltip } from "antd";
import { displayDateTime, enumToTile, getFullUserName } from "utils/helpers";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import AlectifyDownloadDocument from "components/shared/download-document";
import { IPmAttachmentDocuments } from "redux/components/pm-internal";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import DeleteIcon from "components/icons/DeleteIcon";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import { WarningIcon } from "components/icons";
import PreviewFile from "components/shared/preview-file";

interface IDocumentsPmItemsColumns {
  deleteDocument?: (id: string) => void;
  isFuture?: boolean;
  isMaster?: boolean;
}
const DocumentsPmItemsColumns = (props: IDocumentsPmItemsColumns) => {
  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.FOLDER,
          dataIndex: "folder",
          searchable: true,
          width: 100,
          visible: !props.isMaster,
          ellipsis: {
            showTitle: false,
          },
          render: (folder: any) =>
            (folder && (
              <Tooltip placement="topLeft" title={enumToTile(folder)}>
                {enumToTile(folder)}
              </Tooltip>
            )) ||
            "-",
        },
        {
          title: AlectifyText.FILE_NAME,
          dataIndex: "fileName",
          searchable: true,
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (_: any, asset: IPmAttachmentDocuments) => (
            <PreviewFile
              fileName={asset?.fileName}
              fileUrl={asset?.filePath}
              isActivevalue={asset?.isActive}
            />
          ),
        },
        {
          title: AlectifyText.UPLOADED_BY,
          dataIndex: "uploadedBy",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (created_by: any) =>
            (created_by && (
              <Tooltip placement="topLeft" title={getFullUserName(created_by)}>
                {getFullUserName(created_by)}
              </Tooltip>
            )) ||
            "-",
        },
        {
          title: AlectifyText.UPLOADED_AT,
          dataIndex: "createdAt",
          key: "createdAt",
          searchable: true,
          width: 150,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (created_at: any) => displayDateTime(created_at, true),
        },

        {
          title: AlectifyText.ACTIONS,
          align: "center",
          dataIndex: "",
          visible: !props.isFuture,
          ellipsis: {
            showTitle: false,
          },
          width: 80,
          render: (_: any, asset: IPmAttachmentDocuments) => {
            const confirmationModal = () => {
              ModalServiceInstance.open(AlectifyModal, {
                name: MODAL_NAMES.PM_DOCUMENTS_DELETE_CONFIRMATION_MODAL,
                title: "Delete Documents",
                footer: false,
                onCancel: () => {
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.PM_DOCUMENTS_DELETE_CONFIRMATION_MODAL,
                  });
                },
                children: (
                  <ConfirmationModal
                    message={MESSAGES.PM_DOCS.DELETE_DOCUMENT}
                    icon={WarningIcon}
                    onCancel={() =>
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.PM_DOCUMENTS_DELETE_CONFIRMATION_MODAL,
                      })
                    }
                    onConfirm={() => {
                      props.deleteDocument && props.deleteDocument(asset.id);
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.PM_DOCUMENTS_DELETE_CONFIRMATION_MODAL,
                      });
                    }}
                  />
                ),
              });
            };
            return asset.isActive ? (
              <Space>
                <AlectifyDownloadDocument
                  file_name={asset.fileName}
                  file_path={asset.filePath}
                />
                {!props.isMaster && (
                  <Tooltip
                    title={AlectifyText.DELETE}
                    className="cursor-pointer"
                  >
                    <div onClick={confirmationModal}>
                      <DeleteIcon />
                    </div>
                  </Tooltip>
                )}
              </Space>
            ) : (
              AlectifyText.DELETED
            );
          },
        },
      ].filter((column) => column.visible),
    [],
  );
};

export default DocumentsPmItemsColumns;
