import { memo, useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  notification,
  Row,
  Tooltip,
  Typography,
} from "antd";
import AlectifySelect from "../select";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import { useParams } from "react-router-dom";
import {
  getDRProjectDetails,
  getShiftReport,
  getShiftReportOnRange,
} from "services/data-round/data-round.service";
import {
  IDataRoundProject,
  IShiftSchedule,
} from "services/data-round/data-round.interface";
import { RangePickerProps } from "antd/es/date-picker";
import { DatePickerProps } from "antd/lib";
import { IDataRoundReports } from "./DataRoundsReports.interface";
import { MESSAGES } from "constants/messages";
import DataRoundAnalytics from "pages/data-round/dr-project-details/tabs/DataRoundAnalytics/DataRoundAnalytics";
import "./DataRoundsReports.scss";
import { IDataRoundReportsProps } from "pages/data-round/dr-project-details/DataRoundProjectDetails.interface";
import SelectMasterProject from "../master-project-select";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";

const { RangePicker } = DatePicker;

const DataRoundReports: React.FC<IDataRoundReportsProps> = (
  props: IDataRoundReportsProps,
) => {
  const { projectId } = useParams();
  const [project, setProject] = useState<IDataRoundProject | null>(null);
  const [formValues, setFormValues] = useState<IDataRoundReports>({
    shift_date: null,
    shift: null,
    range: null,
    start_date: null,
    end_date: null,
  });

  const [isLoading, setIsLoading] = useState({
    shift: false,
    range: false,
  });

  const { common } = useSelector((state: IRootState) => state);

  const [FormInstance] = Form.useForm();

  const handleValuesChange = (changedValues: any) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      ...changedValues,
    }));
  };

  const onChangeShiftDate: DatePickerProps["onChange"] = (date, dateString) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      shift_date: dateString,
    }));
  };

  const onShiftSelection = (value: string) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      shift: value,
    }));
  };

  const downloadShiftReport = async () => {
    try {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: true,
      }));
      if (projectId || props.selectedDataRound?.id) {
        const params = {
          shift_date: formValues?.shift_date || "-",
          shift: formValues.shift || "-",
        };
        const response = await getShiftReport(
          projectId || props.selectedDataRound?.id || "",
          params,
        );
        if (response?.message) {
          notification.info({
            message: response?.message,
          });
        }
        if (response?.data?.encoded && response?.data?.file_name) {
          const csvContent = atob(response.data.encoded);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const link = document.createElement("a");

          link.href = URL.createObjectURL(blob);
          link.download = response.data.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: false,
      }));
    }
  };

  const downloadShiftRangeReport = async () => {
    try {
      setIsLoading((prevState) => ({
        ...prevState,
        range: true,
      }));
      if (projectId || props.selectedDataRound?.id) {
        const params = {
          start_date: formValues?.start_date,
          end_date: formValues?.end_date,
        };
        const response = await getShiftReportOnRange(
          projectId || props.selectedDataRound?.id || "",
          params,
        );
        if (response.message) {
          notification.info({
            message: response.message,
          });
        }
        if (response?.data?.encoded && response?.data?.file_name) {
          const csvContent = atob(response.data.encoded);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = response.data.file_name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        range: false,
      }));
    }
  };

  const onDateRangeSelect: RangePickerProps["onChange"] = (
    dates,
    dateStrings,
  ) => {
    setFormValues((prevState: IDataRoundReports) => ({
      ...prevState,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    }));
  };

  const getProjectDetails = async () => {
    try {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: true,
        range: true,
      }));
      const response = await getDRProjectDetails(
        projectId || props.selectedDataRound?.id || "",
      );
      if (response.success) {
        setProject(response.data);
      }
      setIsLoading((prevState) => ({
        ...prevState,
        shift: false,
        range: false,
      }));
    } catch (ex) {
      setIsLoading((prevState) => ({
        ...prevState,
        shift: false,
        range: false,
      }));
      console.log(ex);
    }
  };

  useEffect(() => {
    if (projectId || props.selectedDataRound?.id) {
      getProjectDetails();
    }
  }, [projectId || props.selectedDataRound?.id]);

  useEffect(() => {}, [common.activeMasterProject?.id]);
  return (
    <>
      <Typography.Title level={4} className="mt-5">
        {props.isReportPage ? AlectifyText.ROUNDS : AlectifyText.REPORTS}
      </Typography.Title>

      <div className="reports-container">
        <Row
          className="data-report-container"
          align={"middle"}
          justify={"start"}
          gutter={props.isReportPage ? [16, 16] : 0}
        >
          {props.isReportPage && (
            <Col span={24}>
              <Row
                className="data-report-container"
                align={"middle"}
                justify={"start"}
                gutter={[16, 16]}
              >
                <Col span={6}>
                  <SelectMasterProject
                    key={common.activeMasterProject?.id}
                    onChange={(value: any) =>
                      props.handleSelectRound && props.handleSelectRound(null)
                    }
                    onSelect={(value: string, record: any) => {
                      props.handleSelectMasterProject &&
                        props.handleSelectMasterProject(record);
                    }}
                    defaultValue={common?.activeMasterProject?.id}
                    showLabel={false}
                  />
                </Col>
                <Col span={6}>
                  <AlectifySelect
                    placeholder={"Select Round"}
                    name="masterProject"
                    label={""}
                    loading={false}
                    key={
                      common?.activeMasterProject?.id ||
                      props.selectedDataRound?.id
                    }
                    onSelect={(e, record) => {
                      props.handleSelectRound &&
                        props.handleSelectRound(record as any);
                    }}
                    // disabled={!selectedMasterProject}
                    // onChange={(e, record) => {
                    //   console.log("onchange", e, record);
                    // }}
                    value={props.selectedDataRound && props.selectedDataRound}
                    options={
                      (props.rounds &&
                        props.rounds.map((value: IDataRoundProject) => ({
                          ...value,
                          key: value.id,
                          label: value.name,
                          value: value.id,
                        }))) ||
                      []
                    }
                    className="custom-select"
                    allowClear
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={props.isReportPage ? 4 : 8}>
            <Typography.Text>
              {props.isReportPage
                ? AlectifyText.GENERATE_REPORT_SINCE_INCEPTION
                : MESSAGES.ROUNDS.GENERATE_REPORT_Round}
            </Typography.Text>
          </Col>
          {/* {props.isReportPage ? (
            <>
              <Col span={4}>
                <SelectMasterProject
                  key={common.activeMasterProject?.id}
                  onChange={(value: any) =>
                    props.handleSelectRound && props.handleSelectRound(null)
                  }
                  onSelect={(value: string, record: any) => {
                    props.handleSelectMasterProject &&
                      props.handleSelectMasterProject(record);
                  }}
                  defaultValue={common?.activeMasterProject?.id}
                  showLabel={false}
                />
              </Col>
              <Col span={4}>
                <AlectifySelect
                  placeholder={"Select Round"}
                  name="masterProject"
                  label={""}
                  loading={false}
                  key={
                    common?.activeMasterProject?.id ||
                    props.selectedDataRound?.id
                  }
                  onSelect={(e, record) => {
                    props.handleSelectRound &&
                      props.handleSelectRound(record as any);
                  }}
                  // disabled={!selectedMasterProject}
                  // onChange={(e, record) => {
                  //   console.log("onchange", e, record);
                  // }}
                  value={props.selectedDataRound && props.selectedDataRound}
                  options={
                    (props.rounds &&
                      props.rounds.map((value: IDataRoundProject) => ({
                        ...value,
                        key: value.id,
                        label: value.name,
                        value: value.id,
                      }))) ||
                    []
                  }
                  className="custom-select"
                  allowClear
                />
              </Col>
              <Col span={3}>
                <div className="download-btn-data-rounds">
                  <AlectifyButton
                    onClick={props.generateRoundsReport}
                    text={AlectifyText.GENERATE_REPORT}
                    type="primary"
                    className="alectify-primary-btn"
                    disabled={
                      isEmpty(props.selectedDataRound) ||
                      isEmpty(props.selectedMasterProject)
                    }
                  />
                </div>
              </Col>
            </>
          ) : ( */}
          <Col span={3}>
            <Tooltip
              title={
                props.isReportPage
                  ? (!common.activeMasterProject?.id ||
                      !props.selectedDataRound) &&
                    "Please Select Site & Round"
                  : ""
              }
            >
              <div className="download-btn-data-rounds">
                <AlectifyButton
                  onClick={props.generateRoundsReport}
                  text={AlectifyText.GENERATE_REPORT}
                  type="primary"
                  className="alectify-primary-btn"
                  disabled={
                    props.isReportPage
                      ? !common.activeMasterProject?.id ||
                        !props.selectedDataRound
                      : false
                  }
                />
              </div>
            </Tooltip>
          </Col>
          {/* )} */}
        </Row>
        <Form
          className="data-rounds-reports-form"
          name="data-rounds-reports-form"
          form={FormInstance}
          onValuesChange={handleValuesChange}
        >
          <Row
            gutter={16}
            align={"middle"}
            justify={"start"}
            className="data-report-container"
          >
            <Col span={props.isReportPage ? 4 : 8}>
              <Typography.Text>
                {MESSAGES.ROUNDS.GENERATE_REPORT_SHIFT}
              </Typography.Text>
            </Col>
            <Col span={props.isReportPage ? 4 : 3}>
              <Form.Item name="shift_date">
                <Tooltip
                  title={
                    props.isReportPage &&
                    (!common.activeMasterProject?.id ||
                      !props.selectedDataRound)
                      ? "Please select Site & Round"
                      : ""
                  }
                >
                  <DatePicker
                    className={`date-picker-container ${
                      props.isReportPage ? "width-100" : ""
                    }`}
                    onChange={onChangeShiftDate}
                    placeholder={`${AlectifyText.DATE}`}
                    disabled={
                      props.isReportPage
                        ? !common.activeMasterProject?.id ||
                          !props.selectedDataRound
                        : false
                    }
                  />
                </Tooltip>
              </Form.Item>
            </Col>
            <Tooltip
              title={
                props.isReportPage &&
                (!common.activeMasterProject?.id || !props.selectedDataRound)
                  ? "Please select Site & Round"
                  : ""
              }
            >
              <Col span={props.isReportPage ? 4 : 3}>
                <Form.Item name="shift">
                  <AlectifySelect
                    name="shift"
                    placeholder={`${AlectifyText.SHIFT}`}
                    onChange={onShiftSelection}
                    loading={isLoading.shift}
                    disabled={
                      props.isReportPage
                        ? !common.activeMasterProject?.id ||
                          !props.selectedDataRound
                        : false
                    }
                    options={
                      project?.shift_schedules?.map(
                        (shift: IShiftSchedule) => ({
                          label: shift.name,
                          key: shift?.id,
                          value: shift.id,
                        }),
                      ) || []
                    }
                  />
                </Form.Item>
              </Col>
            </Tooltip>
            <Col span={props.isReportPage ? 8 : 10}>
              <Tooltip
                title={
                  props.isReportPage
                    ? (!common.activeMasterProject?.id ||
                        !props.selectedDataRound) &&
                      "Please Select Site & Round"
                    : ""
                }
              >
                <div className="download-btn-data-rounds">
                  <AlectifyButton
                    loading={isLoading.shift}
                    onClick={downloadShiftReport}
                    text={"Download CSV"}
                    type="primary"
                    className="alectify-primary-btn"
                    disabled={
                      !(formValues.shift && formValues.shift_date) ||
                      !common.activeMasterProject?.id ||
                      !props.selectedDataRound
                    }
                  />
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Form>

        <Form
          className="data-rounds-reports-form"
          name="data-rounds-reports-form"
          form={FormInstance}
        >
          <Row
            gutter={16}
            align={"middle"}
            justify={"start"}
            className="data-report-container"
          >
            <Col span={props.isReportPage ? 4 : 8}>
              <Typography.Text>
                {MESSAGES.ROUNDS.GENERATE_REPORT_RANGE}
              </Typography.Text>
            </Col>
            <Col span={props.isReportPage ? 8 : 6}>
              <Form.Item name="range">
                <Tooltip
                  title={
                    props.isReportPage &&
                    (!common.activeMasterProject?.id ||
                      !props.selectedDataRound)
                      ? "Please select Site & Round"
                      : ""
                  }
                >
                  <RangePicker
                    className="round-range-picker"
                    onChange={onDateRangeSelect}
                    disabled={
                      props.isReportPage
                        ? !common.activeMasterProject?.id ||
                          !props.selectedDataRound
                        : false
                    }
                  />
                </Tooltip>
              </Form.Item>
            </Col>

            <Col span={props.isReportPage ? 8 : 10}>
              <Tooltip
                title={
                  props.isReportPage &&
                  (!common.activeMasterProject?.id || !props.selectedDataRound)
                    ? "Please select Site & Round"
                    : ""
                }
              >
                <div className="download-btn-data-rounds">
                  <AlectifyButton
                    loading={isLoading.range}
                    onClick={downloadShiftRangeReport}
                    text={"Download CSV"}
                    type="primary"
                    className="alectify-primary-btn"
                    disabled={!(formValues.start_date && formValues.end_date)}
                  />
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </div>

      {props.showReadings && (
        <>
          <Typography.Title level={4} className="mt-15">
            {AlectifyText.READINGS}
          </Typography.Title>

          <div className="mt-10 p-10">
            <DataRoundAnalytics />
          </div>
        </>
      )}
    </>
  );
};
DataRoundReports.defaultProps = {
  showReadings: true,
  isReportPage: false,
  rounds: [],
  selectedDataRound: null,
  selectedMasterProject: null,
  generateRoundsReport: () => {},
  handleSelectMasterProject: () => {},
};
export default memo(DataRoundReports);
