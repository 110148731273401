import { IAlectifyModalProps } from "components/shared/modal/AlectifyModal.interface";
import React from "react";
import { IModalService } from "./ModalService.interface";

class ModalService implements IModalService {
  on = (event: string, callback: Function) => {
    document.addEventListener(event, (e: any) => callback(e.detail));
  };

  open = (
    component: React.FunctionComponent<any>,
    props: IAlectifyModalProps,
  ) => {
    document.dispatchEvent(
      new CustomEvent("openModal", { detail: { component, props } }),
    );
  };

  close = (component: React.FunctionComponent<any>, props = {}) => {
    document.dispatchEvent(
      new CustomEvent("closeModal", { detail: { component, props } }),
    );
  };

  forceCloseAll = (exceptions: string[] = []) => {
    document.dispatchEvent(
      new CustomEvent("forceCloseAll", { detail: { exceptions } }),
    );
  };

  updateModalProps(name: string, newProps: Record<string, any>): void {
    document.dispatchEvent(
      new CustomEvent("updateModalProps", {
        detail: {
          name,
          props: newProps,
        },
      }),
    );
  }
  isModalOpen(name: string): boolean {
    const event = new CustomEvent("checkModalOpen", {
      detail: { name },
      cancelable: true,
    });
    document.dispatchEvent(event);
    // The event.defaultPrevented will be true if a modal with this name exists
    return event.defaultPrevented;
  }
}

const ModalServiceInstance = new ModalService();

export default ModalServiceInstance;
