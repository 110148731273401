import AlectifyText from "static/texts.json";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import { IRound } from "services/data-round/data-round.interface";
import { Avatar, Tooltip } from "antd";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { isEmpty } from "lodash";
import ViewImage from "components/shared/view-image/ViewImage";
import { DataItem, User } from "../RoundsDetailTable.interface";

const useCommentsTable = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.COMMENTS,
      key: "text",
      dataIndex: "text",
      ellipsis: true,
      width: 400,
      render: (value: string, record: IRound) => {
        return <Tooltip title={value || "-"}>{value}</Tooltip>;
      },
    },
    {
      title: AlectifyText.U_USERS,
      key: "user",
      dataIndex: "user",
      ellipsis: true,
      width: 60,
      align: "center",
      render: (user: User, record: DataItem) => {
        return (
          <Tooltip title={`${record.user.first_name} ${record.user.last_name}`}>
            <Avatar
              size="small"
              src={record.user.image || NO_IMAGE_AVATAR}
              className="cursor-pointer"
              onClick={() => {
                ModalServiceInstance.open(AlectifyModal, {
                  title: "Preview",
                  footer: null,
                  name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                  onCancel: () => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                    });
                  },
                  children: (
                    <ViewImage
                      imageUrl={record.user.image || NO_IMAGE_AVATAR}
                      isDownloadable={!isEmpty(record.user.image)}
                    />
                  ),
                });
              }}
            />
          </Tooltip>
        );
      },
    },
  ];
  return columns;
};

export default useCommentsTable;
