import AlectifyText from "static/texts.json";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import { IRound } from "services/data-round/data-round.interface";
import { Tooltip } from "antd";
import DownloadIcon from "components/icons/DownloadIcon";

const useDocumentsTable = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.DOCUMENTS,
      key: "file_name",
      dataIndex: "file_name",
      ellipsis: true,
      width: 400,
      render: (value: string, record: IRound) => {
        return <Tooltip title={value || "-"}>{value}</Tooltip>;
      },
    },

    {
      title: AlectifyText.ACTIONS,
      key: "signed_url",
      dataIndex: "signed_url",
      ellipsis: true,
      width: 60,
      align: "center",
      render: (signed_url: string) => {
        const openInNewTab = (e: React.MouseEvent) => {
          e.preventDefault();
          if (!signed_url) return;
          window.open(signed_url, "_blank");
        };

        return (
          <Tooltip title="Download">
            <div onClick={openInNewTab} className="cursor-pointer">
              <DownloadIcon />
            </div>
          </Tooltip>
        );
      },
    },
  ];
  return columns;
};

export default useDocumentsTable;
