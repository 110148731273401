export const DRAWER_CONSTANTS = {
  DRAWER_TASK_ASSET_DETAIL: "task-asset-detail-drawer",
  DRAWER_CREATE_NEW_TASK: "task-create-new-drawer",
  DRAWER_EDIT_TASK: "task-edit-drawer",
  DRAWER_TASK_UPLOAD: "task-upload-drawer",
  DRAWER_ASSET_OR_ASSET_PACKAGE: "asset-or-asset-package-drawer",
  DRAWER_TASK_DETAIL: "task-detail-drawer",
  DRAWER_COMMENTS: "comments-drawer",
  ATTACHMENTS_DRAWER: "attachments-drawer",
  CREATE_PM_DRAWER: "create-pm-internal-external-drawer",
  ASSET_PACKAGE_DETAIL_DRAWER: "asset-package-detail-drawer",
  DOCUMENTS_MAIN_DRAWER: "document-main-drawer",
  PM_INTERNAL_DETAIL_DRAWER: "pm-internal-detail-drawer",
  PM_EXTERNAL_DETAIL_DRAWER: "pm-external-detail-drawer",
  CREATE_SUB_PROJECT_DRAWER: "crete-sub-project",
  CHANGE_SUB_PROJECT_DRAWER: "change-subproject-drawer",
  CREATE_MASTER_PROJECT_DRAWER: "create-master-project-drawer",
  PM_INTERNAL_ASSET_ASSET_PACKAGE_DRAWER:
    "pm-internal-asset-asset-package-drawer",
  PM_EXTERNAL_ASSET_ASSET_PACKAGE_DRAWER:
    "pm-external-asset-asset-package-drawer",
  DRAWER_TODAY_TASK: "drawer-today-task",
  DRAWER_CREATE_NEW_CONTACT: "contact-create-new-drawer",
  ADD_ASSET_MANUAL: "add-asset-manual",
  ADD_ASSET_PACKAGE: "add-asset-package",
  DRAWER_SPARE_PART_DETAIL: "drawer-spare-part-drawer",
  DRAWER_PROCEDURE_CREATION: "drawer-procedure-creation",
  ADD_NEW_SPARE_PART_DRAWER: "add-new-spare-part-drawer",
  DRAWER_DRAW_SPARE_PART: "drawer-draw-spare-part",
  DRAWER_REFILL_SPARE_PART: "drawer-refill-spare-part",
  SIMILAR_PARTS_DRAWER: "similar-parts-drawer",
  DRAW_HISTORY_DRAWER: "draw-history-drawer",
  PROCEDURE_DETAIL_DRAWER: "drawer-procedure-detail",
  PACKAGE_ASSETS_DRAWER: "package-assets-drawer",
  MAINTENANCE_PREDICTION_DRAWER: "maintenance-prediction-drawer",
  PROCEDURE_DETAIL_DRAWERL: "procedure-detail-drawer",
  CHATBOT: "chat-bot",
  NOTIFICATIONS: "Notifications",
  CREATE_INCIDENT_REPORT: "create-incident-report",
  INCIDENT_DETAIL_REPORT: "incident-detail-report",
  CREATE_NEW_DR_PROJECT: "create-new-data-round-project",
  CREATE_NEW_GROUP_CHECKPOINT: "create-new-group-checkpoint",
  MESSAGING_CENTER: "messaging-center",
  AI_SUMMARY_ASSET: "ai-recommendations-for-asset",
  AI_SUMMARY_SPARE_PARTS: "ai-recommendations-for-spare-parts",
  WORK_ORDER_DOCUMENTS_SELECTION_MODAL: "work-order-documents-selection-modal",
  SPARE_PARTS_ADVISORY_DRAWER: "spare-parts-advisory-drawer",
};
