import React, { useEffect, useState, useMemo } from "react";
import { get, isEmpty, truncate } from "lodash";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import { Document, Page, View, Text, BlobProvider } from "@react-pdf/renderer";

// Components
/* import PmExternalIconPDF from "components/icons/pdf-icons/PmExternalIconPdf";
import PmInternalIconPdf from "components/icons/pdf-icons/PmInternalIconPdf";
import TaskIconPDF from "components/icons/pdf-icons/TaskIconPdf"; */

import EventCompletedIconPdf from "components/icons/pdf-icons/EventCompletedIconPdf";
import EventCompletedPastDueDateIconPdf from "components/icons/pdf-icons/EventCompletedPastDueDateIconPdf";
import AlectifyButton from "components/shared/button";

// Helpers, interfaces & enums
import { formatDate } from "utils/helpers";
import { CalendarEventStatusEnum } from "enums";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import { styles } from "./CalendarPdfStyles";
import { ICalendarTemplateProps } from "./CalendarPdf.interface";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";

// Event icons mapping
/* const EVENT_ICONS_PDF = {
  [CalendarEventTypesEnum.TASKS]: <TaskIconPDF />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIconPDF />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIconPdf />,
}; */

// Status icons mapping
const EVENT_STATUS_ICONS = {
  [CalendarEventStatusEnum.DELAYED]: <></>,
  [CalendarEventStatusEnum.PENDING]: <></>,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: <EventCompletedIconPdf />,
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <EventCompletedPastDueDateIconPdf />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <></>,
};

// Border colors based on status
/* const EVENT_BORDER_COLOR: any = {
  [CalendarEventStatusEnum.DELAYED]: "#d92323",
  [CalendarEventStatusEnum.PENDING]: "#3e74dd",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "#43bd18",
  [CalendarEventStatusEnum.SKIPPED]: "#d7d7d7",
}; */

const WeeklyCalendarTemplate: React.FC<ICalendarTemplateProps> = (
  props: ICalendarTemplateProps,
) => {
  const { data } = props.calendarEvents;
  const { user } = useSelector((state: IRootState) => state.auth);
  const [columns, setColumns] = useState<{ [key: string]: ICalendarEvent[] }>(
    {},
  );

  // const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<React.ReactElement | null>(null);

  // useEffect to update columns based on calendar data
  useEffect(() => {
    const startOfWeek = new Date(props.startDate);
    const endOfWeek = new Date(props.endDate);
    const newColumns: { [key: string]: ICalendarEvent[] } = {}; // Explicitly typed

    for (
      let date = new Date(startOfWeek);
      date <= endOfWeek;
      date.setDate(date.getDate() + 1)
    ) {
      const key = date.toISOString().slice(0, 10);
      newColumns[key] =
        data?.filter((event) => {
          const eventStart = moment(event.start).format();
          const eventEnd = moment(event.end).format();
          return eventStart.slice(0, 10) <= key && key <= eventEnd.slice(0, 10);
        }) || []; // Ensure a default empty array if data is undefined
    }

    setColumns(newColumns);
    // setLoading(false);
  }, [data, props.startDate, props.endDate]);

  const getAssetName = (event: ICalendarEvent) => {
    return (Array.isArray(event.asset) && event.asset.length > 1) ||
      (Array.isArray(event.asset_package) && event.asset_package.length > 1) ||
      (Array.isArray(event.asset) &&
        event.asset.length === 1 &&
        Array.isArray(event.asset_package) &&
        event.asset_package.length === 1)
      ? "Multi Assets"
      : Array.isArray(event.asset) && event.asset.length === 1
      ? get(event.asset, "[0].asset.name")
      : Array.isArray(event.asset_package) && event.asset_package.length === 1
      ? get(event.asset_package, "[0].area.name")
      : "No Asset";
  };

  const additionalAssets = (event: ICalendarEvent) => {
    const additionalCount = event.asset.length + event.asset_package.length;
    return additionalCount > 1 ? `[${additionalCount}]` : "";
  };

  // Updating template on data change
  useEffect(() => {
    setTemplate(createDocument);
  }, [columns, user, props.startDate, props.endDate]);
  const createDocument = useMemo(
    () => (
      <Document>
        <Page key={"introPage"} orientation="landscape">
          <View style={styles.titlePageContainer}>
            <Text style={styles.titlePageHeading}>
              {AlectifyText.WEEKLY_WORK_ORDERS}
            </Text>
            <Text style={styles.titlePageSubHeading}>
              {moment(props.startDate).format("DD MMM")} -{" "}
              {moment(props.endDate).format("DD MMM, YYYY")}
            </Text>
            <Text style={styles.titlePageSubHeading}>
              Generated by: {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text style={styles.titlePageSubHeading}>
              {moment().format("MMMM DD, YYYY, h:mm A")}
            </Text>
          </View>
        </Page>

        <Page orientation="landscape">
          <View style={styles.container}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={styles.legendDateHeading}>
                <Text>
                  {moment(props.startDate).format("DD MMM")} -{" "}
                  {moment(props.endDate).format("DD MMM, YYYY")}
                </Text>
              </View>
              {/* <View style={styles.legendContainer}>
                <View style={styles.legend}>
                  <EventPendingIconPdf />{" "}
                  <Text style={{ marginLeft: 5 }}>Open (Future Due Date)</Text>
                </View>
                <View style={styles.legend}>
                  <EventDelayedIconPdf />{" "}
                  <Text style={{ marginLeft: 5 }}>Open (Over Due)</Text>
                </View>
                <View style={styles.legend}>
                  <EventCompletedIconPdf />{" "}
                  <Text style={{ marginLeft: 5 }}>Closed (On Time)</Text>
                </View>
                <View style={styles.legend}>
                  <EventCompletedPastDueDateIconPdf />{" "}
                  <Text style={{ marginLeft: 5 }}>
                    Closed (Due Date Passed)
                  </Text>
                </View>
                <View style={styles.legend}>
                  <EventSkippedIconPdf />{" "}
                  <Text style={{ marginLeft: 5 }}>Void</Text>
                </View>
              </View> */}
            </View>

            <View style={styles.row}>
              {Object.keys(columns).map((date, index) => (
                <View style={styles.column} key={`${date}-${index}`}>
                  <View style={styles.header}>
                    <Text style={styles.rowChild}>
                      {formatDate(dayjs(date), "dddd, DD MMM")}
                    </Text>
                  </View>
                  <View>
                    {columns[date]?.map((event) => (
                      <View
                        key={event.id}
                        style={{
                          ...styles.event,
                          backgroundColor: event.master_project[0].color,
                        }}
                      >
                        {/* <View
                          style={{
                            ...styles.eventIcon,
                            borderColor: EVENT_BORDER_COLOR[event.event_status],
                          }}
                        >
                          {
                            EVENT_ICONS_PDF[
                              event.type as CalendarEventTypesEnum
                            ]
                          }
                          <View style={styles.eventStatusIcon}>
                            {
                              EVENT_STATUS_ICONS[
                                event.event_status as CalendarEventStatusEnum
                              ]
                            }
                          </View>
                        </View> */}
                        <View style={styles.eventStatusIcon}>
                          {
                            EVENT_STATUS_ICONS[
                              event.event_status as CalendarEventStatusEnum
                            ]
                          }
                        </View>
                        <View style={styles.eventContent}>
                          <Text>
                            {truncate(event.title, {
                              length: 35,
                              omission: "...",
                            })}
                          </Text>
                          {/* <Text>
                                {event.asset?.[0]?.name ||
                                  event.asset_package?.[0]?.name ||
                                  "NA"}
                              </Text> */}
                          {/* <View> */}
                          <Text>
                            {truncate(getAssetName(event), {
                              length: 15,
                              omission: "...",
                            })}
                            {` ${
                              additionalAssets(event) && additionalAssets(event)
                            }`}
                          </Text>

                          {/* </View> */}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    ),
    [columns, user, props.startDate, props.endDate],
  );

  // useEffect(() => {
  //   setTemplate(createDocument);
  // }, [props.calendarEvents]);

  if (!template) {
    return (
      <AlectifyButton
        text={<span>{"Loading PDF..."}</span>}
        className="light-blue-button"
        type="primary"
      />
    );
  }

  return (
    <BlobProvider document={template}>
      {({ url, loading: pdfLoading }) => {
        // const isLoading = loading || pdfLoading;

        return (
          <AlectifyButton
            name="download-as-pdf"
            text={<span>Download Pdf</span>}
            loading={props.loader}
            disabled={isEmpty(data)}
            className="light-blue-button"
            type="primary"
            onClick={() => {
              if (url) {
                const link = document.createElement("a");
                link.href = url;
                link.download = `calendar-${moment().format("YYYY-MM-DD")}.pdf`;
                link.click();
              }
            }}
          />
        );
      }}
    </BlobProvider>
  );
};

export default WeeklyCalendarTemplate;
