import {
  DeleteOutlined,
  FolderOutlined,
  ImportOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Col, Row, Space, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IWorkOrderAttachmentProps } from "../WorkOrderCreateEdit.interface";
import AlectifyTable from "components/shared/table";
import { isEmpty } from "lodash";
import AlectifyButton from "components/shared/button";
import { fetchMasterWorkOrderDocuments } from "services/work-orders/work-order.service";
import { IPmAttachmentDocuments } from "redux/components/pm-internal";
import { IMetaNest, IParams } from "redux/interfaces";
import { fetchPrevMaintenanceDocuments } from "services/pm-internal/pm-internal.service";
import { PM_EDITING_TYPES } from "components/shared/pm-create-edit-form/PMCreateEditForm.constants";

const WorkOrderAttachments: React.FC<IWorkOrderAttachmentProps> = (
  props: IWorkOrderAttachmentProps,
) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<{
    data: IPmAttachmentDocuments[];
    loader: boolean;
    meta: IMetaNest;
  }>({
    data: [],
    loader: false,
    meta: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 5,
    },
  });
  const [deletedExistingFilesId, setDeletedExistingFilesId] = useState<
    string[]
  >([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFromComputer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      fileInputRef.current.onchange = (event: any) => {
        const files = event.target.files;
        if (files) {
          const filesArray = Array.from(files);
          setSelectedFiles(filesArray as File[]);
          props.handleFileUpload(filesArray);
        }
      };
    }
  };

  const displayRowClass = (record: any) => {
    return deletedExistingFilesId.includes(record.id) || !record.isActive
      ? "disable-row-allow-actions"
      : "";
  };

  const checkIfFileIsActiveFromProps = (file: any) => {
    const taskFiles: any = existingFiles.data || [];
    return taskFiles.some(
      (taskFile: any) =>
        taskFile.id === file.id && taskFile.isActive && file.isActive,
    );
  };

  const fetchAllMasterDocuments = async (page?: number) => {
    setExistingFiles((prev: any) => ({
      ...prev,
      loader: true,
    }));
    try {
      const params = {
        page: page || 1,
        limit: 50,
      };
      let response: any;
      if (props.editType === PM_EDITING_TYPES.ALL_EVENTS) {
        response = await fetchMasterWorkOrderDocuments(
          props.taskDetails?.id || "",
          params,
        );
      } else {
        response = await fetchPrevMaintenanceDocuments(
          props.taskDetails?.id || "",
          params as IParams,
        );
      }
      if (response.status) {
        setExistingFiles((prev: any) => ({
          ...prev,
          data: response.data,
          loader: false,
          meta: response.meta,
        }));
        props.handleSetMasterDocuments(response.data);
      }
    } catch (ex) {
      setExistingFiles((prev: any) => ({
        ...prev,
        loader: true,
      }));
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props.taskDetails?.id) {
      fetchAllMasterDocuments();
    }
  }, [props.taskDetails]);

  return (
    <div className="wo-create-schedule-container">
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <Space direction="vertical">
            <span className="alectify-work-order-section-card-heading">
              {props.editType === PM_EDITING_TYPES.ALL_EVENTS &&
              props.isRecurring
                ? "4. Recurring Work Order Attachments"
                : "4. Work Order Attachments"}
            </span>
            <span>
              {
                props.isRecurring ? (
                  "(These attachments will show on all occurrences of this work order)"
                ) : (
                  <></>
                )
                //  "(These attachments will be shown in only this work order)"
              }
            </span>
          </Space>
        </Col>
        <Col span={4} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">4/5</span>
        </Col>
      </Row>

      <Row justify="start" align="top" gutter={12} className="mt-10">
        <Col
          span={24}
          className="work-order-attachment-button text-align-center"
        >
          <Space>
            <AlectifyButton
              type="primary"
              text="Upload From Computer"
              className="light-blue-button mr-10"
              icon={<FolderOutlined />}
              onClick={handleUploadFromComputer}
            />
            <span>Or</span>
            <Tooltip
              title={
                props.masterProjectId
                  ? ""
                  : "Please select a Site to import documents"
              }
            >
              <AlectifyButton
                type="primary"
                text="Import From Site Documents"
                className="light-blue-button"
                icon={<ImportOutlined />}
                onClick={props.documentsModalHandler}
                disabled={!props.masterProjectId}
              />
            </Tooltip>
          </Space>
          {/* Antd Upload Component */}
          <input
            type="file"
            ref={fileInputRef}
            className="full-width-upload" // Add custom class for full width
            accept="*/*"
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files) {
                const filesArray = Array.from(e.target.files);
                setSelectedFiles(filesArray);
                props.handleFileUpload(filesArray);
              }
            }}
          />
        </Col>
      </Row>

      {(selectedFiles.length > 0 ||
        (props.importedDocuments && props.importedDocuments.length > 0)) && (
        <Row className="mt-10 alectify-work-order-attachments-table">
          <Col span={24}>
            <AlectifyTable
              tableTitle="Selected Files"
              dataSource={[
                ...selectedFiles.map((file, index) => ({
                  key: `file-${index}`,
                  name: file.name,
                  id: `file-${index}`,
                })),
                ...(props.importedDocuments || []).map((doc) => ({
                  key: `doc-${doc.document_id}`,
                  name: (doc as any).file_name,
                  ...doc,
                })),
              ]}
              onDataFetch={() => {}}
              total={0}
              columns={[
                {
                  title: "File Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Action",
                  key: "action",
                  width: 80,
                  render: (_, record) => (
                    <DeleteOutlined
                      onClick={() => {
                        if (String(record.id).startsWith("file-")) {
                          setSelectedFiles((prevFiles) =>
                            prevFiles.filter(
                              (_, i) => `file-${i}` !== record.id,
                            ),
                          );
                        } else {
                          props.handleRemoveImportedDocument(record.key);
                        }
                      }}
                    />
                  ),
                },
              ]}
              showPagination={false}
            />
          </Col>
        </Row>
      )}
      {!isEmpty(existingFiles) && props.isEdit && (
        <Row className="mt-10 alectify-work-order-attachments-table">
          <Col span={24}>
            <AlectifyTable
              tableTitle="Existing Files"
              dataSource={existingFiles.data || []}
              loading={existingFiles?.loader}
              onDataFetch={() => {}}
              total={0}
              displayRowClass={displayRowClass}
              columns={[
                {
                  title: "File Name",
                  dataIndex: "fileName",
                  key: "fileName",
                },
                {
                  title: "Action",
                  key: "action",
                  width: 80,
                  render: (_, record) => {
                    return !deletedExistingFilesId.includes(record.id) ||
                      !record.isActive ? (
                      checkIfFileIsActiveFromProps(record) ? (
                        <Tooltip title="Delete">
                          <DeleteOutlined
                            onClick={() => {
                              setDeletedExistingFilesId((prev) => [
                                ...prev,
                                record.id,
                              ]);
                              props.handleDeleteFiles(record.id);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        "Deleted"
                      )
                    ) : (
                      <Tooltip title="Restore">
                        <UndoOutlined
                          onClick={() => {
                            setDeletedExistingFilesId((prev) =>
                              prev.filter((id) => id !== record.id),
                            );
                            props.handleRestoreFiles(record.id);
                          }}
                        />
                      </Tooltip>
                    );
                  },
                },
              ]}
              showPagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default WorkOrderAttachments;
