import { CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyDrawer from "components/drawer";
import { PmExternalCircleIcon, TaskCircleIcon } from "components/icons";
import AlectifyBulletIcon from "components/icons/BulletIcon";
import StampCheckIcon from "components/icons/StampCheckIcon";
// import AssetDetail from "components/shared/asset-detail";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyButton from "components/shared/button";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
// import PackageDetailAssets from "components/shared/package-detail-assets";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { FrequencyTypeEnum } from "components/shared/pm-create-edit-form/PMCreateEditForm.interace";
import PmDetail from "components/shared/pm-detail";

// import ProcedureDetail from "components/shared/procedure-detail";
import AlectifyTable from "components/shared/table";
import {
  AssetPackageTag,
  AssetTag,
  CompletedTag,
  GenericTag,
  NonRecurringTag,
  RecurringTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import MultiAssetTag from "components/shared/tags/MultiAssetTag";
import NoAssetTag from "components/shared/tags/NoAssetTag";
import {
  ACTION_REQUIRED_COMPONENTS,
  PM_STATUS_COMPONENTS,
} from "components/shared/tasks-table/effects/useTasksColumns";
import TextToLink from "components/shared/text-to-link";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { MODAL_NAMES } from "constants/modal.constants";
import { PME_ACTION_REQUIRED_ENUM } from "enums";
import { get, isEmpty, truncate } from "lodash";
import moment from "moment";
// import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import { getPMEActionRequired } from "pages/work-order/WorkOrderCreateEdit.constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveMasterProject,
  setActiveSubProject,
} from "redux/components/common/sources";
import { IMasterProject } from "redux/components/master-project";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { ISubProject } from "redux/components/sub-project";
import { ITask } from "redux/components/tasks";
import { IPmAssignees } from "redux/interfaces";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import { IProcedure } from "services/procedures/procedures.interface";
import AlectifyText from "static/texts.json";
import {
  capitalizeFirstLetter,
  displayDateTime,
  enumToTile,
  getFullUserName,
  isExternalUser,
  truncateText,
} from "utils/helpers";

export const WORK_ORDER_ICONS = {
  [PM_TYPES.TASK]: <TaskCircleIcon />,
  [PM_TYPES.PM_INTERNAL]: <PmExternalCircleIcon fill="#D85F02" />,
  [PM_TYPES.PM_EXTERNAL]: <PmExternalCircleIcon fill="#D85F02" />,
  [PM_TYPES.ALL]: <PmExternalCircleIcon fill="#D85F02" />,
};

const DashboardOrdersColumns = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(({ auth }: IRootState) => auth);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
    }

    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
    });

    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
    });
  };

  const onSubProjectClick = (
    masterProject: IMasterProject,
    subProject: ISubProject,
  ) => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DASHBOARD_WORK_ORDERS_MODAL,
    });

    if (!isExternalUser(user)) {
      dispatch(setActiveSubProject(subProject));
      dispatch(setActiveMasterProject(masterProject));
      navigate(
        `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
      );
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
      });
    }
  };

  const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
    const isAllChecked =
      procedure.procedureStepCheckedTotalCount ===
      procedure.procedureStepTotalCount;
    return isAllChecked ? (
      <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
    ) : (
      <StampCheckIcon />
    );
  };

  const getDueDateStatus = (dueDate: Date) => {
    const datetimeUtc = moment.utc(dueDate);
    const userTimezone = moment.tz.guess();
    const datetime = datetimeUtc.clone().tz(userTimezone);

    const diffInDays = moment()
      .startOf("day")
      .diff(datetime.startOf("day"), "days");

    if (diffInDays <= 0) {
      return <CompletedTag text={"On-time"} />;
    } else if (diffInDays >= 1 && diffInDays <= 7) {
      return <WaitingForReviewTag text="< 7 Days" />;
    } else if (diffInDays >= 8 && diffInDays <= 14) {
      return <DangerTag color="#dc2f02" text="< 7 - 14 Days" />;
    } else {
      return <DangerTag color="#d00000" text="+14 Days" />;
    }
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.WORK_ORDER_TYPE.replace(":", ""),
      key: "pmType",
      dataIndex: "pmType",
      visible: true,
      width: 80,
      filters: props?.overDueTag
        ? [
            {
              text: AlectifyText.TASKS,
              value: PM_TYPES.TASK,
            },
            // {
            //   text: `PM Internal`,
            //   value: PM_TYPES.PM_INTERNAL,
            // },
            {
              text: AlectifyText.PM_EXTERNAL,
              value: PM_TYPES.PM_EXTERNAL,
            },
          ]
        : null,
      filterMultiple: false,
      render: (target: PM_TYPES, record: any) => (
        <Space align="center" size={5}>
          <Tooltip
            title={`${
              (record.taskCategory && enumToTile(record.taskCategory)) || "-"
            }`}
          >
            <span className="text-ellipsis">
              {`${
                (record.taskCategory && enumToTile(record.taskCategory)) || "-"
              }`}
            </span>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      width: 80,
      visible: true,
      render: (value: Date, pmExternal: IPmExternal) => {
        return (
          <Space direction="vertical" size={15}>
            {`Due: ${displayDateTime(value)}`}
            {!props?.overDueTag
              ? PM_STATUS_COMPONENTS[pmExternal.status]
              : getDueDateStatus(value)}
          </Space>
        );
      },
    },
    {
      key: "master-project",
      dataIndex: "project",
      title: AlectifyText.SITE_AND_ASSET_CATEGORY,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (target: any, record: any) => {
        const masterProject = record?.projects
          ? get(record, "projects[0]", target)
          : record?.project;
        const subProject = record?.sub_projects
          ? get(record, "sub_projects[0]", target)
          : record?.subProject;
        return (
          <Space direction="vertical" size={15}>
            {masterProject?.name ? (
              <Tooltip placement="topLeft" title={masterProject?.name}>
                {masterProject?.name
                  ? truncate(masterProject?.name, {
                      length: 20,
                      omission: "...",
                    })
                  : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
            <Tooltip placement="topLeft" title={subProject?.name}>
              <Space direction="horizontal" size={8}>
                <AlectifyBulletIcon />
                <TextToLink
                  className="text-to-link-options"
                  text={truncate(subProject?.name)}
                  underline={!isExternalUser(user)}
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 420,
                      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                      title: masterProject?.name,
                      closeIcon: <CloseOutlined />,
                      closable: true,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                        }),
                      children: (
                        <SwitchSubProjectDrawer
                          masterProjectId={masterProject.id}
                          navigate={navigate}
                        />
                      ),
                      customFooter: (
                        <Space>
                          <AlectifyButton
                            text={AlectifyText.VISIT_ASSET_CATEGORY}
                            type="default"
                            className="light-blue-button"
                            onClick={() =>
                              onSubProjectClick(masterProject, subProject)
                            }
                          />
                          <AlectifyButton
                            text={AlectifyText.VISIT_SITE}
                            className="ant-btn-primary"
                            onClick={() => onMasterProjectClick(masterProject)}
                          />
                        </Space>
                      ),
                    });
                  }}
                />{" "}
              </Space>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.WORKID_AND_TITLE,
      dataIndex: "workTitle",
      width: 150,
      visible: true,
      searchable: true,
      render: (value: string, pmExternal: IPmExternal) => {
        /*  const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closeIcon: <CloseOutlined />,
            closable: true,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              // props.fetchPmExternal(props.optionsPersisted as any);
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmExternal.procedure as any}
                taskId={pmExternal.id}
                readonly
                isDisabled={false}
              />
            ),
          });
        }; */
        return (
          <Space direction="vertical" size={15}>
            <TextToLink
              className="text-to-link-options"
              text={truncate(value)}
              onClick={() => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 480,
                  title: value,
                  name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                  closeIcon: <CloseOutlined />,
                  closable: true,
                  onClose: () =>
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                    }),
                  children: (
                    <PmDetail
                      pmExternalRecord={pmExternal}
                      pmId={pmExternal.id}
                      pmType={pmExternal.pmType}
                    />
                  ),
                });
              }}
            />
            {(!isEmpty(pmExternal.procedure) && (
              <Tooltip
                title={`${AlectifyText.PROCEDURE_CHECKLIST} ${pmExternal?.procedure?.procedureStepCheckedTotalCount}/${pmExternal?.procedure?.procedureStepTotalCount}`}
              >
                <span
                // className="cursor-pointer"
                // onClick={openProcedureCheckListDrawer}
                >
                  {truncate(pmExternal.workId)}{" "}
                  {!isEmpty(pmExternal.procedure) &&
                    getTaskStatusIcon(pmExternal?.procedure)}
                </span>
              </Tooltip>
            )) || <span>{truncate(pmExternal.workId)} </span>}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ASSET,
      key: "asset_type",
      dataIndex: "asset_type",
      width: 120,
      visible: true,
      // onCell,
      render: (_, record: ITask) => {
        const assetCount = record.assets.length || 0;
        const areaCount = record.areas.length || 0;
        const total = assetCount + areaCount || 0;
        const displayText =
          total > 1 ? (
            <strong>{`Multiple Assets [${total}]`} </strong>
          ) : (
            (record.assets[0] as any)?.asset?.name ||
            (record.areas[0] as any)?.area?.name ||
            "-"
          );
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15}>
            <Tooltip
              title={
                (!isEmpty(record.assets) || !isEmpty(record.areas)) && "Assets"
              }
            >
              {displayText === "-" ? (
                <span>{displayText}</span>
              ) : (
                <TextToLink
                  className="text-to-link-options"
                  text={displayText}
                  underline={!isGeneric}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isGeneric) {
                      const hasMultipleAssets = record.assets.length > 1;
                      const hasMultipleAreas = record.areas.length > 1;

                      if (hasMultipleAssets || hasMultipleAreas) {
                        // Open the modal with the list of assets and areas
                        ModalServiceInstance.open(AlectifyModal, {
                          name: MODAL_NAMES.ASSET_NAME_MODAL,
                          title: `Asset list of (${record.workTitle})`,
                          width: 800,
                          footer: null,
                          children: (
                            <AlectifyTable
                              onDataFetch={() => {}}
                              dataSource={[
                                ...record.assets.map((asset: any) => {
                                  return { ...asset.asset, type: "Tag" };
                                }),
                                ...record.areas.map((ar: any) => {
                                  return { ...ar.area, type: "PackageRoom" };
                                }),
                              ]}
                              total={[...record.assets, ...record.areas].length}
                              showPagination={false}
                              columns={[
                                {
                                  dataIndex: "name",
                                  title: "Name",
                                  width: 180,
                                  render: (value, assetRecord) => {
                                    const goToAssetDetails = () => {
                                      let url: string;
                                      if (assetRecord.type === "PackageRoom") {
                                        url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                                      } else {
                                        url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                                      }
                                      window.open(url, "_blank"); // Open in a new tab
                                    };
                                    return (
                                      <TextToLink
                                        text={
                                          <Space>
                                            <Tooltip title={value || "NA"}>
                                              <span>
                                                {truncateText(value, 29)}
                                              </span>
                                            </Tooltip>
                                            <ExportOutlined />
                                          </Space>
                                        }
                                        onClick={goToAssetDetails}
                                      />
                                    );
                                  },
                                },
                                {
                                  title: AlectifyText.ASSET_TYPE,
                                  key: "asset_type",
                                  width: 140,
                                  render: (value, assetRecord) => {
                                    return (
                                      <>
                                        {assetRecord.type === "Tag" ? (
                                          <AssetTag />
                                        ) : assetRecord.type ===
                                          "PackageRoom" ? (
                                          <AssetPackageTag />
                                        ) : (
                                          <GenericTag />
                                        )}
                                      </>
                                    );
                                  },
                                },
                                {
                                  dataIndex: "location",
                                  title: AlectifyText.LOCATION,
                                  width: 160,
                                  ellipsis: true,
                                  render: (location, assetRecord) => {
                                    return (
                                      <Tooltip title={location || "NA"}>
                                        {truncateText(location || "-", 30)}
                                      </Tooltip>
                                    );
                                  },
                                },

                                {
                                  dataIndex: "description",
                                  title: "description",
                                  width: 200,
                                  ellipsis: true,
                                  render: (description, assetRecord) => {
                                    return (
                                      <Tooltip title={description || "NA"}>
                                        {truncateText(description || "-", 37)}
                                      </Tooltip>
                                    );
                                  },
                                },
                              ]}
                            />
                          ),
                          onCancel: () =>
                            ModalServiceInstance.close(AlectifyModal, {
                              name: MODAL_NAMES.ASSET_NAME_MODAL,
                            }),
                        });
                      } else {
                        // Redirect directly to asset/area detail page
                        const singleAsset: any =
                          record.assets.length === 1 ? record.assets[0] : null;
                        const singleArea: any =
                          record.areas.length === 1 ? record.areas[0] : null;

                        if (singleAsset) {
                          const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
                          window.open(url, "_blank");
                        } else if (singleArea) {
                          const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
                          window.open(url, "_blank");
                        }
                      }
                    }
                  }}
                />
              )}
            </Tooltip>
            {!isEmpty(record.assets) || !isEmpty(record.areas) ? (
              record.areas.length > 1 || record.assets.length > 1 ? (
                <MultiAssetTag />
              ) : record.areas.length === 1 ? (
                <AssetPackageTag />
              ) : record.assets.length === 1 ? (
                <AssetTag />
              ) : (
                <NoAssetTag />
              )
            ) : (
              <NoAssetTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.FREQUENCY,
      dataIndex: "isRecurring",
      key: "isRecurring",
      width: 90,
      visible: true,
      render: (isRecurring, record) => (
        <div className="pminternalcolumn-cell-tag">
          <Space direction="vertical">
            <span>
              {record?.frequency && isRecurring
                ? record.frequencyType === FrequencyTypeEnum.MONTHLY
                  ? `${record?.frequency} Month(s)`
                  : "-"
                : (record.frequencyType &&
                    capitalizeFirstLetter(record.frequencyType)) ||
                  "-"}
            </span>
            {isRecurring ? <RecurringTag /> : <NonRecurringTag />}
          </Space>
        </div>
      ),
    },
    {
      title: AlectifyText.ACTION_REQUIRED,
      dataIndex: "assignees",
      key: "assignees",
      width: 80,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      visible: true,
      render: (assignees: IPmAssignees[], record: ITask) => {
        const actionRquired = getPMEActionRequired(record);
        const ACTION_REQUIRED = ACTION_REQUIRED_COMPONENTS(actionRquired);
        if (assignees?.length || !isEmpty(record.createdBy)) {
          return (
            <Space direction="vertical" size={5}>
              <AvatarGroup
                maxCount={2}
                users={
                  (actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                    ? assignees.map(({ user }) => user)
                    : [record.createdBy]) as any
                }
              />
              {ACTION_REQUIRED}
            </Space>
          );
        }
        return <></>;
      },
    },
  ];
  return columns.filter((col) => col.visible);
};

export default DashboardOrdersColumns;
