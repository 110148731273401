import { Spin } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import TodayTaskDetails from "./TodayTaskDetails";
import { ITodaysTasksProps } from "./TodayTaskDetails.interface";
import { fetchPrevMaintenanceByDueDate } from "services/pm-internal/pm-internal.service";
import { ITask } from "redux/components/tasks/tasks.interface";

const TodaysTasks: React.FC<ITodaysTasksProps> = (props: ITodaysTasksProps) => {
  const { navigate } = props;
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const todayDate = dayjs().format("YYYY-MM-DD");

  const fetchTaskDueToday = async () => {
    try {
      setLoader(true);
      const response = await fetchPrevMaintenanceByDueDate(
        todayDate,
        todayDate,
      );
      setLoader(false);
      if (response.status) {
        setTasks(response.data);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  useEffect(() => {
    fetchTaskDueToday();
  }, []);

  return (
    <Spin spinning={loader}>
      <div className="sub-project-tabs">
        <TodayTaskDetails tasks={tasks || []} navigate={navigate} />
      </div>
    </Spin>
  );
};

export default TodaysTasks;
