import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { Col, Form, Row, Spin, Typography, message, Skeleton } from "antd";
import AlectifyDatePicker from "../datepicker";
import { UploadFile } from "antd/lib";
//internal
import { MESSAGES } from "constants/messages";
import { uploadPrevMaintenanceDocuments } from "services/pm-internal/pm-internal.service";
import { IPmCommentCreationPayload } from "services/pm-external/pm-external.interface";
import {
  createNewPmComment,
  fetchExternalPMDetails,
} from "services/pm-external/pm-external.service";
import { PM_STATUS } from "enums";
import AlectifyUpload from "../upload";
import AlectifyText from "static/texts.json";
import AlectifyHoursPicker from "../estimated-hours-input";
import { isEmpty } from "lodash";
import {
  getPmInternalAttachments,
  getPmsCounts,
} from "redux/components/pm-internal/sources";
import AlectifyInputNumber from "../input-number";
import AlectifyInput from "../input";

import { FOLDER_NAME } from "enums/folder-type.enum";
import {
  getComments,
  setUploadDocuments,
} from "redux/components/common/sources";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import {
  calculateDaysDifference,
  displayDateTime,
  getFullUserName,
} from "utils/helpers";
import { ITaskSubmitForReviewFormProps } from "./TasksTable.interface";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";

const TaskSubmitForReviewForm: React.FC<ITaskSubmitForReviewFormProps> = (
  props: ITaskSubmitForReviewFormProps,
) => {
  const [workOrder, setWorkOrder] = useState<{
    data: IPmExternal | null;
    fetching: boolean;
  }>({ data: null, fetching: false });
  const [loading, setLoader] = useState<boolean>(false);
  const [directory, setDirectory] = useState<{ [key: string]: string }>({});
  const user = useSelector((state: IRootState) => state.auth.user);
  const { uploadDocuments } = useSelector((state: IRootState) => state.common);
  const dispatch = useDispatch();

  const onDirectoryChange = (uid: string, dir: string) => {
    const newDir = directory;
    newDir[uid] = dir;
    setDirectory(newDir);
  };

  const getWorkOrderDetail = async (id: string) => {
    if (props.isDetailPage) {
      setWorkOrder({ data: props.pmExternal as IPmExternal, fetching: false });
      if (!isEmpty(props.pmExternal)) {
        props.form.setFieldValue(
          "estimatedHours",
          props.pmExternal?.estimatedHours || "",
        );
      }
    } else {
      setWorkOrder({ ...workOrder, fetching: true });
      try {
        const response = await fetchExternalPMDetails(id);
        setWorkOrder({ data: response.data as any, fetching: false });
        if (!isEmpty(response.data)) {
          props.form.setFieldValue(
            "estimatedHours",
            response.data?.estimatedHours || "",
          );
        }
      } catch (ex) {
        setWorkOrder({ ...workOrder, fetching: false });
        console.log(ex);
      }
    }
  };

  const uploadDocument = async () => {
    try {
      message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);
      const data = uploadDocuments?.map((file: UploadFile) => ({
        ...file,
        folder: directory[file.uid],
      }));
      const formData = new FormData();
      data?.map((document: any) =>
        formData.append(FOLDER_NAME.SUBMIT_FOR_REVIEW, document.originFileObj),
      );
      formData.append("userId", user?.id);
      formData.append("projectId", props.masterProjectId);
      formData.append("subProjectId", props.subProjectId);
      await uploadPrevMaintenanceDocuments(props.pmExternal.id, formData);
      message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      if (props.isDetailPage) {
        dispatch(
          getPmInternalAttachments(props.pmExternal.id, {
            page: 1,
            per_page: 50,
          }),
        );
      }
    } catch (error) {
      message.error(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_ERROR);
      throw error;
    }
  };

  /*   const openSuccessMessageModal = (text: string, modalName: string) => {
    message.success(text);
    ModalServiceInstance.close(AlectifyModal, {
      name: modalName,
    });
  }; */
  const openWarningModal = (values: any) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.APPROVER_WARNING_MODAL,
      title: "Are you sure?",
      footer: false,
      children: (
        <Spin spinning={loading}>
          <ConfirmationModal
            message={MESSAGES.WORK_ORDER.APPROVER_WARNING}
            onConfirm={() => {
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.APPROVER_WARNING_MODAL,
              });
              onSubmit(values);
            }}
            onCancel={() =>
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.APPROVER_WARNING_MODAL,
              })
            }
            icon={WarningIcon}
          />
        </Spin>
      ),
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.APPROVER_WARNING_MODAL,
        });
      },
    });
  };
  const onSubmitHandler = (values: any) => {
    if (isEmpty(props.pmExternal?.approvers)) {
      openWarningModal(values);
    } else {
      onSubmit(values);
    }
  };

  const onSubmit = async (values: any) => {
    setLoader(true);
    try {
      let commentResponse;
      if (values.comment) {
        const commentPayload: IPmCommentCreationPayload = {
          content_type: "text",
          reference_type: PM_TYPES.PM_EXTERNAL,
          text: values.comment
            ? `Submit for Review Comments: ${values.comment}`
            : "",
          sent_by: user.id,
        };
        commentResponse = await createNewPmComment(
          props.subProjectId,
          props.pmExternal.id,
          commentPayload,
        );
      }
      const isSuccess = await props.updateStatus(
        props.pmExternal,
        !isEmpty(props.pmExternal?.approvers)
          ? PM_STATUS.WAITING_FOR_REVIEW
          : PM_STATUS.COMPLETED,
        values.date,
        values.estimatedHours,
        values.estimatedCost,
        (commentResponse && commentResponse.data[0].id) || undefined,
      );
      if (isSuccess) {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.APPROVER_WARNING_MODAL,
        });
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        });
        !isEmpty(uploadDocuments) && (await uploadDocument());

        if (props.isDetailPage) {
          dispatch(getComments(props.subProjectId, props.pmExternal.id));
        }
        // openSuccessMessageModal(
        //   AlectifyText.ORDER_REVIEW,
        //   MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        // );

        dispatch(getPmsCounts(props.masterProjectId, props.subProjectId));
        dispatch(setUploadDocuments([]));
        props.form.resetFields();
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log("ex", ex);
    }
  };

  useEffect(() => {
    getWorkOrderDetail(props.pmExternal.id);
  }, [props.pmExternal.id]);

  return (
    <div className="pmexternal-task-form-container">
      <Spin spinning={loading}>
        {workOrder.fetching ? (
          <Skeleton active />
        ) : (
          <>
            <Row className="card-container-review">
              <Col span={24}>
                <div className="heading-review-procedure-detail">
                  <Typography.Text strong>
                    {AlectifyText.SUMMARY}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={24}>
                <ul>
                  <li>
                    <strong>Work Title:</strong>{" "}
                    {workOrder.data?.workTitle || "-"}
                  </li>
                  <li className="mt-5">
                    <strong>Work ID:</strong> {workOrder.data?.workId || "-"}
                  </li>
                  {workOrder.data?.reviewedBy && (
                    <li className="mt-5">
                      <strong>
                        {getFullUserName(workOrder.data?.reviewedBy as any)}
                      </strong>{" "}
                      completed the work on{" "}
                      <strong>
                        {displayDateTime(props.pmExternal?.reviewedAt, true) ||
                          "-"}
                      </strong>{" "}
                      {/*   [Completion date mentioned was{" "}
                      <strong>
                        {displayDateTime(props.pmExternal?.completionAt) || "-"}
                      </strong>
                      ] */}
                    </li>
                  )}
                  {workOrder.data?.completionAt &&
                    workOrder.data?.dueDate &&
                    new Date(workOrder.data?.completionAt) >
                      new Date(workOrder.data?.dueDate) && (
                      <li className="mt-5">
                        The submission was{" "}
                        <strong>
                          {calculateDaysDifference(
                            workOrder.data?.completionAt,
                            workOrder.data?.dueDate,
                          )}
                        </strong>{" "}
                        days late
                      </li>
                    )}
                  {props.pmExternal?.estimatedHours &&
                    props.pmExternal?.estimatedCost && (
                      <li className="mt-5">
                        Estimated hours were{" "}
                        <strong>
                          {props.pmExternal?.estimatedHours || "N/A"}
                        </strong>{" "}
                        and estimated cost was{" "}
                        <strong>
                          {props.pmExternal?.estimatedCost || "N/A"}
                        </strong>
                      </li>
                    )}
                  {!isEmpty(workOrder.data?.sparePartsUsed?.count) && (
                    <li className="mt-5">
                      Total of{" "}
                      <strong>{workOrder.data?.sparePartsUsed?.count}</strong>{" "}
                      spare parts were used which costed{" "}
                      <strong>
                        {workOrder.data?.sparePartsUsed?.cost || "-"}
                      </strong>
                    </li>
                  )}
                  {workOrder?.data?.deniedComment && (
                    <li className="red-text mt-5">
                      <span className="red-text">
                        {workOrder?.data?.deniedComment.text || ""}
                      </span>
                    </li>
                  )}
                  {workOrder?.data?.reviewComment && (
                    <li className="mt-5">
                      {workOrder.data?.reviewComment?.text || "-"}
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
            {/*    <Row gutter={[20, 20]} className="card-container">
              <Col span={12}>
                <Statistic
                  title={AlectifyText.WORK_TITLE}
                  value={props.pmExternal.workTitle || "-"}
                  valueStyle={{ fontSize: 14 }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={AlectifyText.WORK_ID}
                  value={props.pmExternal.workId}
                  valueStyle={{ fontSize: 14 }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={AlectifyText.SPARE_PARTS_USED}
                  value={workOrder.data?.sparePartsUsed?.count || "-"}
                  valueStyle={{ fontSize: 14 }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={AlectifyText.SPARE_PARTS_COST}
                  value={renderAmount(workOrder.data?.sparePartsUsed?.cost)}
                  valueStyle={{ fontSize: 14 }}
                />
              </Col>

              {props.pmExternal?.procedure && (
                <Col span={12}>
                  <ChecklistStatus
                    completedSteps={
                      props.pmExternal.procedure
                        ?.procedureStepCheckedTotalCount as any
                    }
                    totalSteps={
                      props.pmExternal.procedure?.procedureStepTotalCount || 0
                    }
                  />
                </Col>
              )}
            </Row> */}
            <Row>
              <Col span={24}>
                <div className="heading-review-procedure-detail">
                  <Typography.Text strong>
                    {MESSAGES.HEADINGS.COMPLETED_WORK_ORDER}{" "}
                  </Typography.Text>
                </div>
              </Col>
            </Row>
            <Form
              layout="vertical"
              form={props.form}
              onFinish={onSubmitHandler}
            >
              <Row gutter={12} align={"middle"}>
                <Col span={24}>
                  <Form.Item
                    label="Completion Date ( Enter date if completion is not today)"
                    name="date"
                    /* rules={[
                      {
                        required: true,
                        message: "Please select completion date",
                      },
                    ]} */
                  >
                    <AlectifyDatePicker className="full-width-picker" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={AlectifyText.ESTIMATED_HOURS}
                    name="estimatedHours"
                    rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          AlectifyText.ESTIMATED_HOURS,
                        ),
                      },
                    ]}
                  >
                    <AlectifyHoursPicker
                      className="procedure-review-date-picker"
                      onChange={() => {}}
                      defaultValue={workOrder.data?.estimatedHours}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={AlectifyText.ESTIMATED_COST}
                    name="estimatedCost"
                  >
                    <AlectifyInputNumber
                      prefix="$"
                      precision={2}
                      min={0}
                      step={0.1}
                      defaultValue={Number(workOrder.data?.estimatedCost) || 0}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <AlectifyInput
                    label={AlectifyText.COMMENTS}
                    type="textarea"
                    name="comment"
                    /* rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          AlectifyText.COMMENTS,
                        ),
                      },
                    ]} */
                    rows={6}
                  />
                </Col>
                <Col span={24}>
                  <div className="upload-box">
                    <AlectifyUpload
                      attachmentText
                      onDirectoryChange={onDirectoryChange}
                      multiple
                      accept="*"
                      documentType={AlectifyText?.PM}
                    />
                  </div>
                </Col>
                {/* {props.procedureSteps &&
                  !areAllStepsCompleted(props.procedureSteps as any) && (
                    <Row
                      justify={"center"}
                      align={"middle"}
                      className="alectify-warning-box mt-10"
                    >
                      <Col span={24} className="text-align-center">
                        <Row justify={"center"} align={"middle"}>
                          <Col span={24}>
                            <WarningIcon />
                          </Col>
                          <Col span={24}>
                            <strong>
                              {`Only ${countCheckedSteps(
                                props.pmExternal.procedure
                                  ?.procedureSteps as any,
                              )} Steps are completed.`}
                            </strong>
                          </Col>
                          <Col span={24}>
                            <Typography.Text>
                              Are you Sure you want for Submit for Review ?
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={"checkedSteps"}
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error("Please check the box."),
                                        ),
                                  required: true,
                                  message: "Please check the box.",
                                },
                              ]}
                              valuePropName="checked"
                            >
                              <Checkbox>
                                <strong>Yes, I want to submit</strong>
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )} */}
              </Row>
            </Form>
          </>
        )}
      </Spin>
    </div>
  );
};

export default TaskSubmitForReviewForm;
