import { memo } from "react";
import SparePartsTable from "components/shared/spare-parts-table";

const DrawSpareParts: React.FC = () => {
  return (
    <SparePartsTable
      scroll={{ y: "calc(100vh - 360px)" }}
      showDownloadCSV
      showAdvisoryButton
    />
  );
};

export default memo(DrawSpareParts);
